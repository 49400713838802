/* eslint-disable no-nested-ternary */
/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import qs from 'query-string';
import { Col, Container, Row, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Layout from '../Layout';
import { list, error } from '../../actions/Dashboard/list';
import { list as listNotifications } from '../../actions/Notification/list';
import {
  iconPlus,
  iconPending,
  iconBooked,
  iconClosed,
  iconConfirmed
} from '../Util';
import Loader from '../Util/Loader';
import { CardStatus, CardApartment } from '../Common/Card';
import { Search, FiltersWrapper } from '../Common/Toolbar';
import {
  Create as CreateRequestModal,
  Overview as RequestOverviewModal
} from '../Request';
import { messaging } from '../../firebase/Firebase';
import { hasRole } from '../../utils/helpers';
import { CLIENT, SUB_CLIENT, HOST_ADMIN } from '../../constants/roles';

const Dashboard = ({
  list,
  errorAction,
  retrieved,
  loading,
  error,
  roles,
  id,
  location,
  listNotifications,
  history
}) => {
  const getIdFromLink = () =>
    qs.parse(location.search, { ignoreQueryPrefix: true }).requestId;
  const [filters, setFilters] = useState({ page: 1 });
  const [loc, setLoc] = useState(location);
  const [duplicatedRequest, setDuplicatedRequest] = useState(null);
  const [requestId, setRequestId] = useState(getIdFromLink());
  const [requestModalState, setRequestModalState] = useState(false);
  const [requestOverviewModalState, setRequestOverviewModalState] = useState(
    requestId !== undefined
  );
  const defaultStatusCounts = {
    pending: 0,
    pendingConfirmations: 0,
    booked: 0,
    closed: 0
  };
  const [statusCounts, setStatusCounts] = useState(defaultStatusCounts);
  const [requests, setRequests] = useState();
  const [shouldIncrement, setShouldIncrement] = useState(false);

  const handleSearch = e => {
    if (e.key === 'Enter') {
      setFilters({
        ...filters,
        search_multiple_combined: e.target.value || null,
        page: 1
      });
    }
  };

  const cardApartmentOnClick = requestId => {
    setRequestId(requestId);
    setRequestOverviewModalState(true);
  };

  const handleFilterButtonClick = obj => {
    setFilters({
      ...filters,
      page: 1,
      filterBtn:
        filters.filterBtn && filters.filterBtn.key === obj.key ? null : obj
    });
  };

  useEffect(() => {
    if (retrieved) {
      if (filters.page > 1 && requests) {
        setRequests([...requests, ...retrieved['hydra:member']]);
      } else {
        setRequests(retrieved['hydra:member']);
      }

      setShouldIncrement(retrieved['hydra:totalItems'] - filters.page * 30 > 0);
    }
  }, [retrieved]);

  useEffect(() => {
    let queryString = '';

    Object.keys(filters).forEach((key, i) => {
      if (filters[key] === null) {
        return;
      }

      if (i > 0) {
        queryString += '&';
      }

      if (key === 'filterBtn') {
        queryString += `${filters[key].key}=${filters[key].value}`;
      } else {
        queryString += `${key}=${filters[key]}`;
      }
    });

    list(queryString);
  }, [filters]);

  useEffect(() => {
    document.addEventListener(
      'keydown',
      evt => {
        const event = evt || window.event;
        if (event.keyCode === 27) {
          setRequestModalState(false);
          setRequestOverviewModalState(false);
          setRequestId();
        }
      },
      false
    );

    if (messaging) {
      // handle the event when a firebase message was received
      messaging.onMessage(() => {
        list();
        listNotifications();
      });
    }
  }, []);

  useEffect(() => {
    const id = getIdFromLink();

    if (loc.key !== location.key && id) {
      history.push('/dashboard');
      setRequestId(id);
      setRequestOverviewModalState(true);
      setLoc(location);
    }
  });

  useEffect(() => {
    setStatusCounts(
      (requests || []).reduce((acc, curr) => {
        if (
          curr.statusName.startsWith('pending') &&
          curr.statusName !== 'pending_confirmation'
        ) {
          acc.pending++;
        }
        if (curr.statusName === 'pending_confirmation') {
          acc.pendingConfirmations++;
        }
        if (curr.statusName === 'booked') {
          acc.booked++;
        }
        if (curr.statusName.startsWith('closed')) {
          acc.closed++;
        }

        return acc;
      }, defaultStatusCounts)
    );
  }, [requests]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      errorAction(null);
    }
  }, [error]);

  return (
    <Layout container={false}>
      {requestModalState && (
        <CreateRequestModal
          modalState={requestModalState}
          setModalState={setRequestModalState}
          duplicatedRequest={duplicatedRequest}
          setDuplicatedRequest={setDuplicatedRequest}
        />
      )}
      {requestOverviewModalState && (
        <RequestOverviewModal
          modalState={requestOverviewModalState}
          setModalState={setRequestOverviewModalState}
          createRequestModalState={requestModalState}
          setCreateRequestModalState={setRequestModalState}
          setRequestId={setRequestId}
          requestId={requestId}
          roles={roles}
          setDuplicatedRequest={setDuplicatedRequest}
        />
      )}
      <Container>
        <Row className="mt-3 d-flex align-items-center">
          {hasRole(roles, [CLIENT, SUB_CLIENT, HOST_ADMIN]) ? (
            <Col md={4}>
              <button
                type="button"
                className="btn btn--form-action"
                onClick={() => setRequestModalState(!requestModalState)}
              >
                <img src={iconPlus} alt="Create request" />
              </button>
            </Col>
          ) : (
            <Col md={4} />
          )}
          <Col md={8}>
            <Row className="align-items-lg-center">
              <Col md={12} lg={8}>
                {hasRole(roles, CLIENT) && (
                  <FiltersWrapper>
                    <button
                      type="button"
                      className={`btn btn--filter${
                        filters.filterBtn && filters.filterBtn.key === 'user.id'
                          ? ' active'
                          : ''
                      }`}
                      onClick={() => {
                        handleFilterButtonClick({
                          key: 'user.id',
                          value: id
                        });
                      }}
                    >
                      My Requests
                    </button>
                    <button
                      type="button"
                      className={`btn btn--filter${
                        filters.filterBtn &&
                        filters.filterBtn.key === 'user.roles'
                          ? ' active'
                          : ''
                      }`}
                      onClick={() => {
                        handleFilterButtonClick({
                          key: 'user.roles',
                          value: CLIENT
                        });
                      }}
                    >
                      My Company
                    </button>
                  </FiltersWrapper>
                )}
              </Col>
              <Col md={12} lg={4}>
                <div className="d-flex">
                  <div className="ml-auto">
                    <Search onKeyPress={handleSearch} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="position-relative">
          {loading && <Loader type="wrap" />}
          <Col md={6} lg={3}>
            <CardStatus
              request={{
                count: statusCounts.pending,
                title: 'Pending Requests',
                icon: iconPending
              }}
            />
          </Col>
          <Col md={6} lg={3}>
            <CardStatus
              request={{
                count: statusCounts.pendingConfirmations,
                title: 'Pending Confirmations',
                icon: iconConfirmed
              }}
            />
          </Col>
          <Col md={6} lg={3}>
            <CardStatus
              request={{
                count: statusCounts.booked,
                title: 'Booked',
                icon: iconBooked
              }}
            />
          </Col>
          <Col md={6} lg={3}>
            <CardStatus
              request={{
                title: 'Closed',
                icon: iconClosed
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <hr />
          </Col>
        </Row>
      </Container>
      <Container>
        {!requests || (!requests.length && loading) ? (
          <Loader type="small" />
        ) : !requests.length ? (
          <h3 className="text-center heading heading--small">
            There are no requets matching your criteria.
          </h3>
        ) : (
          <Row>
            <Col md={6} lg={3}>
              {requests.reduce((accumulator, current) => {
                if (
                  current.statusName.startsWith('pending') &&
                  current.statusName !== 'pending_confirmation'
                ) {
                  accumulator.push(
                    <CardApartment
                      key={current.id}
                      apartment={current}
                      onClick={() => cardApartmentOnClick(current.id)}
                    />
                  );
                }
                return accumulator;
              }, [])}
            </Col>
            <Col md={6} lg={3}>
              {requests.reduce((accumulator, current) => {
                if (current.statusName === 'pending_confirmation') {
                  accumulator.push(
                    <CardApartment
                      key={current.id}
                      apartment={current}
                      onClick={() => cardApartmentOnClick(current.id)}
                    />
                  );
                }
                return accumulator;
              }, [])}
            </Col>
            <Col md={6} lg={3}>
              {requests.reduce((accumulator, current) => {
                if (current.statusName === 'booked') {
                  accumulator.push(
                    <CardApartment
                      key={current.id}
                      apartment={current}
                      onClick={() => cardApartmentOnClick(current.id)}
                    />
                  );
                }

                return accumulator;
              }, [])}
            </Col>
            <Col md={6} lg={3}>
              {requests.reduce((accumulator, current) => {
                if (current.statusName.indexOf('closed') !== -1) {
                  accumulator.push(
                    <CardApartment
                      key={current.id}
                      apartment={current}
                      onClick={() => cardApartmentOnClick(current.id)}
                    />
                  );
                }
                return accumulator;
              }, [])}
            </Col>
            {loading && <Loader type="small" />}
          </Row>
        )}
        {shouldIncrement && !loading && (
          <Row>
            <Col md={12} className="text-center my-4">
              <Button
                type="button"
                className="btn btn--primary"
                onClick={() => {
                  setFilters({ ...filters, page: filters.page + 1 });
                }}
              >
                Load More
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  );
};

export default withRouter(
  connect(
    state => {
      const { loading, retrieved, error } = state.dashboard.list;
      const { roles, id } = state.authentication.token.retrieved;

      return {
        retrieved,
        loading,
        error,
        roles,
        id
      };
    },
    { list, errorAction: error, listNotifications }
  )(Dashboard)
);
