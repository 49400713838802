/* eslint react/prop-types: 0 */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Input } from '../Common/Form';
import logo from '../../assets/nest_logo.svg';
import { Loader } from '../Util';
import { resetPasswordCreate } from '../../actions/Authentication/resetPasswordCreate';

const renderField = data => (
  <div className="form-group row">
    <Input
      {...data}
      type={data.type}
      step={data.step}
      className="col-12 text-left"
      required={data.required}
      placeholder={data.placeholder}
      id={`request_${data.input.name}`}
      error={data.meta.touched && data.meta.error ? data.meta.error : null}
    />
  </div>
);

const ResetPasswordCreate = ({
  handleSubmit,
  resetPasswordCreate,
  error,
  submitting,
  submitSucceeded
}) => (
  <div className="login">
    <div className="login__wrapper">
      <div className="login__box">
        <img src={logo} alt="Nest Logo" className="login__logo" />
        <h4 className="text text--normal" style={{ marginBottom: '40px' }}>
          Reset Password
        </h4>

        {submitSucceeded && (
          <div>
            <h6>Please check your email for a link to reset your password.</h6>

            <h6 className="text text--small" style={{ marginBottom: '20px' }}>
              <Link to="/login" className="text text--link-pink">
                Login
              </Link>
            </h6>
          </div>
        )}

        {!submitSucceeded && (
          <form onSubmit={handleSubmit(resetPasswordCreate)}>
            {!submitting && error && (
              <h6
                className="login__heading login__heading--small"
                style={{ color: 'red', marginBottom: '0' }}
              >
                {error}
              </h6>
            )}

            <Field
              id="email"
              type="email"
              name="email"
              placeholder="Email"
              component={renderField}
            />
            <button type="submit" className="btn btn--primary">
              Send
            </button>
            {submitting && <Loader />}
          </form>
        )}
      </div>
    </div>
  </div>
);

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email is required.';
  }

  return errors;
};

const reduxFormResetPasswordCreate = reduxForm({
  form: 'resetpassswordrequest',
  validate
})(ResetPasswordCreate);

export default connect(
  null,
  { resetPasswordCreate }
)(reduxFormResetPasswordCreate);
