import axios from 'axios';
import { success as deleteSuccess } from './delete';

export function error(error) {
  return { type: 'COMPANY_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'COMPANY_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'COMPANY_LIST_SUCCESS', retrieved };
}

export function list(page = '/companies?pagination=false') {
  return async dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    try {
      const result = await axios.get(
        `${process.env.REACT_APP_API_ENTRYPOINT}${page}`,
        {
          headers: {
            Accept: 'application/ld+json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );
      dispatch(success(result.data));
    } catch (e) {
      dispatch(error(e.message));
    }

    dispatch(loading(false));
  };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'COMPANY_LIST_RESET' });
    dispatch(deleteSuccess(null));
  };
}
