/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  change
} from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  InlineFieldGroup,
  InlineField,
  BlockField,
  SuggestionInput
} from '../Common/Form';
import { Loader, iconPlus, iconMinus } from '../Util';
import { listApartmentTypes } from '../../actions/Request/other';
import { create as createOffer } from '../../actions/Offer/create';
import {
  list as listOffers,
  reset as resetOffers
} from '../../actions/Offer/list';
import { list as listDashboard } from '../../actions/Dashboard/list';
import { ucwords } from '../../utils/helpers';
import {
  listOfferChargeTypes,
  listOfferChargeFrequencies
} from '../../actions/Offer/other';
import { success as setOffer } from '../../actions/Offer/show';

const renderCharges = ({
  fields,
  charges,
  offerChargeTypes,
  offerChargeFrequencies
}) => {
  return (
    <>
      {fields.map((charge, index) => (
        <InlineFieldGroup
          label={`Other Charges${index > 0 ? ` (${index + 1})` : ''}`}
          key={index}
          required
        >
          <Field
            component={InlineField}
            name={`${charge}.type`}
            label="Type"
            type="select"
            placeholder="Type"
            items={offerChargeTypes.map(e => {
              const { id, name } = e;
              return { name, value: id };
            })}
            required
          />
          <Field
            component={InlineField}
            name={`${charge}.frequency`}
            label="Frequency"
            type="select"
            placeholder="Frequency"
            items={offerChargeFrequencies.map(e => {
              const { id, name } = e;
              return { name, value: id };
            })}
            required
          />
          <Field
            component={InlineField}
            name={`${charge}.price`}
            type="currencyInput"
            placeholder="Price"
          />
          {parseInt(charges[index].type, 10) === 4 && (
            <Field
              component={InlineField}
              name={`${charge}.remark`}
              type="text"
              placeholder="Remark"
            />
          )}
        </InlineFieldGroup>
      ))}
      <div className="form-group row">
        <div className="col-sm-3 d-flex">
          <label className="form-label form-label align-self-center">
            Additional Costs
          </label>
        </div>
        <div className="col-sm-9">
          {fields.length < 10 && (
            <button
              type="button"
              className="btn btn--form-action d-inline-block mr-2"
              onClick={() => fields.push({})}
            >
              <img
                src={iconPlus}
                alt="Add other charge"
                style={{ width: '40px', height: '40px' }}
              />
            </button>
          )}
          {fields.length > 0 && (
            <button
              type="button"
              className="btn btn--form-action d-inline-block"
              onClick={() => fields.pop()}
            >
              <img
                src={iconMinus}
                alt="Add other charge"
                style={{ height: '40px', width: '40px' }}
              />
            </button>
          )}
        </div>
      </div>
    </>
  );
};

let Form = ({
  handleSubmit,
  request: { pets, babyPackage, parking, requestedRateType, departure },
  submitSucceeded,
  submitting,
  listApartmentTypes,
  apartmentTypesLoading,
  apartmentTypesRetrieved,
  setView,
  createOffer,
  listDashboard,
  availabilityDiffers,
  availableFrom,
  availableTo,
  setSelectedOffer,
  createdOffer,
  listOfferChargeTypes,
  offerChargeTypesRetrieved,
  offerChargeTypesLoading,
  listOfferChargeFrequencies,
  offerChargeFrequenciesRetrieved,
  offerChargeFrequenciesLoading,
  change,
  listOffers,
  resetOffers,
  filteredOffers,
  offer,
  setOffer,
  charges
}) => {
  useEffect(() => {
    if (!apartmentTypesRetrieved.length) {
      listApartmentTypes();
    }

    if (!offerChargeTypesRetrieved.length) {
      listOfferChargeTypes();
    }

    if (!offerChargeFrequenciesRetrieved.length) {
      listOfferChargeFrequencies();
    }
  }, []);

  useEffect(() => {
    if (submitSucceeded) {
      toast.success('Offer submitted.');
      listDashboard();
      setSelectedOffer(createdOffer);
      setView('list');
    }
  }, [submitSucceeded]);

  useEffect(() => {
    if (offer) {
      const pos = offer.addressPos
        ? {
            lat: offer.addressPos.split(',')[0],
            lng: offer.addressPos.split(',')[1]
          }
        : undefined;

      change('description', offer.description);
      change('apartmentType', offer.apartmentType.id);
      change('sqm', offer.sqm);
      change('location', {
        address: offer.address,
        pos
      });
      change('zip', offer.zip);
      change('city', offer.city);
      change('apartmentIdentifier', offer.apartmentIdentifier || undefined);
      change('cancellationTerms', offer.cancellationTerms);
      change('comment', offer.comment);
      change('link', offer.link);
      change('images', offer.images.map(image => image.path));
    }
  }, [offer]);

  useEffect(() => {
    // Reset offer after it has been mapped to the form
    if (offer) {
      setOffer(null);
    }
  }, [offer]);

  return (
    <>
      {(submitting ||
        apartmentTypesLoading ||
        offerChargeFrequenciesLoading ||
        offerChargeTypesLoading) && <Loader />}
      <form autoComplete="off" onSubmit={handleSubmit(createOffer)}>
        <h1 className="heading heading--medium">Make an Offer</h1>
        <Row>
          <Col xs={12} sm={8} md={6}>
            <SuggestionInput
              placeholder="Search offers to duplicate..."
              list={filteredOffers}
              listAction={e =>
                listOffers(
                  `/offers?address=${
                    e.target.value
                  }&itemsPerPage=100&order[dateCreated]=desc`
                )
              }
              resetAction={resetOffers}
              setItem={setOffer}
            />
          </Col>
        </Row>
        <a
          href="#"
          className="text--link-pink no-decoration d-inline-block my-3"
          onClick={() => setView('list')}
        >
          <i className="fas fa-list" /> All offers
        </a>
        <Row>
          <Col md={6}>
            <Field
              component={BlockField}
              name="description"
              label="Description"
              type="wysiwig"
              placeholder="The appartment has a nice view ..."
              required
            />
            <Field
              component={BlockField}
              name="availabilityDiffers"
              label="Availability differs from request"
              type="checkbox"
            />
            {availabilityDiffers && (
              <InlineFieldGroup label="Dates" required>
                <Field
                  component={InlineField}
                  name="availableFrom"
                  label="From"
                  type="dateTime"
                  placeholder="From (DD.MM.YYYY)"
                  isOutsideRange={day => {
                    return availableTo
                      ? moment(availableTo)
                          .subtract(1, 'day')
                          .isBefore(day)
                      : false;
                  }}
                />
                <Field
                  component={InlineField}
                  name="availableTo"
                  label="To"
                  type="dateTime"
                  placeholder="To (DD.MM.YYYY)"
                  isOutsideRange={day => {
                    return availableFrom
                      ? moment(availableFrom)
                          .add(1, 'day')
                          .isAfter(day)
                      : false;
                  }}
                />
              </InlineFieldGroup>
            )}
            <Field
              component={BlockField}
              name="apartmentType"
              label="Apartment Type"
              type="select"
              items={apartmentTypesRetrieved.map(e => ({
                value: `${e.id}`,
                label: e.name,
                name: e.name
              }))}
              required
            />
            <Field
              component={BlockField}
              name="sqm"
              label="m&sup2;"
              type="text"
              placeholder="100"
              required
            />
            <Field
              component={BlockField}
              name="location"
              label="Address"
              type="placesInput"
              placeholder="point of interest, office location, location etc."
              format={e => (e && e.address ? e.address : '')}
              required
            />
            <InlineFieldGroup label="Zip/City" required>
              <Field
                component={InlineField}
                name="zip"
                type="text"
                placeholder="8004"
                inputClass="col-5"
              />
              <Field
                component={InlineField}
                name="city"
                type="text"
                placeholder="Zürich"
                inputClass="col-7"
                required
              />
            </InlineFieldGroup>
            <Field
              component={BlockField}
              name="apartmentIdentifier"
              label="Apartment ID"
              type="text"
              placeholder="(AWN/EGID/EWID)"
            />
            <Field
              component={BlockField}
              name="cancellationTerms"
              label="Cancellation Terms"
              type="text"
              placeholder="e.g. 30 days, 30 days to next month-end"
              required
            />
            <Field
              component={BlockField}
              label="Remarks"
              name="comment"
              type="wysiwig"
              placeholder=""
            />
            <Field
              component={BlockField}
              name="link"
              label="Link"
              type="text"
              placeholder="https://mynest.ch/en/apartments/albulastrasse..."
              required
            />
            <Field
              component={BlockField}
              name="price"
              label={`${ucwords(requestedRateType)} Price (excl. ${
                process.env.REACT_APP_VAT_HOSPITALITY_FEE
              }% VAT)`}
              type="currencyInput"
              step="0.1"
              placeholder="123.-"
              required
            />
            <Field
              component={BlockField}
              name="endCleaningFee"
              label={`Total End Cleaning Fee excl. (${
                process.env.REACT_APP_VAT_SERVICE_FEE
              }% VAT)`}
              type="currencyInput"
              step="0.1"
              placeholder="10.-"
            />
            {!!pets && (
              <Field
                component={BlockField}
                name="petFee"
                label={`${ucwords(requestedRateType)} Pet Fee excl. (${
                  process.env.REACT_APP_VAT_SERVICE_FEE
                }% VAT)`}
                type="currencyInput"
                step="0.1"
                placeholder="15.-"
                required
              />
            )}
            {!!babyPackage && (
              <Field
                component={BlockField}
                name="babyPackageFee"
                label={`${ucwords(requestedRateType)} Baby Package Fee (excl. ${
                  process.env.REACT_APP_VAT_SERVICE_FEE
                }% VAT)`}
                type="currencyInput"
                step="0.1"
                placeholder="15.-"
                required
              />
            )}
            {!!parking && (
              <Field
                component={BlockField}
                name="parkingFee"
                label={`${ucwords(requestedRateType)} Parking Fee (excl. ${
                  process.env.REACT_APP_VAT_SERVICE_FEE
                }% VAT)`}
                type="currencyInput"
                step="0.1"
                placeholder="15.-"
                required
              />
            )}
            <FieldArray
              name="charges"
              component={renderCharges}
              offerChargeTypes={offerChargeTypesRetrieved}
              offerChargeFrequencies={offerChargeFrequenciesRetrieved}
              charges={charges}
            />
          </Col>
          <Col md={6}>
            <Field
              component={BlockField}
              name="images"
              type="fileInput"
              label="Image"
              accept="image/*"
              flex={false}
              multiple
            />
          </Col>
        </Row>
        <div className="form-group row">
          <div className="col-12">
            <div className="float-right">
              <button type="submit" className="btn btn--primary">
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

const validate = (
  values,
  { request: { pets, babyPackage, parking, departure } }
) => {
  const errors = {};

  if (!values.description) {
    errors.description = 'Description is required.';
  }

  if (values.availabilityDiffers && !values.availableFrom) {
    errors.availableFrom = 'From date must be set.';
  }

  if (departure && values.availabilityDiffers && !values.availableTo) {
    errors.availableTo = 'To date must be set.';
  }

  if (!values.sqm) {
    errors.sqm = 'Sqm (m2) is required.';
  }

  if (!values.apartmentType) {
    errors.apartmentType = 'At least one of the above must be selected.';
  }

  if (!values.location || !values.location.address) {
    errors.location = 'Address is required.';
  } else if (
    values.location &&
    values.location.address !== '' &&
    !values.location.pos
  ) {
    errors.location = 'Please select an address from the dropdown.';
  }

  if (!values.zip) {
    errors.zip = 'ZIP is required.';
  }

  if (!values.city) {
    errors.city = 'City is required.';
  }

  if (!values.cancellationTerms) {
    errors.cancellationTerms = 'Cancellation Terms are required.';
  }

  if (!values.price) {
    errors.price = 'Price is required';
  }

  if (!values.link) {
    errors.link = 'Link is required';
  }

  if (values.endCleaningFee && parseFloat(values.endCleaningFee) < 0) {
    errors.endCleaningFee = 'Negative values values are not allowed.';
  }

  if (pets && !values.petFee) {
    errors.petFee = 'Pet Fee is required.';
  } else if (pets && parseFloat(values.petFee) < 0) {
    errors.petFee = 'Negative values values are not allowed.';
  }

  if (babyPackage && !values.babyPackageFee) {
    errors.babyPackageFee = 'Baby Package Fee is required.';
  } else if (babyPackage && parseFloat(values.babyPackageFee) < 0) {
    errors.babyPackageFee = 'Negative values values are not allowed.';
  }

  if (parking && !values.parkingFee) {
    errors.parkingFee = 'Parking Fee is required.';
  } else if (parking && parseFloat(values.parkingFee) < 0) {
    errors.parkingFee = 'Negative values values are not allowed.';
  }

  const chargeErrors = [];

  if (values.charges) {
    values.charges.forEach((charge, i) => {
      const temp = {};

      if (!charge.type) {
        temp.type = 'Type is required.';
      }

      if (!charge.frequency) {
        temp.frequency = 'Frequency is required.';
      }

      if (!charge.price) {
        temp.price = 'Price is required.';
      }

      if (!charge.remark && parseInt(charge.type, 10) === 4) {
        temp.remark = 'Remark is required.';
      }

      if (Object.keys(temp).length !== 0 && temp.constructor === Object) {
        chargeErrors[i] = temp;
      }
    });
  }

  if (chargeErrors.length) {
    errors.charges = chargeErrors;
  }

  if (values.images) {
    const hasExceededLimit = Array.from(values.images).some(
      e => e.size > 2097152
    );

    if (values.images.length > 5) {
      errors.images = 'A maximum of 5 images is allowed.';
    } else if (hasExceededLimit) {
      errors.images = 'Maximum file size is 2MB';
    }
  }

  if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
    return {
      ...errors,
      _error: 'Submission failed. Please fix the fields marked in red.'
    };
  }

  return errors;
};

Form = reduxForm({
  form: 'offer',
  validate,
  onSubmitFail: (errors, dispatch, submitErrors, props) => {
    toast.error((errors ? errors._error : '') || props.error);
  },
  enableReinitialize: true
})(Form);

const selector = formValueSelector('offer');

export default connect(
  (state, { request: { id } }) => {
    const {
      availabilityDiffers,
      availableFrom,
      availableTo,
      charges
    } = selector(
      state,
      'availabilityDiffers',
      'availableFrom',
      'availableTo',
      'charges'
    );

    const {
      apartmentTypesLoading,
      apartmentTypesRetrieved
    } = state.request.other;

    const {
      retrieved: filteredOffers,
      loading: filteredOffersLoading,
      error: filteredOffersError
    } = state.offer.list;

    const {
      offerChargeTypesLoading,
      offerChargeTypesRetrieved,
      offerChargeFrequenciesLoading,
      offerChargeFrequenciesRetrieved
    } = state.offer.other;

    const { created: createdOffer } = state.offer.create;
    const { retrieved: offer } = state.offer.show;

    return {
      availabilityDiffers,
      availableFrom,
      availableTo,
      apartmentTypesLoading,
      apartmentTypesRetrieved,
      createdOffer,
      offerChargeTypesLoading,
      offerChargeTypesRetrieved,
      offerChargeFrequenciesLoading,
      offerChargeFrequenciesRetrieved,
      filteredOffers,
      filteredOffersLoading,
      filteredOffersError,
      offer,
      charges,
      initialValues: {
        apartmentRequest: `${id}`,
        availabilityDiffers: false
      }
    };
  },
  {
    listApartmentTypes,
    createOffer,
    listOffers,
    resetOffers,
    listDashboard,
    listOfferChargeTypes,
    listOfferChargeFrequencies,
    change,
    setOffer
  }
)(Form);
