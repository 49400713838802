import React from 'react';
import PropTypes from 'prop-types';
import { iconFilter } from '../../Util';

const FiltersWarpper = ({ children }) => (
  <div className="filter d-md-flex">
    <div className="filter__icon d-flex align-items-center">
      <img src={iconFilter} alt="icon filter" />
      <span>Filter</span>
    </div>
    <div className="filter__buttons d-flex justify-content-between">
      {children}
    </div>
  </div>
);

FiltersWarpper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default FiltersWarpper;
