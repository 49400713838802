import React from 'react';
import { List } from '../components/User';
import { PrivateRoute } from '../components/Authentication';

export default [
  <PrivateRoute
    path="/users"
    components={{ hostadmin: List, client: List }}
    title="Users"
    exact
    strict
    key="list"
  />
];
