import { combineReducers } from 'redux';
import list from './list';
import create from './create';
import update from './update';
import del from './delete';
import show from './show';
import other from './other';
import pdf from './pdf';
import email from './email';

export default combineReducers({
  list,
  create,
  update,
  del,
  show,
  other,
  pdf,
  email
});
