import axios from 'axios';
import { SubmissionError } from 'redux-form';
import { decodeJwt, calculateExpiryDate } from '../../utils/helpers';
import {
  askForPermissionToReceiveNotifications,
  initializeFirebase
} from '../../firebase/Firebase';
import { create } from '../NotificationFirebase/create';

initializeFirebase();
const askForPermission = askForPermissionToReceiveNotifications();

export const error = error => {
  return { type: 'TOKEN_ERROR', error };
};

export const loading = loading => {
  return { type: 'TOKEN_LOADING', loading };
};

export const retrieved = retrieved => {
  return { type: 'TOKEN_SUCCESS', retrieved };
};

export const getToken = ({ email, password }) => async dispatch => {
  dispatch(loading(true));
  dispatch(error(''));

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_ENTRYPOINT}/login`,
      {
        email,
        password
      }
    );

    const decoded = decodeJwt(data.token);
    const { iat, exp, roles, tosAccepted } = decoded;

    localStorage.setItem('token', data.token);
    localStorage.setItem('refreshToken', data.refresh_token);

    const result = {
      token: data.token,
      refreshToken: data.refresh_token,
      roles,
      tosAccepted,
      id: decoded.id,
      email: decoded.email,
      firstName: decoded.firstName,
      lastName: decoded.lastName,
      company: decoded.company,
      companySubClientEnabled: decoded.companySubClientEnabled,
      expiresAt: calculateExpiryDate(iat, exp)
    };

    // save the notification token in the database
    askForPermission.then(value => {
      dispatch({
        type: 'ALLOW_NOTIFICATIONS',
        allowNotifications: value.allowNotifications
      });
      if (value.allowNotifications && data.token && value.token) {
        const values = { registrationToken: value.token, deviceType: 'web' };
        dispatch(create(values));
      }
    });

    dispatch(retrieved(result));
  } catch (e) {
    let message = 'Error while trying to communicate with server.';

    if (e.response !== undefined) {
      message =
        e.response.data.message === 'Bad credentials.'
          ? 'Invalid username or password'
          : e.response.data.message;
    }

    throw new SubmissionError({ _error: message });
  }

  dispatch(loading(false));
};

export const logout = () => async dispatch => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('notification_token');

  return dispatch({ type: 'TOKEN_LOGOUT' });
};

let checkTokenTimeout = null;

export const checkToken = () => async (dispatch, getState) => {
  clearTimeout(checkTokenTimeout);

  const { expiresAt } = getState().authentication.token.retrieved;
  let expiresIn = expiresAt ? new Date(expiresAt) - new Date() : 0;
  expiresIn = expiresIn < 0 ? 0 : expiresIn;

  if (!expiresIn) {
    dispatch(logout());
  } else {
    checkTokenTimeout = setTimeout(() => {
      dispatch(logout());
    }, expiresIn);
  }
};

export const refreshToken = () => async (dispatch, getState) => {
  dispatch(error(''));
  dispatch(loading(true));

  try {
    const { email, refreshToken } = getState().authentication.token.retrieved;

    const { data } = await axios.post(
      `${process.env.REACT_APP_API_ENTRYPOINT}/token/refresh`,
      {
        email,
        refresh_token: refreshToken
      }
    );

    const decoded = decodeJwt(data.token);
    const { iat, exp, roles, tosAccepted } = decoded;

    localStorage.setItem('token', data.token);
    localStorage.setItem('refreshToken', data.refresh_token);

    const result = {
      token: data.token,
      refreshToken: data.refresh_token,
      roles,
      tosAccepted,
      id: decoded.id,
      email: decoded.email,
      firstName: decoded.firstName,
      lastName: decoded.lastName,
      company: decoded.company,
      companySubClientEnabled: decoded.companySubClientEnabled,
      expiresAt: calculateExpiryDate(iat, exp)
    };

    dispatch(retrieved(result));
  } catch (e) {
    console.log(e);
  }
  dispatch(loading(false));
};

export const refreshTokenTimeout = () => async (dispatch, getState) => {
  const { retrieved } = getState().authentication.token;
  let expiresAt;

  if (retrieved) {
    ({ expiresAt } = retrieved);
  }

  expiresAt = new Date(expiresAt);

  const refreshIn = (expiresAt - new Date()) / 2;

  if (refreshIn < 0) {
    return;
  }

  setTimeout(() => {
    dispatch(refreshToken());
  }, refreshIn);
};
