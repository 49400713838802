import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Alert, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import Header from '../Header';
import SupportHelp from '../Common/Support/SupportHelp';
import { logout } from '../../actions/Authentication/token';
import { hasRole } from '../../utils/helpers';
import { HOST, HOST_ADMIN, CLIENT, SUB_CLIENT } from '../../constants/roles';
import logo from '../../assets/nest_logo.svg';

const Layout = ({
  children,
  container,
  allowNotifications,
  logout,
  roles,
  firstName,
  companySubClientEnabled
}) => {
  let links = [];

  if (hasRole(roles, [HOST_ADMIN])) {
    links = [
      { link: '/dashboard', title: 'Dashboard' },
      { link: '/users', title: 'Users' },
      { link: '/companies', title: 'Companies' }
    ];
  } else if (hasRole(roles, [CLIENT])) {
    links = [{ link: '/dashboard', title: 'Dashboard' }];

    if (companySubClientEnabled) {
      links.push({ link: '/users', title: 'Users' });
    }
  } else if (hasRole(roles, [SUB_CLIENT])) {
    links = [{ link: '/tos', title: 'Terms of Service' }];
  }

  return (
    <Fragment>
      <div className="bg-white">
        <Container bsPrefix="container">
          <Header links={links} logout={logout} firstName={firstName} />
        </Container>
      </div>
      {!allowNotifications && (
        <Alert variant="-error">
          Notifications are not allowed or your browser does not support
          realtime notifications!
        </Alert>
      )}

      {!hasRole(roles, [HOST, HOST_ADMIN]) && <SupportHelp logo={logo} />}
      {container ? <Container>{children}</Container> : children}
    </Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  container: PropTypes.bool,
  allowNotifications: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  firstName: PropTypes.string.isRequired,
  companySubClientEnabled: PropTypes.bool.isRequired
};

Layout.defaultProps = {
  container: true
};

const mapStateToProps = state => {
  const { allowNotifications } = state.dashboard.notifications;
  const {
    roles,
    firstName,
    companySubClientEnabled
  } = state.authentication.token.retrieved;

  return {
    allowNotifications,
    roles,
    firstName,
    companySubClientEnabled
  };
};

export default connect(
  mapStateToProps,
  { logout }
)(Layout);
