import React from 'react';

const DemoVendor = () => (
  <div className="demo">
    <iframe title="demo" src="https://invis.io/QMSGYJ0AJ58">
      Your browser does not support iframes
    </iframe>
  </div>
);

export default DemoVendor;
