import { useState, useEffect } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

const updateURL = (history, values) => {
  const qs = queryString.stringify(values);
  history.push(`?${qs}`);
};

export const useStateToUrl = params => {
  const history = useHistory();
  const { query } = queryString.parseUrl(window.location.href);
  const initialValue = {};

  Object.keys(params).forEach(key => {
    if (query[key]) {
      initialValue[key] = query[key];
    } else if (params[key]) {
      initialValue[key] = params[key];
    }
  });

  const [value, setValue] = useState(initialValue);

  useState(() => {
    updateURL(history, value);
  }, []);

  const setValueEnhanced = params => {
    const values = value;
    Object.keys(params).forEach(key => {
      if (!params[key] && query[key]) {
        delete values[key];
      } else {
        values[key] = params[key];
      }
    });

    setValue({ ...values });
    updateURL(history, values);
  };

  return [value, setValueEnhanced];
};

export const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
};
