/* eslint react/prop-types: "off" */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { list } from '../../actions/Company/list';
import { del as deleteCompany } from '../../actions/Company/delete';
import Layout from '../Layout';
import { Create as CreateCompanyModal, Update as UpdateCompanyModal } from '.';
import { iconPlus, Loader } from '../Util';
import { Search, FilterButtons } from '../Common/Toolbar';
import { ListColumnTitle } from '../Common/List';
import { useStateToUrl } from '../../utils/hooks';
import { withAuth } from '../Authentication';

const List = ({
  list,
  submitSucceeded,
  location,
  deleteSuccess,
  retrieved,
  loading,
  deleteLoading,
  deleteCompany
}) => {
  const [createModalState, setCreateModalState] = useState(false);
  const [updateModalState, setUpdateModalState] = useState(false);
  const [company, setCompany] = useState();
  const [params, setParams] = useStateToUrl({
    order: '',
    orderDirection: '',
    search: '',
    filter: '',
    page: 1,
    itemsPerPage: 5
  });

  const urlToFetch = () => {
    const uri = location.pathname;
    const qs = [
      params.search ? `search_multiple_combined=${params.search}` : '',
      params.order && params.orderDirection
        ? `order[${params.order}]=${params.orderDirection}`
        : '',
      params.filter ? `type.name=${params.filter}` : '',
      params.page
        ? `page=${params.page}&itemsPerPage=${params.itemsPerPage}`
        : ''
    ]
      .filter(item => {
        return item !== '';
      })
      .join('&');

    return uri + (qs !== '' ? `?${qs}` : '');
  };

  useEffect(() => {
    list(urlToFetch());
  }, [params]);

  useEffect(() => {
    if (submitSucceeded) {
      setCreateModalState(false);
      setUpdateModalState(false);

      list(urlToFetch());
    }
  }, [submitSucceeded]);

  useEffect(() => {
    if (deleteSuccess) {
      list(urlToFetch());
    }
  }, [deleteSuccess]);

  const handleSorting = e => {
    let orderDirection;

    if (params.order !== e.target.name) {
      orderDirection = 'asc';
    } else {
      orderDirection = params.orderDirection === 'asc' ? 'desc' : 'asc';
    }

    setParams({ order: e.target.name, orderDirection });
  };

  const delItem = item => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      deleteCompany(item);
    }
  };

  return (
    <Layout>
      {createModalState && (
        <CreateCompanyModal
          modalState={createModalState}
          setModalState={setCreateModalState}
        />
      )}
      {updateModalState && (
        <UpdateCompanyModal
          modalState={updateModalState}
          setModalState={setUpdateModalState}
          company={company}
        />
      )}
      <Row>
        <Col md={12}>
          <h1 className="pt-5 pb-2">Company List</h1>
        </Col>
      </Row>
      <Row className="mt-3 d-flex align-items-center">
        <Col md={4}>
          <button
            type="button"
            className="btn btn--form-action"
            onClick={() => setCreateModalState(!createModalState)}
          >
            <img src={iconPlus} alt="add company" />{' '}
          </button>
        </Col>
        <Col md={8}>
          <Row className="align-items-lg-center">
            <Col md={12} lg={8}>
              <FilterButtons
                onClick={e =>
                  setParams({
                    filter:
                      e.target.value !== params.filter ? e.target.value : ''
                  })
                }
                activeButton={params.filter}
              />
            </Col>
            <Col md={12} lg={4}>
              <div className="d-flex">
                <div className="ml-auto">
                  <Search
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        setParams({ search: e.target.value });
                      }
                    }}
                    value={params.search}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="pt-4">
          <div className="table-responsive">
            <table className="table table-shadow">
              <thead>
                <tr>
                  <ListColumnTitle
                    label="Company Name"
                    name="name"
                    orderActive={params.order === 'name'}
                    orderDirection={params.orderDirection}
                    onClick={e => handleSorting(e)}
                  />
                  <ListColumnTitle
                    label="Address"
                    name="address"
                    orderActive={params.order === 'address'}
                    orderDirection={params.orderDirection}
                    onClick={e => handleSorting(e)}
                  />
                  <ListColumnTitle
                    label="Zip / City"
                    name="zip"
                    orderActive={params.order === 'zip'}
                    orderDirection={params.orderDirection}
                    onClick={e => handleSorting(e)}
                  />
                  <ListColumnTitle
                    label="Email"
                    name="notificationsEmail"
                    orderActive={params.order === 'notificationsEmail'}
                    orderDirection={params.orderDirection}
                    onClick={e => handleSorting(e)}
                  />
                  <ListColumnTitle
                    label="Role"
                    name="type.name"
                    orderActive={params.order === 'type.name'}
                    orderDirection={params.orderDirection}
                    onClick={e => handleSorting(e)}
                  />
                  <ListColumnTitle
                    label="Anonymized"
                    name="defaultAnonymous"
                    orderActive={params.order === 'defaultAnonymous'}
                    orderDirection={params.orderDirection}
                    onClick={e => handleSorting(e)}
                  />
                  <ListColumnTitle
                    label="NEST only"
                    name="defaultNestOnly"
                    orderActive={params.order === 'defaultNestOnly'}
                    orderDirection={params.orderDirection}
                    onClick={e => handleSorting(e)}
                  />
                  <ListColumnTitle
                    label="Status"
                    name="active"
                    orderActive={params.order === 'active'}
                    orderDirection={params.orderDirection}
                    onClick={e => handleSorting(e)}
                  />
                  <th width="91px" colSpan={2} />
                </tr>
              </thead>
              <tbody>
                {/* eslint-disable-next-line */}
                {loading || deleteLoading || !retrieved ? (
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                ) : !retrieved['hydra:totalItems'] ? (
                  <td colSpan="10">
                    <center>No items found.</center>
                  </td>
                ) : (
                  retrieved['hydra:member'].map(item => (
                    <tr key={item['@id']}>
                      <td>{item.name}</td>
                      <td>{item.address}</td>
                      <td>
                        {item.zip} {item.city}
                      </td>
                      <td>{item.notificationsEmail}</td>
                      <td>{item.typeName}</td>
                      <td>{item.defaultAnonymous ? 'Yes' : 'No'}</td>
                      <td>{item.defaultNestOnly ? 'Yes' : 'No'}</td>
                      <td>
                        <button
                          type="button"
                          className={`btn btn--small btn--small-${
                            item.active ? 'active' : 'inactive'
                          }`}
                        >
                          {item.active ? 'Active' : 'Inactive'}
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="btn btn--form-action"
                          onClick={() => {
                            setCompany(item);
                            setUpdateModalState(true);
                          }}
                        >
                          <i className="fi flaticon-file-edit" />
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => delItem(item)}
                          className="btn btn--danger"
                          title="Delete"
                        >
                          <i className="fi flaticon-file" />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {retrieved && (
            <div className="pt-4 pb-4">
              <Pagination
                innerClass="pagination justify-content-center"
                hideDisabled
                activePage={params.page}
                itemsCountPerPage={params.itemsPerPage}
                totalItemsCount={retrieved['hydra:totalItems']}
                onChange={page => {
                  setParams({ page });
                }}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    retrieved: state.company.list.retrieved,
    loading: state.company.list.loading,
    error: state.company.list.error,
    deletedItem: state.company.list.deletedItem,
    submitSucceeded:
      typeof state.form.company === 'undefined'
        ? false
        : state.form.company.submitSucceeded,
    deleteError: state.company.del.error,
    deleteLoading: state.company.del.loading,
    deleteSuccess: state.company.del.deleted
  };
};

const mapDispatchToProps = {
  list,
  deleteCompany
};

export default withRouter(
  withAuth(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(List)
  )
);
