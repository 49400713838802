import React from 'react';
import { List } from '../components/Company';
import { PrivateRoute } from '../components/Authentication';

export default [
  <PrivateRoute
    path="/companies"
    title="Companies"
    exact
    strict
    key="list"
    components={{ hostadmin: List }}
  />
];
