import axios from 'axios';

export function error(error) {
  return { type: 'REQUEST_SHOW_ERROR', error };
}

export function loading(loading) {
  return { type: 'REQUEST_SHOW_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'REQUEST_SHOW_SUCCESS', retrieved };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'REQUEST_SHOW_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
  };
}

export const retrieve = id => async dispatch => {
  dispatch(loading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/apartment_requests/${id}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(success(result.data));
  } catch (e) {
    dispatch(error(e.message));
  }

  dispatch(loading(false));
};
