/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import Form from './Form';
import { create } from '../../actions/Request/create';
import { Modal, iconClose } from '../Util';
import { formatDate } from '../../utils/helpers';

const Create = ({
  create,
  modalState,
  setModalState,
  duplicatedRequest,
  setDuplicatedRequest
}) => {
  useEffect(() => {
    return () => {
      setDuplicatedRequest(null);
    };
  });

  return (
    <Modal isOpen={modalState}>
      <div className="modal__content pb-0">
        <Row className="mb-5">
          <Col md={12}>
            <h1 className="heading heading--medium">Submit a new request</h1>
            <h2 className="heading heading--small">
              We will do our best to find the perfect appartment.
            </h2>
            <p className="text text--small">
              {formatDate(new Date(), 'en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </p>
            <button
              type="button"
              className="btn btn--close-modal"
              onClick={() => setModalState(!modalState)}
            >
              <img src={iconClose} alt="close modal" />
            </button>
          </Col>
        </Row>
      </div>
      <Form
        onSubmit={create}
        modalState={modalState}
        setModalState={setModalState}
        duplicatedRequest={duplicatedRequest}
      />
    </Modal>
  );
};

export default connect(
  null,
  { create }
)(Create);
