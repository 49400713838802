/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { sanitize } from 'dompurify';
import {
  ucwords,
  formatDate,
  formatDateSimple,
  hasRole,
  arrayToString,
  numberToCHF,
  calculateDiff
} from '../../utils/helpers';
import {
  iconClose,
  iconCloseRed,
  iconApartment,
  iconClient,
  iconGuest,
  Loader
} from '../Util';
import { CLIENT, SUB_CLIENT, VENDOR, HOST_ADMIN } from '../../constants/roles';
import {
  updateSuccess as updateSuccessAction,
  updateError as updateErrorAction,
  updateStatus as updateStatusAction
} from '../../actions/Request/update';
import { list as dashboardList } from '../../actions/Dashboard/list';

const userFullName = request => {
  return `${ucwords(request.user.firstName)} ${ucwords(request.user.lastName)}`;
};

const dateOptions = {
  locale: 'en-US',
  options: {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
};

const Detail = ({
  request: req,
  modalState,
  setModalState,
  roles,
  updateSuccessAction,
  updateErrorAction,
  updateStatusAction,
  updateLoading,
  updateError,
  updated,
  dashboardList,
  setDuplicatedRequest,
  setCreateRequestModalState
}) => {
  const [request, setRequest] = useState(req);

  useEffect(() => {
    if (updated) {
      if (updated.status.name === 'closed') {
        toast.success('Request closed succesfully.');
      }

      setRequest(updated);
      dashboardList();
    }

    return () => {
      updateSuccessAction(null);
    };
  }, [updated]);

  useEffect(() => {
    if (updateError) {
      toast.error(updateError);
    }

    return () => {
      updateErrorAction(null);
    };
  }, [updateError]);

  const duplicateRequest = request => {
    setDuplicatedRequest(request);
    setCreateRequestModalState(true);
    setModalState(!modalState);
  };

  const getDateDiff = (arrival, departure) => {
    const diff = calculateDiff(arrival, departure);

    if (!diff) {
      return '';
    }

    return ` (${diff} Night${diff > 1 ? 's' : ''})`;
  };

  return (
    <div className="modal__content pb-0">
      {!!updateLoading && <Loader />}
      <Row>
        <Col md={12} className="pr-5">
          <h1 className="heading heading--medium">
            Request - ({request.title}) #{request.id}
          </h1>
          <p>{formatDate(request.dateCreated)}</p>
          {hasRole(roles, [CLIENT, SUB_CLIENT, HOST_ADMIN]) && (
            <a
              href="#"
              className="text--link-pink no-decoration d-inline-block mb-3"
              onClick={() => duplicateRequest(request)}
            >
              <i className="fas fa-copy" /> Duplicate
            </a>
          )}
          <button
            type="button"
            className="btn btn--close-modal"
            onClick={() => setModalState(!modalState)}
          >
            <img src={iconClose} alt="close modal" />
          </button>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {!(
            hasRole(roles, VENDOR) &&
            request.anonymous &&
            !request.hasAcceptedOffers &&
            !request.hasBookedOffers
          ) && (
            <>
              <h2 className="modal__header">
                <img src={iconClient} alt="icon client" /> Client
              </h2>
              <p className="modal__content__infos">
                {(!hasRole(roles, VENDOR) ||
                  ['pending', 'pending_confirmation'].includes(
                    request.status.name
                  )) && (
                  <>
                    <strong className="w-30 d-inline-block">Name</strong>{' '}
                    {userFullName(request)}
                    <br />
                  </>
                )}
                {!hasRole(roles, VENDOR) && (
                  <>
                    <strong className="w-30 d-inline-block">Email</strong>{' '}
                    {request.user.email}
                    <br />
                  </>
                )}
                {(!(request.anonymous && hasRole(roles, VENDOR)) ||
                  !hasRole(roles, VENDOR) ||
                  request.hasAcceptedOffers) && (
                  <>
                    <strong className="w-30 d-inline-block">Company</strong>{' '}
                    {request.user.company.name}
                  </>
                )}
                {request.differentBillingAddress &&
                  (!hasRole(roles, VENDOR) || request.hasBookedOffers) && (
                    <>
                      <br />
                      <strong className="w-30 d-inline-block">
                        Billing Company
                      </strong>{' '}
                      {request.billingCompany}
                    </>
                  )}

                {request.differentBillingAddress &&
                  (!hasRole(roles, VENDOR) || request.hasBookedOffers) && (
                    <>
                      <br />
                      <strong className="w-30 d-inline-block">
                        Billing Address
                      </strong>{' '}
                      {request.billingAddress
                        ? // eslint-disable-next-line
                          request.billingAddress + ','
                        : ''}
                      {request.billingZip} {request.billingCity},{' '}
                      {request.billingCountry}
                    </>
                  )}

                {request.differentContractAddress &&
                  (!hasRole(roles, VENDOR) || request.hasBookedOffers) && (
                    <>
                      <br />
                      <strong className="w-30 d-inline-block">
                        Contract Company
                      </strong>{' '}
                      {request.contractCompany}
                    </>
                  )}

                {request.differentContractAddress &&
                  (!hasRole(roles, VENDOR) || request.hasBookedOffers) && (
                    <>
                      <br />
                      <strong className="w-30 d-inline-block">
                        Contract Address
                      </strong>{' '}
                      {request.contractAddress
                        ? // eslint-disable-next-line
                          request.contractAddress + ','
                        : ''}
                      {request.contractZip} {request.contractCity},{' '}
                      {request.contractCountry}
                    </>
                  )}
                {request.billingToGuest &&
                  !request.differentBillingAddress &&
                  (!hasRole(roles, VENDOR) || request.hasBookedOffers) && (
                    <>
                      <br />
                      <strong className="w-30 d-inline-block">
                        Billing to guest
                      </strong>
                      {' Yes'}
                    </>
                  )}

                {(!(request.anonymous && hasRole(roles, VENDOR)) ||
                  !hasRole(roles, VENDOR) ||
                  request.hasAcceptedOffers) && (
                  <>
                    <br />
                    <strong className="w-30 d-inline-block">
                      Client References
                    </strong>{' '}
                    {arrayToString(
                      request.clientReferences.reduce((acc, curr) => {
                        if (curr.reference) {
                          acc.push({ name: curr.reference });
                        }

                        return acc;
                      }, []),
                      ' - '
                    ) || 'Not specified'}
                  </>
                )}
                <br />
              </p>
            </>
          )}
          <h2 className="modal__header">
            <img src={iconGuest} alt="icon client" /> Request Information
          </h2>
          <p className="mb-5 modal__content__infos">
            <strong className="w-30 d-inline-block">Name</strong>{' '}
            {request.guestName}
            <br />
            {['booked', 'booked_closed'].includes(request.status.name) && (
              <>
                <strong className="w-30 d-inline-block">Email</strong>{' '}
                {request.guestEmail}
                <br />
              </>
            )}
            <strong className="w-30 d-inline-block">Adults</strong>{' '}
            {request.adults || 'None'}
            <br />
            <strong className="w-30 d-inline-block">Children</strong>{' '}
            {request.children || 'None'}
          </p>
          {request.guests.length !== 0 && (
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <td>
                    <strong className="w-30">#</strong>
                  </td>
                  <td>
                    <strong className="w-30">First name</strong>
                  </td>
                  <td>
                    <strong className="w-30">Last name</strong>
                  </td>
                  <td>
                    <strong className="w-30">Date of birth</strong>
                  </td>
                  <td>
                    <strong className="w-30">Permit</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                {request.guests.map((e, i) => {
                  return (
                    <tr key={e.id}>
                      <td>{i + 1}</td>
                      <td>{e.firstName}</td>
                      <td>{e.lastName}</td>
                      <td>{formatDateSimple(e.dateOfBirth)}</td>
                      <td>{e.permit}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </Col>
        <Col md={6}>
          <h2 className="modal__header">
            <img src={iconApartment} alt="icon apartment" /> Guest Information
          </h2>
          <div className="mb-5 modal__content__infos">
            <strong className="w-30 d-inline-block">City/Region</strong>{' '}
            {arrayToString(request.regions)}
            <br />
            <span className="d-flex">
              <strong className="w-30 d-inline-block">
                Preferred Location
              </strong>
              &nbsp;
              <span className="w-70 d-inline-block">
                {' '}
                {request.preferredLocation}
              </span>
            </span>
            <strong className="w-30 d-inline-block">Apartment Type</strong>{' '}
            {arrayToString(request.apartmentTypes)}
            <br />
            <span className="d-flex">
              <strong className="w-30 d-inline-block">Date</strong>&nbsp;
              <span className="w-70 d-inline-block">
                {' '}
                {formatDate(request.arrival, ...Object.values(dateOptions))}
                {request.departure
                  ? ` - ${formatDate(
                      request.departure,
                      ...Object.values(dateOptions)
                    )}`
                  : ' - Open end'}
                {getDateDiff(request.arrival, request.departure)}
              </span>
            </span>
            <strong className="w-30 d-inline-block">Request Rate Type</strong>{' '}
            {ucwords(request.requestedRateType)}
            <br />
            <strong className="w-30 d-inline-block">Cleaning</strong>{' '}
            {ucwords(request.cleaning, false)}
            <br />
            <strong className="w-30 d-inline-block">Budgets</strong>{' '}
            {numberToCHF(request.budgets)}
            <br />
            <strong className="w-30 d-inline-block">Baby Package</strong>{' '}
            {request.babyPackage ? 'Yes' : 'No'}
            <br />
            <strong className="w-30 d-inline-block">Parking Slot</strong>{' '}
            {request.parking ? 'Yes' : 'No'}
            <br />
            <strong className="w-30 d-inline-block">Pets</strong> {request.pets}
            {request.pets ? ` - ${request.petBreeds}` : ''}
            <br />
            <span className="d-flex">
              <strong className="w-30 d-inline-block">Remarks</strong>&nbsp;
              <div
                className="w-70 d-inline-block"
                dangerouslySetInnerHTML={{
                  __html: sanitize(request.comment)
                }}
              />
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        {(hasRole(roles, [CLIENT, SUB_CLIENT]) &&
          request.status.name !== 'booked' &&
          request.status.name.indexOf('closed') < 0) ||
        (hasRole(roles, [HOST_ADMIN]) &&
          request.status.name.indexOf('closed') < 0) ? (
          <Col md={12}>
            <button
              type="button"
              className="btn btn--decline-offer text-red p-0 m-0"
              onClick={() => {
                const shouldCancel = window.confirm(
                  'Are you sure you want to cancel this request?'
                );

                if (shouldCancel) {
                  updateStatusAction(request.id, 'close');
                }
              }}
            >
              <img
                src={iconCloseRed}
                alt="Cancel Request"
                className="btn__close-icon mr-2"
              />
              Cancel Request{' '}
            </button>
            <hr className="mt-2" />
          </Col>
        ) : (
          <Col md={12}>
            <hr className="mt-2" />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default connect(
  state => {
    const { updateLoading, updateError, updated } = state.request.update;

    return { updateLoading, updateError, updated };
  },
  {
    updateSuccessAction,
    updateErrorAction,
    updateStatusAction,
    dashboardList
  }
)(Detail);
