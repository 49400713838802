import React from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';

const TextInput = ({ id, input, placeholder }) => {
  return (
    <Cleave
      className="input"
      id={id}
      placeholder={placeholder}
      {...input}
      value={input.value}
      onBlur={e => {
        input.onBlur(e.target.rawValue);
      }}
      onChange={e => {
        input.onChange(e.target.rawValue);
      }}
      options={{
        numeral: true,
        prefix: 'CHF ',
        delimiter: "'",
        noImmediatePrefix: true,
        rawValueTrimPrefix: true
      }}
    />
  );
};

TextInput.defaultProps = {
  className: null,
  placeholder: null
};

TextInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  placeholder: PropTypes.string
};

export default TextInput;
