import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({ className, items, ...props }) => {
  const { id, input, disabled } = props;

  if (!items) {
    return (
      <div className="checkbox">
        <input
          type="checkbox"
          className="checkbox__input"
          id={id}
          {...input}
          disabled={disabled}
        />
        <span className="checkbox__square" />
      </div>
    );
  }

  return items.map((e, i) => {
    const initial = Array.isArray(input.value)
      ? input.value.includes(e.value)
      : e.checked;
    const [isChecked, setChecked] = useState(initial);

    return (
      <div
        key={e.id || e.name}
        className={`checkbox ${i !== items.length - 1 ? 'mb-2' : ''}`}
      >
        <input
          type="checkbox"
          className="checkbox__input"
          id={e.id || e.name}
          {...input}
          name={`${e.name}`}
          value={e.value}
          checked={isChecked ? 'checked' : ''}
          onBlur={e => e.preventDefault()}
          disabled={disabled}
          onChange={() => {
            const newValue = [...input.value];

            if (!isChecked) {
              newValue.push(e.value);
            } else {
              newValue.splice(newValue.indexOf(e.value), 1);
            }

            setChecked(!isChecked);

            return input.onChange(newValue);
          }}
        />
        <span className="checkbox__square" />
        <label htmlFor={e.id || e.name} className="checkbox__label">
          {e.label}
        </label>
      </div>
    );
  });
};

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  checked: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool,
      value: PropTypes.string
    })
  )
};

Checkbox.defaultProps = {
  className: '',
  id: '',
  checked: false,
  items: null
};

export default Checkbox;
