/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Input } from '../Common/Form';
import logo from '../../assets/nest_logo.svg';
import { Loader } from '../Util';
import { resetPasswordConfirm } from '../../actions/Authentication/resetPasswordConfirm';
import Layout from '../Layout';

const renderField = data => (
  <div className="form-group row">
    <Input
      {...data}
      type={data.type}
      step={data.step}
      className="col-12 text-left"
      required={data.required}
      placeholder={data.placeholder}
      id={`resetpw_confirm_${data.input.name}`}
      error={data.meta.touched && data.meta.error ? data.meta.error : null}
    />
  </div>
);

const ResetPasswordConfirm = ({
  handleSubmit,
  resetPasswordConfirm,
  error,
  submitting,
  submitSucceeded
}) => {
  useEffect(() => {
    if (submitSucceeded) {
      toast.success('Password saved.');
    }
  }, [submitSucceeded]);

  return (
    <div className="login">
      <div className="login__wrapper">
        <div className="login__box">
          <img src={logo} alt="Nest Logo" className="login__logo" />
          <h4 className="text text--normal" style={{ marginBottom: '40px' }}>
            Set Password.
          </h4>

          {submitSucceeded && <Redirect to="/login" />}

          {!submitting && error && (
            <h6
              className="login__heading login__heading--small"
              style={{ color: 'red', marginBottom: '0' }}
            >
              {error}
            </h6>
          )}

          {!submitSucceeded && (
            <form onSubmit={handleSubmit(resetPasswordConfirm)}>
              <Field
                id="password"
                name="password"
                type="password"
                placeholder="Password"
                component={renderField}
              />
              <Field
                id="passwordConfirm"
                name="passwordConfirm"
                type="password"
                placeholder="Password confirm"
                component={renderField}
              />

              <button type="submit" className="btn btn--primary">
                Send
              </button>
              {submitting && <Loader />}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

const validate = values => {
  const errors = {};

  if (!values.password) {
    errors.password = 'Password is required.';
  }

  if (!values.passwordConfirm) {
    errors.passwordConfirm = 'Password confirm is required.';
  }

  if (values.password !== values.passwordConfirm) {
    errors.passwordConfirm = 'Passwords are not equal.';
  }

  if (values.password && values.password.length < 8) {
    errors.password = 'Password should be at least 8 characters long.';
  }

  return errors;
};

const reduxFormResetPassword = reduxForm({
  form: 'resetpassword',
  validate
})(ResetPasswordConfirm);

export default connect(
  (state, ownProps) => {
    const { id, nonce } = ownProps.match.params;

    return {
      initialValues: { id, nonce }
    };
  },
  { resetPasswordConfirm }
)(reduxFormResetPassword);
