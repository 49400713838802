import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import store from './store';
import * as serviceWorker from './serviceWorker';
import './css/vendors.css';
import './css/style.css';
import App from './App';
import './utils/polyfills';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
