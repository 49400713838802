import React from 'react';
import { Application } from '../components/Application';
import { PublicRoute } from '../components/Authentication';

export default [
  <PublicRoute
    key="application"
    path="/apply"
    title="Apply for an account"
    component={Application}
    strict
    exact
  />
];
