/* eslint react/prop-types: "off" */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { list } from '../../actions/User/list';
import { del as deleteUser } from '../../actions/User/delete';
import { invite, reset as resetInvite } from '../../actions/User/invite';
import { formatDate, hasRole } from '../../utils/helpers';
import Layout from '../Layout';
import { Create as CreateUserModal, Update as UpdateUserModal } from '.';
import { iconPlus, Loader, iconKey } from '../Util';
import { Search, FilterButtons } from '../Common/Toolbar';
import { ListColumnTitle } from '../Common/List';
import { useStateToUrl } from '../../utils/hooks';
import { withAuth } from '../Authentication';
import { HOST_ADMIN, CLIENT } from '../../constants/roles';

const List = ({
  list,
  submitSucceeded,
  location,
  deleteSuccess,
  inviteSuccess,
  retrieved,
  loading,
  deleteLoading,
  inviteLoading,
  deleteUser,
  roles,
  invite
}) => {
  const [createModalState, setCreateModalState] = useState(false);
  const [updateModalState, setUpdateModalState] = useState(false);
  const [user, setUser] = useState();
  const [params, setParams] = useStateToUrl({
    order: '',
    orderDirection: '',
    search: '',
    filter: '',
    page: 1,
    itemsPerPage: 5
  });

  const urlToFetch = () => {
    const uri = location.pathname;
    const qs = [
      params.search ? `search_multiple_combined=${params.search}` : '',
      params.order && params.orderDirection
        ? `order[${params.order}]=${params.orderDirection}`
        : '',
      params.filter ? `company.type.name=${params.filter}` : '',
      params.page
        ? `page=${params.page}&itemsPerPage=${params.itemsPerPage}`
        : '',
      hasRole(roles, CLIENT) ? 'roles=ROLE_SUB_CLIENT' : ''
    ]
      .filter(item => {
        return item !== '';
      })
      .join('&');

    return uri + (qs !== '' ? `?${qs}` : '');
  };

  useEffect(() => {
    list(urlToFetch());
  }, [params]);

  useEffect(() => {
    if (submitSucceeded) {
      setCreateModalState(false);
      setUpdateModalState(false);

      list(urlToFetch());
    }
  }, [submitSucceeded]);

  useEffect(() => {
    if (deleteSuccess) {
      list(urlToFetch());
    }
  }, [deleteSuccess]);

  useEffect(() => {
    if (inviteSuccess) {
      resetInvite();
      list(urlToFetch());
    }
  }, [inviteSuccess]);

  const handleSorting = e => {
    let orderDirection;

    if (params.order !== e.target.name) {
      orderDirection = 'asc';
    } else {
      orderDirection = params.orderDirection === 'asc' ? 'desc' : 'asc';
    }

    setParams({ order: e.target.name, orderDirection });
  };

  const delItem = item => {
    if (window.confirm('Are you sure you want to delete this item?')) {
      deleteUser(item);
    }
  };

  const inviteUser = item => {
    if (
      window.confirm(
        'Are you sure you want to send an email with the link to set the password?'
      )
    ) {
      invite(item);
    }
  };

  return (
    <Layout>
      {createModalState && (
        <CreateUserModal
          modalState={createModalState}
          setModalState={setCreateModalState}
        />
      )}
      {updateModalState && (
        <UpdateUserModal
          modalState={updateModalState}
          setModalState={setUpdateModalState}
          user={user}
        />
      )}
      <Row>
        <Col md={12}>
          <h1 className="pt-5 pb-2">User List</h1>
        </Col>
      </Row>
      <Row className="mt-3 d-flex align-items-center">
        <Col md={4}>
          <button
            type="button"
            className="btn btn--form-action"
            onClick={() => setCreateModalState(!createModalState)}
          >
            <img src={iconPlus} alt="add user" />{' '}
          </button>
        </Col>
        <Col md={8}>
          <Row className="align-items-lg-center">
            {hasRole(roles, HOST_ADMIN) && (
              <Col md={12} lg={8}>
                <FilterButtons
                  onClick={e =>
                    setParams({
                      filter:
                        e.target.value !== params.filter ? e.target.value : ''
                    })
                  }
                  activeButton={params.filter}
                />
              </Col>
            )}
            <Col md={12} lg={hasRole(roles, HOST_ADMIN) ? 4 : 12}>
              <div className="d-flex">
                <div className="ml-auto">
                  <Search
                    onKeyPress={e => {
                      if (e.key === 'Enter') {
                        setParams({ search: e.target.value });
                      }
                    }}
                    value={params.search}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} className="pt-4">
          <div className="table-responsive">
            <table className="table table-shadow">
              <thead>
                <tr>
                  <ListColumnTitle
                    label="Name"
                    name="firstName"
                    orderActive={params.order === 'firstName'}
                    orderDirection={params.orderDirection}
                    onClick={handleSorting}
                  />
                  <ListColumnTitle
                    label="Company"
                    name="company.name"
                    orderActive={params.order === 'company.name'}
                    orderDirection={params.orderDirection}
                    onClick={handleSorting}
                  />
                  <ListColumnTitle
                    label="Email"
                    name="email"
                    orderActive={params.order === 'email'}
                    orderDirection={params.orderDirection}
                    onClick={handleSorting}
                  />
                  <ListColumnTitle
                    label="Mobile"
                    name="mobileNumber"
                    orderActive={params.order === 'mobileNumber'}
                    orderDirection={params.orderDirection}
                    onClick={handleSorting}
                  />
                  <ListColumnTitle
                    label="Phone"
                    name="phoneNumber"
                    orderActive={params.order === 'phoneNumber'}
                    orderDirection={params.orderDirection}
                    onClick={handleSorting}
                  />
                  <ListColumnTitle
                    label="Role"
                    name="roles"
                    orderActive={params.order === 'roles'}
                    orderDirection={params.orderDirection}
                    onClick={handleSorting}
                  />
                  <ListColumnTitle
                    label="Is Active"
                    name="active"
                    orderActive={params.order === 'active'}
                    orderDirection={params.orderDirection}
                    onClick={handleSorting}
                  />
                  <ListColumnTitle
                    label="Last Login"
                    name="lastLogin"
                    orderActive={params.order === 'lastLogin'}
                    orderDirection={params.orderDirection}
                    onClick={handleSorting}
                  />
                  <ListColumnTitle
                    label="Invitation Date"
                    name="invitationDate"
                    orderActive={params.order === 'invitationDate'}
                    orderDirection={params.orderDirection}
                    onClick={handleSorting}
                  />
                  <th width="125px" colSpan={3} />
                </tr>
              </thead>
              <tbody>
                {/* eslint-disable-next-line */}
                {loading || deleteLoading || inviteLoading || !retrieved ? (
                  <tr>
                    <td>
                      <Loader />
                    </td>
                  </tr>
                ) : !retrieved['hydra:totalItems'] ? (
                  <td colSpan="10">
                    <center>No items found.</center>
                  </td>
                ) : (
                  retrieved['hydra:member'].map(item => (
                    <tr key={item['@id']}>
                      <td>
                        {item.firstName} {item.lastName}
                      </td>
                      <td>{item.company.name}</td>
                      <td>{item.email}</td>
                      <td>{item.mobileNumber}</td>
                      <td>{item.phoneNumber}</td>
                      <td>{item.roleName}</td>
                      <td>
                        <button
                          type="button"
                          className={`btn btn--small btn--small-${
                            item.active === true ? 'active' : 'inactive'
                          }`}
                        >
                          {item.active === true ? 'Active' : 'Inactive'}
                        </button>
                      </td>
                      <td>{formatDate(item.lastLogin)}</td>
                      <td>{formatDate(item.invitationDate)}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn--form-action"
                          onClick={() => {
                            setUser(item);
                            setUpdateModalState(true);
                          }}
                        >
                          <i className="fi flaticon-file-edit" />
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => delItem(item)}
                          className="btn btn--danger"
                          title="Delete"
                        >
                          <i className="fi flaticon-file" />
                        </button>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => inviteUser(item)}
                          className="btn btn--warning"
                          title="Invite / Reset PW"
                        >
                          <img
                            className="icon"
                            src={iconKey}
                            alt="Reset Password"
                          />
                        </button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          {retrieved && (
            <div className="pt-4 pb-4">
              <Pagination
                innerClass="pagination justify-content-center"
                hideDisabled
                activePage={params.page}
                itemsCountPerPage={params.itemsPerPage}
                totalItemsCount={retrieved['hydra:totalItems']}
                onChange={page => {
                  setParams({ page });
                }}
                itemClass="page-item"
                linkClass="page-link"
              />
            </div>
          )}
        </Col>
      </Row>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    retrieved: state.user.list.retrieved,
    loading: state.user.list.loading,
    error: state.user.list.error,
    deletedItem: state.user.list.deletedItem,
    submitSucceeded:
      typeof state.form.user === 'undefined'
        ? false
        : state.form.user.submitSucceeded,
    deleteError: state.user.del.error,
    deleteLoading: state.user.del.loading,
    deleteSuccess: state.user.del.deleted,
    inviteLoading: state.user.invite.loading,
    inviteSuccess: state.user.invite.invited,
    inviteError: state.user.invite.error
  };
};

const mapDispatchToProps = {
  list,
  resetInvite,
  deleteUser,
  invite
};

export default withRouter(
  withAuth(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(List)
  )
);
