import React from 'react';
import { Input } from '..';
import PropTypes from 'prop-types';

const InlineField = props => {
  const {
    meta,
    type,
    inputClass,
    required,
    placeholder,
    prefix,
    input
  } = props;

  return (
    <Input
      {...props}
      type={type}
      className={inputClass}
      required={required}
      placeholder={placeholder}
      id={`${prefix}${input.name}`}
      error={meta.touched && meta.error ? meta.error : null}
    />
  );
};

InlineField.propTypes = {
  prefix: PropTypes.string,
  inputClass: PropTypes.string,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired
};

InlineField.defaultProps = {
  prefix: '',
  inputClass: 'col-sm-6 mb-2',
  placeholder: undefined,
  required: false
};

export default InlineField;
