import React from 'react';
import { List } from '../components/Notification';
import { PrivateRoute } from '../components/Authentication';

export default [
  <PrivateRoute
    path="/notifications"
    title="Notifications"
    component={List}
    exact
    strict
    key="list"
  />,
  <PrivateRoute
    path="/notifications/:page"
    component={List}
    exact
    strict
    key="page"
  />
];
