import request from './Request';
import dashboard from './Dashboard';
import user from './User';
import notification from './Notification';
import offer from './Offer';
import company from './Company';
import authentication from './Authentication';
import notificationFirebase from './NotificationFirebase';
import noAvailableOffer from './NoAvailableOffer';

export default {
  request,
  dashboard,
  user,
  offer,
  company,
  notification,
  notificationFirebase,
  authentication,
  noAvailableOffer
};
