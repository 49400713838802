import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { iconCamera } from '../../../Util';

const readFileAsync = file => {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();

    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

const FileInput = ({
  id,
  accept,
  input,
  hasPreview,
  multiple,
  uploadDir,
  uploadText,
  clearFilesText,
  meta: { initial }
}) => {
  let defaultPreview = initial
    ? `${process.env.REACT_APP_API_ENTRYPOINT}/${uploadDir}/${initial}`
    : iconCamera;
  let defaultImages = [];

  if (multiple && initial) {
    defaultImages = initial.map(e => {
      return {
        file: {
          name: e
        },
        base64: `${process.env.REACT_APP_API_ENTRYPOINT}/${uploadDir}/${e}`
      };
    });

    defaultPreview = defaultImages[0].base64;
  }

  const [preview, setPreview] = useState(defaultPreview);
  const [images, setImages] = useState(defaultImages);
  const fileRef = useRef(null);

  useEffect(() => {
    if (input.value && Array.isArray(input.value) && input.value.length) {
      const images = input.value.reduce((accumulator, image) => {
        accumulator.push({
          file: {
            name: image
          },
          base64: `${
            process.env.REACT_APP_API_ENTRYPOINT
          }/${uploadDir}/${image}`
        });

        return accumulator;
      }, []);

      setImages(images);
      setPreview(images[0].base64);
    } else {
      setImages(defaultImages);
      setPreview(defaultPreview);
    }
  }, [input.value]);

  const clearFiles = () => {
    fileRef.current.value = null;
    setImages(defaultImages);
    setPreview(defaultPreview);
    input.onChange(null);
  };

  const handleImageChange = async e => {
    e.preventDefault();
    let tmpImages = [];

    [...e.target.files].forEach(file => {
      readFileAsync(file).then(result => {
        const currentImage = {
          file,
          base64: result
        };

        tmpImages = [currentImage, ...tmpImages];

        setImages(tmpImages);
        setPreview(tmpImages[0].base64);
      });
    });

    input.onChange(e);
  };

  return (
    <>
      <Row>
        <Col md={12}>
          <div className="file-input">
            {hasPreview && (
              <div
                className="file-input__preview"
                style={{
                  height: '250px'
                }}
              >
                <img src={preview} alt="Preview" />
              </div>
            )}
            <div className="d-inline-block position-relative mr-2">
              <button
                type="button"
                className="file-input__button file-input__button--gray mr-0"
              >
                {uploadText}
              </button>
              <input
                id={id}
                type="file"
                onChange={handleImageChange}
                accept={accept}
                name={input.name}
                multiple={multiple}
                ref={fileRef}
              />
            </div>
            {input.value && images.length > 0 && (
              <button
                type="button"
                className="file-input__button file-input__button--white"
                onClick={() => clearFiles()}
              >
                {clearFilesText}
              </button>
            )}
          </div>
        </Col>
      </Row>
      {!!(hasPreview && multiple && images.length) && (
        <Row className="mt-4">
          {images.map(e => {
            return (
              <Col md={6} key={e.file.name}>
                <div
                  className="file-input__preview"
                  onClick={() => setPreview(e.base64)}
                  style={{ height: '100px' }}
                >
                  <img src={e.base64} alt="Preview" />
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </>
  );
};

FileInput.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func
  }).isRequired,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  hasPreview: PropTypes.bool,
  uploadText: PropTypes.string,
  clearFilesText: PropTypes.string,
  uploadDir: PropTypes.string,
  meta: PropTypes.object.isRequired
};

FileInput.defaultProps = {
  accept: 'undefined',
  multiple: false,
  hasPreview: true,
  uploadText: 'Choose file',
  clearFilesText: 'Clear',
  uploadDir: process.env.REACT_APP_IMAGES_UPLOAD_DIR_USER
};

export default FileInput;
