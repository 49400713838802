import React from 'react';

const DemoClient = () => (
  <div className="demo">
    <iframe
      title="demo"
      src="https://invis.io/XPZGPTOFGUH#/437536680_Nest_Login"
    >
      Your browser does not support iframes
    </iframe>
  </div>
);

export default DemoClient;
