import React from 'react';
import PropTypes from 'prop-types';

const SelectInput = ({ items, disabled, placeholder, input }) => (
  <div className="select-input">
    <select
      className="select-input__select"
      disabled={disabled}
      {...input}
      value={input.value}
    >
      <option value="" default>
        {placeholder}
      </option>
      {items.map(e => (
        <option key={e.value || e.name} value={e.value || e.name}>
          {e.label || e.name}
        </option>
      ))}
    </select>
  </div>
);

SelectInput.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

SelectInput.defaultProps = {
  placeholder: 'Please choose...',
  disabled: false
};

export default SelectInput;
