import axios from 'axios';

export const loading = loading => {
  return { type: 'DASHBOARD_LIST_LOADING', loading };
};

export const error = error => {
  return { type: 'DASHBOARD_LIST_ERROR', error };
};

export const retrieved = retrieved => {
  return { type: 'DASHBOARD_LIST_SUCCESS', retrieved };
};

export const list = queryString => async dispatch => {
  dispatch(loading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/apartment_requests/dashboard${
        queryString ? `?${queryString}` : ''
      }`,
      {
        headers: {
          Accept: 'application/ld+json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );

    dispatch(retrieved(result.data));
  } catch (e) {
    dispatch(error(e.message));
  }

  dispatch(loading(false));
};
