import axios from 'axios';
import { SubmissionError } from 'redux-form';

export function error(error) {
  return { type: 'OFFER_EMAIL_PDF_ERROR', error };
}

export function loading(loading) {
  return { type: 'OFFER_EMAIL_PDF_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'OFFER_EMAIL_PDF_SUCCESS', retrieved };
}

export const sendEmail = ({ id, recipient, cc, price }) => async dispatch => {
  dispatch(loading(true));

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_ENTRYPOINT}/offers/${id}/email_pdf`,
      {
        recipient,
        cc,
        price
      },
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );

    dispatch(success(data));
  } catch (e) {
    if (e.response.status === 400) {
      const { data } = e.response;

      if (!data.violations) {
        dispatch(error(e.message));
        throw new SubmissionError({
          _error: e.message
        });
      }

      const errors = {
        _error: 'Submission failed. Please fix the fields marked in red.'
      };

      data.violations.forEach(violation => {
        errors[violation.propertyPath.replace(/\[|\]/g, '')] =
          violation.message;
      });

      dispatch(error(errors._error));

      throw new SubmissionError(errors);
    } else {
      dispatch(error(e.message));
      throw new SubmissionError({
        _error: e.message
      });
    }
  }

  dispatch(loading(false));
};
