/* eslint react/prop-types: "off" */
import React, { useEffect } from 'react';
import { matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import qs from 'query-string';
import { pdf } from '../../actions/Offer/pdf';
import { Loader } from '../Util';

const Pdf = ({ loading, retrieved, error, pdf, location }) => {
  useEffect(() => {
    const match = matchPath(location.pathname, {
      path: '/offers/:id/pdf',
      exact: true,
      strict: false
    });

    const price =
      // eslint-disable-next-line
      qs.parse(location.search, { ignoreQueryPrefix: true }).price == 'true';

    pdf(match.params.id, price);
  }, {});

  return (
    <>
      {loading && <Loader />}

      {retrieved && (
        <iframe
          title="pdf"
          src={retrieved}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
          frameBorder="0"
        />
      )}
    </>
  );
};

export default connect(
  state => {
    const { error, loading, retrieved } = state.offer.pdf;
    return { error, loading, retrieved };
  },
  { pdf }
)(Pdf);
