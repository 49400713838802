/* eslint react/prop-types: "off" */
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

const withAuth = WrappedComponent => ({ token, ...rest }) => {
  return <WrappedComponent token={token} {...rest} />;
};

withAuth.propTypes = {
  token: PropTypes.string.isRequired
};

export default compose(
  connect(state => {
    let [token, roles] = [null, null];

    const { retrieved } = state.authentication.token;

    if (retrieved) {
      ({ token, roles } = retrieved);
    }

    return { token, roles };
  }),
  withAuth
);
