import { toast } from 'react-toastify';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'COMPANY_DELETE_ERROR', error };
}

export function loading(loading) {
  return { type: 'COMPANY_DELETE_LOADING', loading };
}

export function success(deleted) {
  return { type: 'COMPANY_DELETE_SUCCESS', deleted };
}

export function del(item) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(item['@id'], { method: 'DELETE' })
      .then(() => {
        dispatch(loading(false));
        toast.success(`${item.name} company successfully deleted`);
        dispatch(success(item));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
        toast.error(
          `${
            item.name
          } could not be deleted, maybe there are data related to this company!`
        );
      });
  };
}
