import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import {
  fetch,
  extractHubURL,
  normalize,
  mercureSubscribe as subscribe
} from '../../utils/dataAccess';
import { success as createSuccess } from './create';
import { loading, error } from './delete';

export function retrieveError(retrieveError) {
  return { type: 'COMPANY_UPDATE_RETRIEVE_ERROR', retrieveError };
}

export function retrieveLoading(retrieveLoading) {
  return { type: 'COMPANY_UPDATE_RETRIEVE_LOADING', retrieveLoading };
}

export function retrieveSuccess(retrieved) {
  return { type: 'COMPANY_UPDATE_RETRIEVE_SUCCESS', retrieved };
}

export function retrieve(id) {
  return dispatch => {
    dispatch(retrieveLoading(true));

    return fetch(id)
      .then(response =>
        response
          .json()
          .then(retrieved => ({ retrieved, hubURL: extractHubURL(response) }))
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        // change the company iri to id because we are sending the form as form data instead of json
        retrieved.type = retrieved.type ? retrieved.type.split('/')[2] : null;

        // change the consultant iri to id because we are sending the form as form data instead of json
        retrieved.consultant = retrieved.consultant
          ? retrieved.consultant.split('/')[2]
          : null;

        if (retrieved.regions) {
          retrieved.regions = retrieved.regions.map(item => item.split('/')[2]);
        }

        dispatch(retrieveLoading(false));
        dispatch(retrieveSuccess(retrieved));

        if (hubURL) dispatch(mercureSubscribe(hubURL, retrieved['@id']));
      })
      .catch(e => {
        dispatch(retrieveLoading(false));
        dispatch(retrieveError(e.message));
      });
  };
}

export function updateError(updateError) {
  return { type: 'COMPANY_UPDATE_UPDATE_ERROR', updateError };
}

export function updateLoading(updateLoading) {
  return { type: 'COMPANY_UPDATE_UPDATE_LOADING', updateLoading };
}

export function updateSuccess(updated) {
  return { type: 'COMPANY_UPDATE_UPDATE_SUCCESS', updated };
}

export function update(item, values) {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(createSuccess(null));
    dispatch(updateLoading(true));

    const body = new FormData();
    Object.keys(values).map(function(key) {
      if (key === 'logo' && values[key] instanceof FileList) {
        body.append(key, values[key][0]);
      } else if (values[key] !== '' && values[key] !== null) {
        body.append(key, values[key]);
      }
    });

    return fetch(item['@id'], { method: 'POST', body })
      .then(response =>
        response
          .json()
          .then(retrieved => ({ retrieved, hubURL: extractHubURL(response) }))
      )
      .then(({ retrieved, hubURL }) => {
        retrieved = normalize(retrieved);

        dispatch(updateLoading(false));
        toast.success('Company successfully updated');
        dispatch(updateSuccess(retrieved));

        if (hubURL) dispatch(mercureSubscribe(hubURL, retrieved['@id']));
      })
      .catch(e => {
        dispatch(updateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(updateError(e.errors._error));
          throw e;
        }

        toast.error('Company did not update');
        dispatch(updateError(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'COMPANY_UPDATE_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(createSuccess(null));
  };
}

export function mercureSubscribe(hubURL, topic) {
  return dispatch => {
    const eventSource = subscribe(hubURL, [topic]);
    dispatch(mercureOpen(eventSource));
    eventSource.addEventListener('message', event =>
      dispatch(mercureMessage(normalize(JSON.parse(event.data))))
    );
  };
}

export function mercureOpen(eventSource) {
  return { type: 'COMPANY_UPDATE_MERCURE_OPEN', eventSource };
}

export function mercureMessage(retrieved) {
  return dispatch => {
    if (Object.keys(retrieved).length === 1) {
      dispatch({ type: 'COMPANY_UPDATE_MERCURE_DELETED', retrieved });
      return;
    }

    dispatch({ type: 'COMPANY_UPDATE_MERCURE_MESSAGE', retrieved });
  };
}
