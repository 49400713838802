import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/initialize';

const DateInput = ({
  id,
  input,
  initialVisibleMonth,
  placeholder,
  displayPopper,
  autoComplete,
  isOutsideRange,
  meta: { initial }
}) => {
  const [date, setDate] = useState(initial ? moment(initial) : null);
  const [focus, setFocus] = useState();

  useEffect(() => {
    if (displayPopper) {
      const date = moment(input.value);
      setDate(input.value ? date : null);
    } else {
      setDate(input.value ? date : '');
    }
  }, [input.value]);

  useEffect(() => {
    document.getElementById(id).setAttribute('autoComplete', autoComplete);
  }, []);

  if (!displayPopper) {
    return (
      <Cleave
        className="input"
        id={id}
        placeholder={placeholder}
        {...input}
        value={date ? date.format('DD.MM.YYYY') : input.value}
        options={{
          date: true,
          delimiter: '.',
          datePattern: ['d', 'm', 'Y']
        }}
      />
    );
  }

  return (
    <SingleDatePicker
      date={date}
      initialVisibleMonth={initialVisibleMonth}
      onDateChange={date => {
        setDate(date);
        input.onChange(date);
      }}
      onFocusChange={({ focused }) => setFocus(focused)}
      focused={focus}
      numberOfMonths={1}
      id={id}
      noBorder
      displayFormat="DD.MM.YYYY"
      placeholder={placeholder}
      isOutsideRange={isOutsideRange}
    />
  );
};

DateInput.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.instanceOf(Date)
    ])
  }).isRequired,
  meta: PropTypes.shape({
    initial: PropTypes.string
  }).isRequired,
  placeholder: PropTypes.string,
  displayPopper: PropTypes.bool,
  autoComplete: PropTypes.string,
  isOutsideRange: PropTypes.func,
  initialVisibleMonth: PropTypes.func
};

DateInput.defaultProps = {
  placeholder: 'dd.MM.YYYY',
  displayPopper: true,
  autoComplete: 'off',
  isOutsideRange: () => false,
  initialVisibleMonth: () => moment()
};

export default DateInput;
