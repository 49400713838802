import React from 'react';
import { Input } from '..';
import PropTypes from 'prop-types';

const BlockField = props => {
  const {
    prefix,
    labelClass,
    inputClass,
    label,
    type,
    flex,
    input,
    required,
    placeholder,
    meta
  } = props;

  return (
    <div className="form-group row">
      {label && (
        <div
          className={`${labelClass} ${
            type === 'checkbox' || !flex ? '' : 'd-flex'
          }`}
        >
          <label
            className={`form-label ${
              required ? 'form-label--required' : ''
            } align-self-center`}
            htmlFor={`${prefix}${input.name}`}
          >
            {label}
          </label>
        </div>
      )}
      <Input
        {...props}
        type={type}
        className={inputClass}
        required={required}
        placeholder={placeholder}
        id={`${prefix}${input.name}`}
        error={meta.touched && meta.error ? meta.error : null}
      />
    </div>
  );
};

BlockField.propTypes = {
  prefix: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  meta: PropTypes.object.isRequired,
  flex: PropTypes.bool
};

BlockField.defaultProps = {
  prefix: '',
  labelClass: 'col-sm-3',
  inputClass: 'col-sm-9',
  placeholder: undefined,
  required: false,
  flex: true
};

export default BlockField;
