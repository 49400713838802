/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Detail as RequestDetail } from '.';
import { Modal, Loader } from '../Util';
import {
  retrieve,
  error as requestErrorAction
} from '../../actions/Request/show';
import { List as OfferList, Form as OfferForm } from '../Offer';
import { reset as listOffersReset } from '../../actions/Offer/list';

const Overview = ({
  roles,
  retrieve,
  request,
  requestError,
  requestErrorAction,
  requestLoading,
  modalState,
  setModalState,
  requestId,
  setRequestId,
  listOffersReset,
  setCreateRequestModalState,
  setDuplicatedRequest,
  updatedOffer
}) => {
  const [view, setView] = useState('list');
  const [selectedOffer, setSelectedOffer] = useState();

  useEffect(() => {
    retrieve(requestId);

    return () => {
      // Cleanup function
      setRequestId();
      listOffersReset();
    };
  }, []);

  useEffect(() => {
    if (updatedOffer) {
      retrieve(request.id);
    }
  }, [updatedOffer]);

  useEffect(() => {
    if (requestError) {
      setRequestId();
      setModalState(false);
      toast.error('Error while trying to fetch apartment request.');
      requestErrorAction(null);
    }
  }, [requestError]);

  return !request || requestLoading ? (
    <Loader />
  ) : (
    <Modal isOpen={modalState}>
      <RequestDetail
        request={request}
        modalState={modalState}
        setModalState={setModalState}
        roles={roles}
        setDuplicatedRequest={setDuplicatedRequest}
        setCreateRequestModalState={setCreateRequestModalState}
      />
      <div className="modal__content pt-0">
        {view === 'list' ? (
          <OfferList
            request={request}
            roles={roles}
            setView={setView}
            selectedOffer={selectedOffer}
          />
        ) : (
          <OfferForm
            request={request}
            setView={setView}
            setSelectedOffer={setSelectedOffer}
          />
        )}
      </div>
    </Modal>
  );
};

export default connect(
  state => {
    const {
      retrieved: request,
      error: requestError,
      loading: requestLoading
    } = state.request.show;

    const { updated: updatedOffer } = state.offer.update;

    return {
      request,
      requestError,
      requestLoading,
      updatedOffer
    };
  },
  { retrieve, listOffersReset, requestErrorAction }
)(Overview);
