import React from 'react';
import PropTypes from 'prop-types';

const ListColumnTitle = ({
  name,
  label,
  labelClass,
  orderActive,
  orderDirection,
  onClick
}) => {
  let className;

  if (orderActive) {
    className =
      orderDirection === 'desc'
        ? 'fas fa-sort-amount-up'
        : 'fas fa-sort-amount-down';
  }

  return (
    <th className={labelClass} nowrap="nowrap">
      <button
        type="button"
        className="btn btn--form-action"
        name={name}
        onClick={onClick}
      >
        {label} &nbsp;
        <i className={className} />
      </button>
    </th>
  );
};

ListColumnTitle.defaultProps = {
  name: '',
  labelClass: 'table-head',
  orderActive: false,
  orderDirection: 'asc',
  onClick: null
};

ListColumnTitle.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  labelClass: PropTypes.string,
  orderActive: PropTypes.bool,
  orderDirection: PropTypes.string,
  onClick: PropTypes.func
};

export default ListColumnTitle;
