import axios from 'axios';

export const consultantError = consultantError => {
  return { type: 'USER_CONSULTANT_ERROR', consultantError };
};

export const consultantLoading = consultantLoading => {
  return { type: 'USER_CONSULTANT_LOADING', consultantLoading };
};

export const consultantRetrieved = consultantRetrieved => {
  return { type: 'USER_CONSULTANT_SUCCESS', consultantRetrieved };
};

export const getConsultant = () => async dispatch => {
  dispatch(consultantLoading(true));
  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/users/consultant`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(consultantRetrieved(result.data));
  } catch (e) {
    dispatch(consultantError(e.message));
  }

  dispatch(consultantLoading(false));
};
