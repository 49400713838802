import axios from 'axios';

export const personalConsultantError = personalConsultantError => {
  return { type: 'COMPANY_PERSONAL_CONSULTANT_ERROR', personalConsultantError };
};

export const personalConsultantLoading = personalConsultantLoading => {
  return {
    type: 'COMPANY_PERSONAL_CONSULTANT_LOADING',
    personalConsultantLoading
  };
};

export const personalConsultantRetrieved = personalConsultantRetrieved => {
  return {
    type: 'COMPANY_PERSONAL_CONSULTANT_SUCCESS',
    personalConsultantRetrieved
  };
};

export const listPersonalConsultants = () => async dispatch => {
  dispatch(personalConsultantLoading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/users?company.type.name=host`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(personalConsultantRetrieved(result.data));
  } catch (e) {
    dispatch(personalConsultantError(e.message));
  }

  dispatch(personalConsultantLoading(false));
};

export const companyTypesError = companyTypesError => {
  return { type: 'COMPANY_TYPES_ERROR', companyTypesError };
};

export const companyTypesLoading = companyTypesLoading => {
  return {
    type: 'COMPANY_TYPES_LOADING',
    companyTypesLoading
  };
};

export const companyTypesRetrieved = companyTypesRetrieved => {
  return {
    type: 'COMPANY_TYPES_SUCCESS',
    companyTypesRetrieved
  };
};

export const listCompanyTypes = () => async dispatch => {
  dispatch(companyTypesLoading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/company_types`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(companyTypesRetrieved(result.data));
  } catch (e) {
    dispatch(companyTypesError(e.message));
  }

  dispatch(companyTypesLoading(false));
};
