import { combineReducers } from 'redux';

export function personalConsultantError(state = null, action) {
  switch (action.type) {
    case 'COMPANY_PERSONAL_CONSULTANT_ERROR':
      return action.personalConsultantError;

    default:
      return state;
  }
}

export function personalConsultantLoading(state = false, action) {
  switch (action.type) {
    case 'COMPANY_PERSONAL_CONSULTANT_LOADING':
      return action.personalConsultantLoading;

    default:
      return state;
  }
}

export function personalConsultantRetrieved(state = [], action) {
  switch (action.type) {
    case 'COMPANY_PERSONAL_CONSULTANT_SUCCESS':
      return action.personalConsultantRetrieved;

    default:
      return state;
  }
}

export function companyTypesError(state = null, action) {
  switch (action.type) {
    case 'COMPANY_TYPES_ERROR':
      return action.companyTypesError;

    default:
      return state;
  }
}

export function companyTypesLoading(state = false, action) {
  switch (action.type) {
    case 'COMPANY_TYPES_LOADING':
      return action.companyTypesLoading;

    default:
      return state;
  }
}

export function companyTypesRetrieved(state = [], action) {
  switch (action.type) {
    case 'COMPANY_TYPES_SUCCESS':
      return action.companyTypesRetrieved;

    default:
      return state;
  }
}

export default combineReducers({
  personalConsultantError,
  personalConsultantLoading,
  personalConsultantRetrieved,
  companyTypesError,
  companyTypesLoading,
  companyTypesRetrieved
});
