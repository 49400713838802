/* eslint react/prop-types: 0 */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { list, reset } from '../../actions/Notification/list';
import { retrieve, update } from '../../actions/Notification/update';
import Loader from '../Util/Loader';

const NotificationPopup = ({
  notifications,
  notificationCount,
  loading,
  update,
  setModalStatus,
  history
}) => {
  const onNotificationOpenHandler = item => {
    const time = new Date();
    if (item.openTime == null) {
      update(item, { openTime: time.toISOString() });
    }
    setModalStatus(false);
    history.push(item.notificationLink);
  };

  return (
    <div className="notification-modal">
      {notificationCount > 0 && (
        <div className="notification-modal__head">
          <small>New Notifications</small>
        </div>
      )}
      <div className="notification-modal__list">
        {loading ? (
          <Loader type="wrap" />
        ) : (
          <>
            <table className="table table-shadow table-hover">
              <tbody>
                {notifications &&
                  notifications['hydra:member'].reduce(
                    (accumulator, current) => {
                      accumulator.push(
                        <tr
                          key={current.id}
                          className={!current.openTime ? ' bg-light-pink' : ''}
                          onClick={() => onNotificationOpenHandler(current)}
                        >
                          <td>
                            <span className="text--link-pink">
                              {current.content}
                            </span>
                            <br />
                            <span className="table-cell-time">
                              <TimeAgo date={current.sentTimeFormatted} />
                            </span>
                          </td>
                        </tr>
                      );
                      return accumulator;
                    },
                    []
                  )}
              </tbody>
            </table>
          </>
        )}
      </div>
      <div className="notification-modal__footer">
        <button
          type="button"
          className="btn btn--primary"
          onClick={() => {
            history.push('/notifications');
          }}
        >
          Load more
        </button>
      </div>
    </div>
  );
};

export default withRouter(
  connect(
    null,
    {
      list,
      retrieve,
      update,
      reset
    }
  )(NotificationPopup)
);
