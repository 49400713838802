import axios from 'axios';

export const offerChargeTypesError = offerChargeTypesError => {
  return { type: 'OFFER_CHARGE_TYPES_ERROR', offerChargeTypesError };
};

export const offerChargeTypesLoading = offerChargeTypesLoading => {
  return { type: 'OFFER_CHARGE_TYPES_LOADING', offerChargeTypesLoading };
};

export const offerChargeTypesRetrieved = offerChargeTypesRetrieved => {
  return { type: 'OFFER_CHARGE_TYPES_SUCCESS', offerChargeTypesRetrieved };
};

export const listOfferChargeTypes = () => async dispatch => {
  dispatch(offerChargeTypesLoading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/offer_charge_types`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(offerChargeTypesRetrieved(result.data));
  } catch (e) {
    dispatch(offerChargeTypesError(e.message));
  }

  dispatch(offerChargeTypesLoading(false));
};

export const offerChargeFrequenciesError = offerChargeFrequenciesError => {
  return {
    type: 'OFFER_CHARGE_FREQUENCIES_ERROR',
    offerChargeFrequenciesError
  };
};

export const offerChargeFrequenciesLoading = offerChargeFrequenciesLoading => {
  return {
    type: 'OFFER_CHARGE_FREQUENCIES_LOADING',
    offerChargeFrequenciesLoading
  };
};

export const offerChargeFrequenciesRetrieved = offerChargeFrequenciesRetrieved => {
  return {
    type: 'OFFER_CHARGE_FREQUENCIES_SUCCESS',
    offerChargeFrequenciesRetrieved
  };
};

export const listOfferChargeFrequencies = () => async dispatch => {
  dispatch(offerChargeFrequenciesLoading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/offer_charge_frequencies`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(offerChargeFrequenciesRetrieved(result.data));
  } catch (e) {
    dispatch(offerChargeFrequenciesError(e.message));
  }

  dispatch(offerChargeFrequenciesLoading(false));
};

export const offerFeedbackTypesError = offerFeedbackTypesError => {
  return {
    type: 'OFFER_FEEDBACK_TYPES_ERROR',
    offerFeedbackTypesError
  };
};

export const offerFeedbackTypesLoading = offerFeedbackTypesLoading => {
  return {
    type: 'OFFER_FEEDBACK_TYPES_LOADING',
    offerFeedbackTypesLoading
  };
};

export const offerFeedbackTypesRetrieved = offerFeedbackTypesRetrieved => {
  return {
    type: 'OFFER_FEEDBACK_TYPES_SUCCESS',
    offerFeedbackTypesRetrieved
  };
};

export const listOfferFeedbackTypes = () => async dispatch => {
  dispatch(offerFeedbackTypesLoading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/offer_feedback_types`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(offerFeedbackTypesRetrieved(result.data));
  } catch (e) {
    dispatch(offerFeedbackTypesError(e.message));
  }

  dispatch(offerFeedbackTypesLoading(false));
};
