import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { withAuth, Page } from '.';

const PublicRoute = ({ token, component, title, ...rest }) => {
  if (!token) {
    return (
      <Route
        {...rest}
        render={props => (
          <Page {...props} component={component} title={title} />
        )}
      />
    );
  }

  return <Redirect to="/" />;
};

PublicRoute.propTypes = {
  token: PropTypes.string,
  component: PropTypes.func.isRequired,
  title: PropTypes.string
};

PublicRoute.defaultProps = {
  token: undefined,
  title: ''
};

export default withAuth(PublicRoute);
