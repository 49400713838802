import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';

ReactModal.setAppElement('#root');

const getHeight = () =>
  Math.max(document.documentElement.clientHeight, window.innerHeight || 0);

const Modal = ({ isOpen, children, onClose, className }) => {
  const [height, setHeight] = useState(getHeight());

  useEffect(() => {
    const resizeHandler = () => setHeight(getHeight());
    window.addEventListener('resize', resizeHandler);

    const keydownHandler = evt => {
      const event = evt || window.event;
      if (event.keycode === 27) {
        onclose();
      }
    };

    if (onClose) {
      document.addEventListener('keydown', keydownHandler, false);
    }

    return () => {
      window.removeEventListener('resize', resizeHandler);
      document.removeEventListener('keydown', keydownHandler, false);
    };
  }, []);

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={`modal${className ? ` ${className}` : ''}`}
      className="modal__inner"
      style={{
        overlay: {
          height
        }
      }}
    >
      {children}
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  className: PropTypes.string
};

Modal.defaultProps = {
  isOpen: false,
  onClose: null,
  className: ''
};

export default Modal;
