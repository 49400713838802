import axios from 'axios';

export function error(error) {
  return { type: 'OFFER_PDF_ERROR', error };
}

export function loading(loading) {
  return { type: 'OFFER_PDF_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'OFFER_PDF_SUCCESS', retrieved };
}

export const pdf = (id, price) => async dispatch => {
  dispatch(loading(true));

  try {
    const result = await axios.get(
      `${
        process.env.REACT_APP_API_ENTRYPOINT
      }/offers/${id}/downloadpdf?price=${price}`,
      {
        responseType: 'blob',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );

    const file = new Blob([result.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);

    dispatch(success(fileURL));
  } catch (e) {
    dispatch(error(e.message));
  }

  dispatch(loading(false));
};
