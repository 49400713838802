import { combineReducers } from 'redux';

export const error = (state = null, action) => {
  switch (action.type) {
    case 'DASHBOARD_LIST_ERROR':
      return action.error;
    default:
      return state;
  }
};

export const loading = (state = false, action) => {
  switch (action.type) {
    case 'DASHBOARD_LIST_LOADING':
      return action.loading;
    default:
      return state;
  }
};

export const retrieved = (state = null, action) => {
  switch (action.type) {
    case 'DASHBOARD_LIST_SUCCESS':
      return action.retrieved;
    default:
      return state;
  }
};

export default combineReducers({ loading, retrieved, error });
