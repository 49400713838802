import React from 'react';
import PropTypes from 'prop-types';

const RadioButton = ({ id, value, label, input }) => {
  return (
    <label htmlFor={id} className="radio-button">
      <input
        type="radio"
        className="radio-button__input"
        {...input}
        checked={value === input.value}
        id={id}
        value={value}
      />
      <span className="radio-button__circle" />
      {label}
    </label>
  );
};

RadioButton.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string
  }).isRequired
};

export default RadioButton;
