import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import moment from 'moment';
import { fetch, normalize } from '../../utils/dataAccess';
import { success as createSuccess } from './create';
import { loading, error } from './delete';

export function retrieveError(retrieveError) {
  return { type: 'USER_UPDATE_RETRIEVE_ERROR', retrieveError };
}

export function retrieveLoading(retrieveLoading) {
  return { type: 'USER_UPDATE_RETRIEVE_LOADING', retrieveLoading };
}

export function retrieveSuccess(retrieved) {
  return { type: 'USER_UPDATE_RETRIEVE_SUCCESS', retrieved };
}

export function retrieve(id) {
  return dispatch => {
    dispatch(retrieveLoading(true));

    return fetch(id)
      .then(response => response.json())
      .then(retrieved => {
        retrieved = normalize(retrieved);
        // change the company iri to id because we are sending the user form as form data instead of json
        retrieved.company = retrieved.company.split('/')[2];

        dispatch(retrieveLoading(false));
        dispatch(retrieveSuccess(retrieved));
      })
      .catch(e => {
        dispatch(retrieveLoading(false));
        dispatch(retrieveError(e.message));
      });
  };
}

export function updateError(updateError) {
  return { type: 'USER_UPDATE_UPDATE_ERROR', updateError };
}

export function updateLoading(updateLoading) {
  return { type: 'USER_UPDATE_UPDATE_LOADING', updateLoading };
}

export function updateSuccess(updated) {
  return { type: 'USER_UPDATE_UPDATE_SUCCESS', updated };
}

export function update(item, values) {
  return dispatch => {
    dispatch(updateError(null));
    dispatch(createSuccess(null));
    dispatch(updateLoading(true));
    const body = new FormData();

    Object.keys(values).map(key => {
      if (key === 'profilePicture' && values[key] instanceof FileList) {
        body.append(key, values[key][0]);
      } else if (key === 'company') {
        body.append(key, values[key].split('/')[2]);
      } else if (values[key] !== '' && values[key] !== null) {
        body.append(key, values[key]);
      }
    });

    return fetch(item['@id'], { method: 'POST', body })
      .then(response => response.json())
      .then(retrieved => {
        dispatch(updateLoading(false));
        toast.success('User successfully updated!');
        dispatch(updateSuccess(retrieved));
      })
      .catch(e => {
        dispatch(updateLoading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw new SubmissionError({
            ...e.errors,
            _error: 'Submission failed. Please fix the fields marked in red.'
          });
        } else {
          dispatch(error(e.message));
          throw new SubmissionError({
            _error: e.message
          });
        }
      });
  };
}

export function acceptToS(id) {
  const page = `${process.env.REACT_APP_API_ENTRYPOINT}/users/${id}/accept_tos`;

  return dispatch => {
    dispatch(updateError(null));
    dispatch(createSuccess(null));
    dispatch(updateLoading(true));

    return fetch(page, { method: 'PUT' })
      .then(response => response.json())
      .then(retrieved => {
        retrieved = normalize(retrieved);

        dispatch(updateLoading(false));
        dispatch(updateSuccess(retrieved));
      })
      .catch(e => {
        dispatch(updateLoading(false));
        dispatch(updateError(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch({ type: 'USER_UPDATE_RESET' });
    dispatch(error(null));
    dispatch(loading(false));
    dispatch(createSuccess(null));
  };
}
