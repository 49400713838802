import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'USER_INVITE_ERROR', error };
}

export function loading(loading) {
  return { type: 'USER_INVITE_LOADING', loading };
}

export function success(invited) {
  return { type: 'USER_INVITE_SUCCESS', invited };
}

export function invite(item) {
  return dispatch => {
    dispatch(loading(true));

    return fetch(`${item['@id']}/invite`, { method: 'POST' })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => {
        toast.success(`The user is invited to set his password.`);
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }
        toast.error(e.message);
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(success(null));
    dispatch(error(null));
  };
}
