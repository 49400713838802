/* eslint react/prop-types: "off" */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import Form from './Form';
import { update, reset } from '../../actions/Company/update';
import { iconClose, Loader, Modal } from '../Util';

const Update = ({
  company,
  update,
  modalState,
  setModalState,
  updateLoading,
  reset
}) => {
  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <Modal isOpen={modalState} onClose={() => setModalState(false)}>
      {updateLoading && <Loader />}
      <div className="modal__content pb-0">
        <Row className="mb-5">
          <Col md={12}>
            <h1 className="heading heading--medium">
              Edit Company: {company && company['@id']}
            </h1>
            <button
              type="button"
              className="btn btn--close-modal"
              onClick={() => setModalState(!modalState)}
            >
              <img src={iconClose} alt="close modal" />
            </button>

            {company && (
              <Form
                onSubmit={values => update(company, values)}
                initialValues={{
                  ...company,
                  consultant: company.consultant && company.consultant.id,
                  type: company.type && company.type.split('/')[2],
                  regions:
                    company.regions &&
                    company.regions.reduce((acc, current) => {
                      acc.push(`${current.id}`);
                      return acc;
                    }, [])
                }}
              />
            )}
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  updateError: state.company.update.updateError,
  updateLoading: state.company.update.updateLoading
});

const mapDispatchToProps = {
  update,
  reset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Update);
