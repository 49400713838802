import { combineReducers } from 'redux';

export const allowNotifications = (state = true, action) => {
  switch (action.type) {
    case 'ALLOW_NOTIFICATIONS':
      return action.allowNotifications;
    default:
      return state;
  }
};

export default combineReducers({ allowNotifications });
