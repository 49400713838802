import React from 'react';
import { Redirect } from 'react-router-dom';
import { Dashboard } from '../components/Dashboard';
import { PrivateRoute } from '../components/Authentication';

export default [
  <PrivateRoute
    key="/dashboard"
    path="/dashboard"
    title="Dashboard"
    component={Dashboard}
    strict
    exact
  />,
  <PrivateRoute
    key="/"
    path="/"
    component={() => <Redirect to="/dashboard" />}
    strict
    exact
  />
];
