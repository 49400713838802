/* eslint react/prop-types: "off" */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { Modal, Loader } from '../Util';
import { acceptToS } from '../../actions/User/update';
import { refreshToken } from '../../actions/Authentication/token';

const TermsOfService = ({
  id,
  tosAccepted,
  acceptToS,
  updated,
  refreshToken,
  history,
  updateLoading,
  updateError
}) => {
  useEffect(() => {
    if (updated) {
      refreshToken();
      setTimeout(() => {
        history.push('/');
      }, 1500);
    }
  }, [updated]);

  useEffect(() => {
    if (updateError) {
      toast.error(updateError);
    }
  }, [updateError]);

  return (
    <Modal isOpen className="modal--tos">
      {updateLoading && <Loader />}
      <div className="modal__content">
        <div className="tos__container">
          <form>
            <h2 className="tos__title">Terms of Service</h2>
            <object
              data="nest_tos.pdf"
              type="application/pdf"
              className="tos__pdf"
            >
              <embed src="nest_tos.pdf" type="application/pdf" />
            </object>
            {!tosAccepted && (
              <button
                type="button"
                className="btn btn--primary tos__button"
                onClick={() => {
                  acceptToS(id);
                }}
              >
                Accept
              </button>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default connect(
  state => {
    const { id, tosAccepted } = state.authentication.token.retrieved;

    const { updated, updateLoading, updateError } = state.user.update;

    return {
      id,
      tosAccepted,
      updated,
      updateLoading,
      updateError
    };
  },
  { acceptToS, refreshToken }
)(withRouter(TermsOfService));
