import React from 'react';
import PropTypes from 'prop-types';

const TextArea = ({ id, disabled, placeholder, maxlength, input }) => (
  <textarea
    className="textarea"
    id={id}
    {...input}
    disabled={disabled}
    placeholder={placeholder}
    maxLength={maxlength}
  />
);

TextArea.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  maxlength: PropTypes.number
};

TextArea.defaultProps = {
  placeholder: 'Message...',
  disabled: false,
  maxlength: 160
};

export default TextArea;
