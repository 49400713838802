import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import queryString from 'querystring';
import Pagination from 'react-js-pagination';
import { list, reset } from '../../actions/Notification/list';
import { formatDate } from '../../utils/helpers';
import Layout from '../Layout';
import { Loader } from '../Util';

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: queryString.parse(this.props.location.search).page,
      itemsPerPage: 30
    };
  }

  static propTypes = {
    retrieved: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.string,
    deletedItem: PropTypes.object,
    list: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired
  };

  urlToFetch() {
    const uri = this.props.location.pathname;
    const qs = [
      this.state.search ? `search_multiple_combined=${this.state.search}` : '',
      this.state.order && this.state.orderDirection
        ? `order[${this.state.order}]=${this.state.orderDirection}`
        : '',
      this.state.filter ? `type.name=${this.state.filter}` : '',
      this.state.page
        ? `page=${this.state.page}&itemsPerPage=${this.state.itemsPerPage}`
        : ''
    ]
      .filter(item => {
        return item !== '';
      })
      .join('&');

    return uri + (qs !== '' ? `?${qs}` : '');
  }

  urlToShow(state) {
    const uri = this.props.location.pathname;
    const qs = [
      state.search ? `search=${state.search}` : '',
      state.order ? `order=${state.order}` : '',
      state.orderDirection ? `orderdirection=${state.orderDirection}` : '',
      state.filter ? `filter=${state.filter}` : '',
      state.page ? `page=${state.page}` : ''
    ]
      .filter(item => {
        return item != '';
      })
      .join('&');

    return uri + (qs !== '' ? `?${qs}` : '');
  }

  componentDidMount() {
    this.props.list(
      this.props.match.params.page &&
        decodeURIComponent(this.props.match.params.page)
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page)
      nextProps.list(
        nextProps.match.params.page &&
          decodeURIComponent(nextProps.match.params.page)
      );
  }

  componentWillUnmount() {
    this.props.reset(this.props.eventSource);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.urlToShow(prevState) !== this.urlToShow(this.state)) {
      this.props.history.push(this.urlToShow(this.state));
      this.props.list(this.urlToFetch());
    }
  }

  handlePagination(pageNumber) {
    this.setState({ page: pageNumber });
  }

  render() {
    return (
      <Layout>
        {this.props.loading && <Loader />}
        <Row>
          <Col md={12} className="mt-5 mb-4">
            <h1>Notification List</h1>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <table className="table table-shadow">
              <thead>
                <tr>
                  <th className="table-head">Subject</th>
                  <th className="table-head">Sent Time</th>
                  <th className="table-head">Seen Time</th>
                  <th className="table-head">Open Time</th>
                </tr>
              </thead>
              <tbody>
                {this.props.retrieved &&
                  this.props.retrieved['hydra:member'].map(item => (
                    <tr key={item['@id']}>
                      <td>
                        <Link
                          to={`${item.notificationLink}`}
                          className="text--link-pink"
                        >
                          {item.subject}
                        </Link>
                      </td>
                      <td>{formatDate(item.sentTime)}</td>
                      <td>{formatDate(item.seenTime)}</td>
                      <td>{formatDate(item.openTime)}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Col>
        </Row>

        {this.pagination()}
      </Layout>
    );
  }

  pagination() {
    const view = this.props.retrieved && this.props.retrieved['hydra:view'];
    if (!view) return;

    const totalItems =
      this.props.retrieved && this.props.retrieved['hydra:totalItems'];

    const {
      'hydra:first': first,
      'hydra:previous': previous,
      'hydra:next': next,
      'hydra:last': last
    } = view;

    return (
      <div className="pt-4 pb-4">
        <Pagination
          innerClass="pagination justify-content-center"
          hideDisabled
          activePage={this.state.page}
          itemsCountPerPage={this.state.itemsPerPage}
          totalItemsCount={totalItems}
          onChange={e => this.handlePagination(e)}
          itemClass="page-item"
          linkClass="page-link"
        />
      </div>
    );
  }

  renderLinks = (type, items) => {
    if (Array.isArray(items)) {
      return items.map((item, i) => (
        <div key={i}>{this.renderLinks(type, item)}</div>
      ));
    }

    return (
      <Link
        to={`../${type}/show/${encodeURIComponent(items)}`}
        className="text--link-pink"
      >
        {items}
      </Link>
    );
  };
}

const mapStateToProps = state => {
  const {
    retrieved,
    loading,
    error,
    eventSource,
    deletedItem
  } = state.notification.list;
  return { retrieved, loading, error, eventSource, deletedItem };
};

const mapDispatchToProps = dispatch => ({
  list: page => dispatch(list(page)),
  reset: eventSource => dispatch(reset(eventSource))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(List);
