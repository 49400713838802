import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const MultiSelectInput = ({ input, options, placeholder }) => {
  return (
    <Select
      {...input}
      className="react-select-container"
      classNamePrefix="react-select"
      onChange={value => input.onChange(value)}
      onBlur={() => input.onBlur(input.value)}
      options={options}
      isMulti
      placeholder={placeholder}
      theme={theme => ({
        ...theme,
        borderRadius: 3,
        border: 1,
        colors: {
          ...theme.colors,
          neutral20: '#dddddd',
          neutral50: '#919293',
          primary: '#d7577c',
          primary25: '#ffcfdd',
          primary50: '#ffcfdd',
          danger: '#cf0014',
          dangerLight: '#ffc9c9'
        }
      })}
    />
  );
};

MultiSelectInput.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired
};

MultiSelectInput.defaultProps = {
  placeholder: 'Please choose...'
};

export default MultiSelectInput;
