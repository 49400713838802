/* eslint react/prop-types: "off" */
import React, { useEffect } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import {
  listPersonalConsultants,
  listCompanyTypes
} from '../../actions/Company/dynamicData';
import { listRegions } from '../../actions/Request/other';
import { Loader } from '../Util';
import { InlineFieldGroup, InlineField, BlockField } from '../Common/Form';
import { ucwords } from '../../utils/helpers';

let Form = ({
  handleSubmit,
  listPersonalConsultants,
  personalConsultantLoading,
  personalConsultantRetrieved,
  listRegions,
  regionsRetrieved,
  regionsLoading,
  listCompanyTypes,
  companyTypesRetrieved,
  companyTypesLoading,
  initialValues,
  selectedType
}) => {
  useEffect(() => {
    listRegions();
    listPersonalConsultants();
    listCompanyTypes();
  }, {});

  const currentCompanyType =
    companyTypesRetrieved && selectedType
      ? companyTypesRetrieved.find(e => e.id == selectedType) // eslint-disable-line
      : null;

  return (
    <form onSubmit={handleSubmit} encType="multipart/form-data">
      {(regionsLoading || personalConsultantLoading || companyTypesLoading) && (
        <Loader />
      )}
      <Row>
        <Col md={6}>
          <Field
            component={BlockField}
            name="name"
            type="text"
            label="Name"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            placeholder="NEST Agency"
            required
          />
          <Field
            component={BlockField}
            name="address"
            type="text"
            label="Address"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            placeholder="123 Main Street"
            required
          />
          <InlineFieldGroup
            label="Zip / City"
            labelClass="col-sm-4"
            childrenClass="col-sm-8"
            required
          >
            <Field
              component={InlineField}
              name="zip"
              type="text"
              inputClass="col-4"
              label="Address*"
              placeholder="8004"
              required
            />
            <Field
              component={InlineField}
              name="city"
              type="text"
              placeholder="Zürich"
              inputClass="col-8"
              label="Address*"
              required
            />
          </InlineFieldGroup>
          <Field
            component={BlockField}
            name="country"
            type="text"
            label="Country"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            placeholder="Switzerland"
            required
          />
          <Field
            component={BlockField}
            name="notificationsEmail"
            type="text"
            label="Email"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            placeholder="max.mustermann@email.ch"
          />
          <Field
            component={BlockField}
            name="type"
            type="select"
            label="Role"
            className="col-6"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            items={(companyTypesRetrieved || []).reduce((acc, curr) => {
              const name = curr.name === 'host' ? 'NEST' : ucwords(curr.name);
              acc.push({ value: curr.id, name });
              return acc;
            }, [])}
            required
          />
          {currentCompanyType && currentCompanyType.name === 'client' && (
            <Field
              component={BlockField}
              name="subClientEnabled"
              type="checkbox"
              label="Sub Client Module"
              labelClass="col-8 col-sm-4"
              inputClass="col-4 col-sm-8"
            />
          )}
          <Field
            component={BlockField}
            name="defaultAnonymous"
            type="checkbox"
            placeholder=""
            label="Anonymized"
            labelClass="col-8 col-sm-4"
            inputClass="col-4 col-sm-8"
          />
          <Field
            component={BlockField}
            name="defaultNestOnly"
            type="checkbox"
            placeholder=""
            label="NEST only"
            labelClass="col-8 col-sm-4"
            inputClass="col-4 col-sm-8"
          />
          <Field
            component={BlockField}
            name="active"
            type="checkbox"
            label="Is Active?"
            labelClass="col-8 col-sm-4"
            inputClass="col-4 col-sm-8"
          />
          <Field
            component={BlockField}
            name="consultant"
            label="Personal Consultant"
            type="select"
            placeholder="Please choose"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            items={personalConsultantRetrieved.map(e => ({
              value: e.id,
              label: `${e.firstName} ${e.lastName}`,
              name: `${e.firstName} ${e.lastName}`
            }))}
          />
        </Col>
        <Col md={6}>
          <Field
            component={BlockField}
            name="logo"
            type="fileInput"
            label="Logo"
            accept="image/*"
            uploadDir={process.env.REACT_APP_IMAGES_UPLOAD_DIR_COMPANY}
            hasPreview
            labelClass="col-sm-4"
            inputClass="col-sm-8"
          >
            Choose file
          </Field>
          {currentCompanyType && currentCompanyType.name === 'vendor' && (
            <Field
              component={BlockField}
              name="regions"
              label="City/Region"
              type="checkbox"
              labelClass="col-sm-4"
              inputClass="col-sm-8"
              items={regionsRetrieved.map(e => ({
                id: `regions_${e.id}`,
                name: `regions[]`,
                value: `${e.id}`,
                label: e.name,
                checked: !!(
                  initialValues && initialValues.regions.includes(`${e.id}`)
                )
              }))}
              required
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="float-right">
            <button type="submit" className="btn btn--primary">
              SAVE
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name is required.';
  }

  if (!values.address) {
    errors.address = 'Address is required.';
  }

  if (!values.zip) {
    errors.zip = 'ZIP is required.';
  }

  if (!values.city) {
    errors.city = 'City is required.';
  }

  if (!values.country) {
    errors.country = 'City is required.';
  }

  if (!values.type) {
    errors.type = 'Role is required.';
  }

  if (values.type && values.type === '3' && !values.regions) {
    errors.regions = 'At least one of the above must be selected.';
  }

  if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
    return {
      ...errors,
      _error: 'Submission failed. Please fix the fields marked in red.'
    };
  }

  return errors;
};

Form = reduxForm({
  form: 'company',
  validate,
  onSubmitFail: (errors, dispatch, submitErrors, props) => {
    toast.error(errors._error || props.error);
  }
})(Form);

const selector = formValueSelector('company');

export default connect(
  state => {
    const selectedType = selector(state, 'type');

    const {
      personalConsultantLoading,
      personalConsultantRetrieved,
      companyTypesLoading,
      companyTypesRetrieved
    } = state.company.dynamicData;

    const { regionsLoading, regionsRetrieved } = state.request.other;

    return {
      errors: state.form.company ? state.form.company.syncErrors : {},
      personalConsultantLoading,
      personalConsultantRetrieved,
      regionsLoading,
      regionsRetrieved,
      companyTypesLoading,
      companyTypesRetrieved,
      selectedType
    };
  },
  { listPersonalConsultants, listCompanyTypes, listRegions }
)(Form);
