import axios from 'axios';

export const apartmentTypesError = apartmentTypesError => {
  return { type: 'REQUEST_APARTMENT_TYPES_ERROR', apartmentTypesError };
};

export const apartmentTypesLoading = apartmentTypesLoading => {
  return { type: 'REQUEST_APARTMENT_TYPES_LOADING', apartmentTypesLoading };
};

export const apartmentTypesRetrieved = apartmentTypesRetrieved => {
  return { type: 'REQUEST_APARTMENT_TYPES_SUCCESS', apartmentTypesRetrieved };
};

export const listApartmentTypes = () => async dispatch => {
  dispatch(apartmentTypesLoading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/apartment_types`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(apartmentTypesRetrieved(result.data));
  } catch (e) {
    dispatch(apartmentTypesError(e.message));
  }

  dispatch(apartmentTypesLoading(false));
};

export const regionsError = regionsError => {
  return { type: 'REQUEST_REGIONS_ERROR', regionsError };
};

export const regionsLoading = regionsLoading => {
  return { type: 'REQUEST_REGIONS_LOADING', regionsLoading };
};

export const regionsRetrieved = regionsRetrieved => {
  return { type: 'REQUEST_REGIONS_SUCCESS', regionsRetrieved };
};

export const listRegions = () => async dispatch => {
  dispatch(regionsLoading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/regions`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(regionsRetrieved(result.data));
  } catch (e) {
    dispatch(regionsError(e.message));
  }

  dispatch(regionsLoading(false));
};

export const userCompanyError = userCompanyError => {
  return { type: 'REQUEST_USER_COMPANY_ERROR', userCompanyError };
};

export const userCompanyLoading = userCompanyLoading => {
  return { type: 'REQUEST_USER_COMPANY_LOADING', userCompanyLoading };
};

export const userCompanyRetrieved = userCompanyRetrieved => {
  return { type: 'REQUEST_USER_COMPANY_SUCCESS', userCompanyRetrieved };
};

export const userCompany = () => async dispatch => {
  dispatch(userCompanyLoading(true));

  try {
    const result = await axios.get(
      `${process.env.REACT_APP_API_ENTRYPOINT}/users/company`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }
    );
    dispatch(userCompanyRetrieved(result.data));
  } catch (e) {
    dispatch(userCompanyError(e.message));
  }

  dispatch(userCompanyLoading(false));
};
