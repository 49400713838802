import { SubmissionError } from 'redux-form';
import moment from 'moment';
import { fetch } from '../../utils/dataAccess';
import { parseArrayErrors } from '../../utils/helpers';

export function error(error) {
  return { type: 'OFFER_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'OFFER_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'OFFER_CREATE_SUCCESS', created };
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));

    const newValues = values;

    if (newValues.location) {
      newValues.address = newValues.location.address || '';
      newValues.addressPos = newValues.location.pos
        ? `${newValues.location.pos.lat},${newValues.location.pos.lng}`
        : '';
    }

    const body = new FormData();

    Object.keys(values).forEach(key => {
      if (key === 'images' && values[key] instanceof FileList) {
        for (let i = 0; i < values[key].length; i++) {
          body.append(`${key}[]`, values[key][i]);
        }
      } else if (key === 'availableFrom' || key === 'availableTo') {
        if (moment.isMoment(values[key])) {
          body.append(key, values[key].format('YYYY-MM-DD HH:mm:ss'));
        } else {
          body.append(key, null);
        }
      } else if (key === 'comment') {
        if (values[key]) {
          body.append(key, values[key]);
        }
      } else if (key === 'charges') {
        for (let i = 0; i < values[key].length; i++) {
          Object.keys(values[key][i]).forEach(key1 => {
            body.append(`${key}[${i}][${key1}]`, values[key][i][key1]);
          });
        }
      } else {
        body.append(key, values[key]);
      }
    });

    return fetch('offers', { method: 'POST', body })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => dispatch(success(retrieved)))
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));

          throw new SubmissionError({
            ...parseArrayErrors(e.errors),
            _error: 'Submission failed. Please fix the fields marked in red.'
          });
        } else {
          dispatch(error(e.message));
          throw new SubmissionError({
            _error: e.message
          });
        }
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}
