import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const Page = ({ title, component, ...rest }) => {
  useEffect(() => {
    document.title = `NEST${title ? ` - ${title}` : ''}`;
  }, [title]);

  const Component = component;

  return <Component {...rest} />;
};

Page.propTypes = {
  title: PropTypes.string,
  component: PropTypes.func.isRequired
};

Page.defaultProps = {
  title: ''
};

export default Page;
