import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import logo from '../../assets/nest_logo.svg';
import { iconLogout } from '../Util';
import NotificationIcon from '../Notification/NotificationIcon';

const Header = ({ links, logout, firstName }) => {
  return (
    <Navbar bg="white" variant="light" expand="lg">
      <LinkContainer to="/">
        <Navbar.Brand>
          <img src={logo} alt="Nest Logo" className="navbar__logo" />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse style={{ margin: 0 }} id="nav-collapse">
        <Nav className="mr-auto">
          {links.map(e => (
            <li className="nav-item" key={e.title}>
              <LinkContainer to={e.link}>
                <Nav.Link>{e.title}</Nav.Link>
              </LinkContainer>
            </li>
          ))}
        </Nav>
        <Nav className="ml-auto">
          <div className="navbar">Hello {firstName}</div>
          <NotificationIcon />
          <Nav.Link onClick={logout} title="Logout">
            <img
              src={iconLogout}
              alt="Logout"
              className="navbar__logout-icon"
            />
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

Header.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.string
    })
  ),
  logout: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired
};

Header.defaultProps = {
  links: []
};

export default withRouter(Header);
