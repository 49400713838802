/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { Row, Col, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { toast } from 'react-toastify';
import { ListColumnTitle } from '../Common/List';
import {
  list as listNoAvailableOffers,
  reset as resetNoAvailableOffers
} from '../../actions/NoAvailableOffer/list';
import {
  ucwords,
  numberToCHF,
  hasRole,
  pendingStatusTypes
} from '../../utils/helpers';
import {
  CLIENT,
  SUB_CLIENT,
  VENDOR,
  HOST_ADMIN,
  HOST
} from '../../constants/roles';
import { Loader, iconPlus, iconX, Tooltip } from '../Util';
import {
  list as listOffersAction,
  error as listOffersErrorAction
} from '../../actions/Offer/list';
import {
  create as createNoAvailableOfferAction,
  reset as resetNoAvailableOfferAction
} from '../../actions/NoAvailableOffer/create';

const getQueryString = ({ id, listOrder }) => {
  const order = !listOrder
    ? 'order[availabilityDiffers]=asc&order[dateCreated]=asc'
    : `order[${listOrder.key}]=${listOrder.order}`;

  return `offers?apartmentRequest.id=${id}&pagination=false&${order}`;
};

const changeOrder = ({ listOrder, setListOrder, key }) => {
  if (!listOrder || listOrder.key !== key) {
    setListOrder({ key, order: 'desc' });
  } else if (listOrder.order === 'desc') {
    setListOrder({ key, order: 'asc' });
  } else if (listOrder.order === 'asc') {
    setListOrder(null);
  }
};

const Table = ({
  listOffersAction,
  listOffersErrorAction,
  offers,
  offersLoading,
  offersError,
  request,
  offer,
  setOffer,
  roles,
  selectedOffer,
  setView,
  createNoAvailableOfferAction,
  resetNoAvailableOfferAction,
  createdNoAvailableOffer,
  errorNoAvailableOffer,
  loadingNoAvailableOffer,
  setTabIndex,
  listNoAvailableOffers,
  noAvailableOffers,
  noAvailableOffersLoading,
  resetNoAvailableOffers
}) => {
  const [listOrder, setListOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [headerText, setHeaderText] = useState();
  const itemsPerPage = 5;

  useEffect(() => {
    if (!noAvailableOffers) {
      listNoAvailableOffers(
        `apartment_requests/${request.id}/no_available_offers`
      );
    }

    return () => {
      resetNoAvailableOffers();
    };
  }, []);

  useEffect(() => {
    if (offers && !offer) {
      const offer = selectedOffer || offers['hydra:member'][0];
      setOffer(offer);
    }
  }, [offers]);

  useEffect(() => {
    if (offersError) {
      toast.error('Error while trying to fetch offers.');
      listOffersErrorAction(null);
    }
  }, [offersError]);

  useEffect(() => {
    listOffersAction(
      getQueryString({
        id: request.id,
        listOrder
      })
    );
  }, [listOrder]);

  useEffect(() => {
    if (offers && !offers['hydra:member'].length) {
      setHeaderText('No offers yet!');
    } else if (hasRole(roles, [CLIENT, SUB_CLIENT])) {
      setHeaderText('Offers to your request.');
    } else if (hasRole(roles, [VENDOR, HOST, HOST_ADMIN])) {
      setHeaderText('Offers to the request.');
    }
  }, [offers]);

  useEffect(() => {
    if (errorNoAvailableOffer) {
      toast.error(errorNoAvailableOffer);
      resetNoAvailableOfferAction();
    }
  }, [errorNoAvailableOffer]);

  useEffect(() => {
    if (createdNoAvailableOffer) {
      toast.success('Success.');
      setTabIndex(1);
      resetNoAvailableOfferAction();
    }
  }, [createdNoAvailableOffer]);

  return (
    <>
      {(loadingNoAvailableOffer || noAvailableOffersLoading) && <Loader />}
      <Row>
        <Col sm={12}>
          <h1 className="heading heading--medium float-left">{headerText}</h1>
          <div className="d-inline-block float-right">
            {!hasRole(roles, [CLIENT, SUB_CLIENT]) &&
              pendingStatusTypes.indexOf(request.status.name) !== -1 && (
                <Tooltip title="Create Offer">
                  <button
                    type="button"
                    className="btn btn--form-action mr-2"
                    onClick={() => {
                      setView('form');
                    }}
                  >
                    <img src={iconPlus} alt="Create offer" />
                  </button>
                </Tooltip>
              )}
            {!hasRole(roles, [CLIENT, SUB_CLIENT]) &&
              request.status.name === 'pending' &&
              offers &&
              !offers['hydra:totalItems'] &&
              noAvailableOffers &&
              (noAvailableOffers['hydra:member'].length === 0 ||
                hasRole(roles, [HOST_ADMIN])) && (
                <Tooltip title=" No Offers Available">
                  <button
                    type="button"
                    className="btn btn--form-action d-inline-block"
                    onClick={() => {
                      createNoAvailableOfferAction({
                        apartmentRequest: `/apartment_requests/${request.id}`
                      });
                    }}
                  >
                    <img src={iconX} alt="Create no available offer" />
                  </button>
                </Tooltip>
              )}
          </div>
        </Col>
      </Row>
      {!!(offers && offers['hydra:member'].length) && (
        <div className="table-responsive position-relative">
          {(!offers || offersLoading) && <Loader type="wrap" />}
          <table className="table table-shadow table-hover table-offer-list mt-3">
            <thead>
              <tr>
                <th scope="col">Offer #</th>
                <th scope="col">Company</th>
                <ListColumnTitle
                  label={`Price (excl. ${
                    process.env.REACT_APP_VAT_HOSPITALITY_FEE
                  }% VAT + other charges)`}
                  name="price"
                  orderActive={listOrder && listOrder.key === 'price'}
                  orderDirection={listOrder && listOrder.order}
                  onClick={() => {
                    changeOrder({
                      listOrder,
                      setListOrder,
                      key: 'price'
                    });
                  }}
                />
                <ListColumnTitle
                  label="City"
                  name="city"
                  orderActive={listOrder && listOrder.key === 'city'}
                  orderDirection={listOrder && listOrder.order}
                  onClick={() => {
                    changeOrder({ listOrder, setListOrder, key: 'city' });
                  }}
                />
                <th scope="col">Unit</th>
                <th scope="col">m&sup2;</th>
                <th scope="col">Rate Type</th>
                <th scope="col">Link</th>
                <th scope="col">Pdf</th>
                {!hasRole(roles, [CLIENT, SUB_CLIENT]) && (
                  <th scope="col">Feedback</th>
                )}
                <th scope="col">Status</th>
                <th scope="col">Exact Date Match</th>
              </tr>
            </thead>
            <tbody>
              {offers &&
                offers['hydra:member'].map((e, i) => {
                  // Do not show offer if it's not within pagination range
                  if (
                    i < itemsPerPage * page - itemsPerPage ||
                    i >= itemsPerPage * page
                  ) {
                    return null;
                  }

                  let btnClass;

                  switch (e.status.name) {
                    case 'declined':
                      btnClass = ' btn--small-error';
                      break;
                    case 'booked':
                      btnClass = ' btn--small-active';
                      break;
                    case 'accepted':
                      btnClass = ' btn--small-warning';
                      break;
                    case 'closed':
                      btnClass = ' btn--small-inactive';
                      break;
                    case 'expired':
                      btnClass = ' btn--small-inactive';
                      break;
                    case 'not_confirmed':
                      btnClass = ' btn--small-inactive';
                      break;
                    default:
                      btnClass = '';
                  }

                  return (
                    <tr
                      key={e.id}
                      className={
                        offer && e.id === offer.id ? 'active' : undefined
                      }
                      onClick={() => setOffer(e)}
                    >
                      <td>
                        <span>{e.id}</span>
                      </td>
                      <td>{e.company.name}</td>
                      <td>{numberToCHF(e.price)}</td>
                      <td>{e.city}</td>
                      <td>{e.apartmentType.name}</td>
                      <td>{e.sqm}</td>
                      <td>{ucwords(request.requestedRateType)}</td>
                      <td>
                        <a
                          href={e.link}
                          target="_blank"
                          className="text--link-pink"
                        >
                          <span className="fas fa-link" />
                        </a>
                      </td>
                      <td>
                        <span>
                          <Dropdown className>
                            <Dropdown.Toggle variant="success">
                              <span className="fas fa-file-pdf text--link-pink" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item
                                href={`/offers/${e.id}/pdf?price=true`}
                                target="_blank"
                              >
                                Price included
                              </Dropdown.Item>
                              <Dropdown.Item
                                href={`/offers/${e.id}/pdf`}
                                target="_blank"
                              >
                                Price not included
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </span>
                      </td>
                      {!hasRole(roles, [CLIENT, SUB_CLIENT]) && (
                        <td>
                          <a href="#feedback">
                            <i className="fa fa-comment text-pink" />
                          </a>
                        </td>
                      )}
                      <td>
                        {e.status.name === 'pending' ? (
                          ucwords(e.status.name)
                        ) : (
                          <button
                            type="button"
                            className={`btn btn--small${btnClass}`}
                          >
                            {ucwords(e.status.name.replace('_', ' '))}
                          </button>
                        )}
                      </td>
                      <td>
                        <span
                          className={
                            !e.availabilityDiffers
                              ? 'fas fa-check text-green'
                              : ''
                          }
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <Pagination
            hideDisabled
            activePage={page}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={offers ? offers['hydra:totalItems'] : 0}
            onChange={page => setPage(page)}
            itemClass="page-item"
            linkClass="page-link"
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const {
    retrieved: offers,
    loading: offersLoading,
    error: offersError
  } = state.offer.list;

  const {
    created: createdNoAvailableOffer,
    error: errorNoAvailableOffer,
    loading: loadingNoAvailableOffer
  } = state.noAvailableOffer.create;

  const {
    retrieved: noAvailableOffers,
    loading: noAvailableOffersLoading
  } = state.noAvailableOffer.list;

  return {
    offers,
    offersLoading,
    offersError,
    createdNoAvailableOffer,
    errorNoAvailableOffer,
    loadingNoAvailableOffer,
    noAvailableOffers,
    noAvailableOffersLoading
  };
};

const mapDispatchToProps = {
  listOffersAction,
  listOffersErrorAction,
  createNoAvailableOfferAction,
  resetNoAvailableOfferAction,
  listNoAvailableOffers,
  resetNoAvailableOffers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
