import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'COMPANY_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'COMPANY_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'COMPANY_CREATE_SUCCESS', created };
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));

    const body = new FormData();
    Object.keys(values).map(function(key) {
      if (key === 'logo' && values[key] instanceof FileList) {
        body.append(key, values[key][0]);
      } else {
        body.append(key, values[key]);
      }
    });

    return fetch('companies', { method: 'POST', body })
      .then(response => {
        dispatch(loading(false));
        return response.json();
      })
      .then(retrieved => {
        toast.success('Company successfully created');
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw e;
        }

        toast.error('Submission failed. Please fix the fields marked in red.');
        dispatch(error(e.message));
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}
