import React from 'react';
import { PublicRoute, PrivateRoute } from '../components/Authentication';
import {
  Login,
  ResetPasswordCreate,
  ResetPasswordConfirm,
  TermsOfService
} from '../components/Login';

export default [
  <PublicRoute
    key="login"
    path="/login"
    title="Login"
    component={Login}
    strict
    exact
  />,
  <PublicRoute
    key="set_password"
    path="/set-password/:id/:nonce"
    title="Set Password"
    component={ResetPasswordConfirm}
    strict
    exact
  />,
  <PublicRoute
    key="reset_password"
    path="/reset-password"
    title="Reset Password"
    component={ResetPasswordCreate}
    strict
    exact
  />,
  <PrivateRoute
    key="terms_of_service"
    path="/tos"
    title="Terms Of Service"
    components={{ sub_client: TermsOfService }}
    strict
    exact
  />
];
