import React from 'react';
import { PublicRoute } from '../components/Authentication';
import { DemoClient, DemoVendor } from '../components/Demo';

export default [
  <PublicRoute
    key="demo_client"
    path="/demo_client"
    component={DemoClient}
    strict
    exact
  />,
  <PublicRoute
    key="demo_vendor"
    path="/demo_vendor"
    component={DemoVendor}
    strict
    exact
  />
];
