/* eslint camelcase: off */
import { createStore, combineReducers, applyMiddleware } from 'redux';
import createBrowserHistory from 'history/createBrowserHistory';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { reducer as formReducer } from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import reducers from './reducers';
import { decodeJwt } from './utils/helpers';

export const history = createBrowserHistory();

const token = localStorage.getItem('token');
const refreshToken = localStorage.getItem('refreshToken');
const authentication = {
  token: {
    error: null,
    loading: false,
    retrieved: {
      token,
      refreshToken
    }
  }
};

try {
  const {
    id,
    firstName,
    lastName,
    company,
    companySubClientEnabled,
    email,
    roles,
    tosAccepted,
    exp
  } = decodeJwt(token);

  authentication.token.retrieved.id = id;
  authentication.token.retrieved.firstName = firstName;
  authentication.token.retrieved.lastName = lastName;
  authentication.token.retrieved.company = company;
  authentication.token.retrieved.companySubClientEnabled = companySubClientEnabled;
  authentication.token.retrieved.email = email;
  authentication.token.retrieved.roles = roles;
  authentication.token.retrieved.tosAccepted = tosAccepted;
  authentication.token.retrieved.expiresAt = new Date(exp * 1000);
} catch {
  authentication.token.retrieved.token = null;
}

const appReducer = combineReducers({
  router: connectRouter(history),
  form: formReducer,
  ...reducers
});

const rootReducer = (state, action) => {
  const newState = action.type === 'TOKEN_LOGOUT' ? undefined : state;

  return appReducer(newState, action);
};

const store = createStore(
  rootReducer,
  { authentication },
  composeWithDevTools(applyMiddleware(routerMiddleware(history), thunk))
);

export default store;
