/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import {
  Field,
  FieldArray,
  reduxForm,
  formValueSelector,
  change
} from 'redux-form';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import { InlineFieldGroup, InlineField, BlockField } from '../Common/Form';
import {
  listApartmentTypes,
  listRegions,
  userCompany
} from '../../actions/Request/other';
import { iconApartment, iconClient, iconGuest, Loader } from '../Util';
import { list as listDashboard } from '../../actions/Dashboard/list';
import { retrieve as retrieveUser } from '../../actions/User/show';
import { hasRole } from '../../utils/helpers';
import { SUB_CLIENT } from '../../constants/roles';

const renderGuests = ({ fields, meta: { error } }) => {
  return fields.map((guest, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>
      <Field
        component={BlockField}
        name={`${guest}.salutation`}
        label="Salutation"
        type="radioButton"
        options={[
          { id: `mrs_${index}`, value: 'mrs', label: 'Mrs.' },
          { id: `mr_${index}`, value: 'mr', label: 'Mr.' }
        ]}
      />
      <InlineFieldGroup label={`Guest ${index + 1}`} required={index === 0}>
        <Field
          component={InlineField}
          name={`${guest}.firstName`}
          type="text"
          className=""
          placeholder="First Name"
        />
        <Field
          component={InlineField}
          name={`${guest}.lastName`}
          type="text"
          className=""
          placeholder="Last Name"
        />
        <Field
          component={InlineField}
          name={`${guest}.dateOfBirth`}
          placeholder="Date of Birth (DD.MM.YYYY)"
          type="dateTime"
          displayPopper={false}
        />
        <Field
          component={InlineField}
          name={`${guest}.permit`}
          placeholder="Permit"
          type="select"
          items={[
            { name: 'L' },
            { name: 'B' },
            { name: 'C' },
            { name: 'Swiss Nationality' }
          ]}
        />
        {error && <h1>{error}</h1>}
      </InlineFieldGroup>
    </React.Fragment>
  ));
};

const Label = ({ label, value, className }) => (
  <div className={className}>
    <div className="col-sm-3">
      <span
        className="form-label font-weight-bold"
        style={{ lineHeight: '10px' }}
      >
        {label}
      </span>
    </div>
    <div className="col-sm-9">
      <span className="form-label" style={{ lineHeight: '10px' }}>
        {value}
      </span>
    </div>
  </div>
);

let Form = ({
  handleSubmit,
  submitSucceeded,
  submitting,
  array,
  adults,
  children,
  guests,
  pets,
  arrival,
  departure,
  listApartmentTypes,
  listRegions,
  apartmentTypesLoading,
  apartmentTypesRetrieved,
  regionsLoading,
  regionsRetrieved,
  userCompany,
  firstName,
  lastName,
  email,
  company,
  setModalState,
  listDashboard,
  differentBillingAddress,
  differentContractAddress,
  userCompanyRetrieved,
  change,
  duplicatedRequest,
  userId,
  currentUser,
  retrieveUser,
  roles
}) => {
  useEffect(() => {
    retrieveUser(userId);
    listApartmentTypes();
    listRegions();
    userCompany();
    document.getElementById('title').focus();
  }, {});

  useEffect(() => {
    if (!duplicatedRequest && userCompanyRetrieved) {
      change('nestOnly', userCompanyRetrieved.defaultNestOnly);
      change('anonymous', userCompanyRetrieved.defaultAnonymous);
    }
  }, [userCompanyRetrieved]);

  useEffect(() => {
    if (submitSucceeded) {
      toast.success('Request submitted.');
      listDashboard();
      setModalState(false);
    }
  }, [submitSucceeded]);

  useEffect(() => {
    let totalGuests = (adults || 0) + (children || 0);

    if (guests) {
      while (guests && totalGuests !== guests.length) {
        if (totalGuests > guests.length) {
          array.insert('guests', guests.length, {});
        } else {
          array.pop('guests');
        }

        totalGuests += totalGuests > guests.length ? -1 : 1;
      }
    } else {
      for (let i = 0; i < totalGuests; i++) {
        array.insert('guests', i, {});
      }
    }
  }, [adults, children]);

  return (
    <>
      {(regionsLoading ||
        apartmentTypesLoading ||
        submitting ||
        (hasRole(roles, SUB_CLIENT) && !currentUser)) && <Loader />}
      <div className="modal__content pt-0">
        <form onSubmit={handleSubmit}>
          <Row className="mb-5">
            <Col md={12}>
              <h2 className="modal__header">
                <img src={iconClient} alt="icon client" /> Client
              </h2>
              <Label
                label="Name"
                value={`${firstName} ${lastName}`}
                className="form-group row mb-1"
              />
              <Label
                label="E-Mail"
                value={email}
                className="form-group row mb-1"
              />
              <Label
                label="Company"
                value={company}
                className="form-group row"
              />
              <Field
                component={BlockField}
                name="differentBillingAddress"
                label="Different Billing Address"
                type="checkbox"
                disabled={hasRole(roles, SUB_CLIENT)}
              />
              {differentBillingAddress && (
                <>
                  <Field
                    component={BlockField}
                    name="billingCompany"
                    label="Company"
                    placeholder="Company Name"
                    type="text"
                    required
                  />
                  <Field
                    component={BlockField}
                    name="billingAddress"
                    label="Address"
                    type="text"
                    placeholder="123 Main Street"
                  />
                  <InlineFieldGroup
                    label="Zip / City"
                    labelClass="col-sm-3"
                    childrenClass="col-sm-9"
                  >
                    <Field
                      component={InlineField}
                      name="billingZip"
                      type="text"
                      placeholder="8004"
                      inputClass="col-3"
                      label="Zip"
                      required
                    />
                    <Field
                      component={InlineField}
                      name="billingCity"
                      type="text"
                      placeholder="Zürich"
                      inputClass="col-9"
                      label="City"
                      required
                    />
                  </InlineFieldGroup>
                  <Field
                    component={BlockField}
                    name="billingCountry"
                    label="Country"
                    placeholder="Switzerland"
                    type="text"
                    required
                  />
                </>
              )}
              <Field
                component={BlockField}
                name="differentContractAddress"
                label="Different Contract Address"
                type="checkbox"
              />
              {differentContractAddress && (
                <>
                  <Field
                    component={BlockField}
                    name="contractCompany"
                    label="Company"
                    placeholder="Company Name"
                    type="text"
                    required
                  />
                  <Field
                    component={BlockField}
                    name="contractAddress"
                    label="Address"
                    type="text"
                    placeholder="123 Main Street"
                  />
                  <InlineFieldGroup
                    label="Zip / City"
                    labelClass="col-sm-3"
                    childrenClass="col-sm-9"
                  >
                    <Field
                      component={InlineField}
                      name="contractZip"
                      type="text"
                      placeholder="8004"
                      inputClass="col-3"
                      label="Zip"
                      required
                    />
                    <Field
                      component={InlineField}
                      name="contractCity"
                      type="text"
                      placeholder="Zürich"
                      inputClass="col-9"
                      label="City"
                      required
                    />
                  </InlineFieldGroup>
                  <Field
                    component={BlockField}
                    name="contractCountry"
                    label="Country"
                    placeholder="Switzerland"
                    type="text"
                    required
                  />
                </>
              )}
              {!differentBillingAddress && (
                <Field
                  component={BlockField}
                  name="billingToGuest"
                  label="Billing Directly to Guest"
                  type="checkbox"
                  disabled={hasRole(roles, SUB_CLIENT)}
                />
              )}
              <Field
                component={BlockField}
                name="anonymous"
                label="Anonymous"
                type="checkbox"
              />
              <Field
                component={BlockField}
                name="nestOnly"
                label="Nest only"
                type="checkbox"
              />
              <Field
                component={BlockField}
                name="title"
                label="Title"
                type="text"
                placeholder="Apartment in..."
                required
              />
              <InlineFieldGroup label="Client references">
                <Field
                  component={InlineField}
                  name="clientReferences[0].reference"
                  type="text"
                  className=""
                  placeholder="PO/File Reference #1"
                />
                <Field
                  component={InlineField}
                  name="clientReferences[1].reference"
                  type="text"
                  className=""
                  placeholder="PO/File Reference #2"
                />
                <Field
                  component={InlineField}
                  name="clientReferences[2].reference"
                  type="text"
                  className=""
                  placeholder="PO/File Reference #3"
                />
                <Field
                  component={InlineField}
                  name="clientReferences[3].reference"
                  type="text"
                  className=""
                  placeholder="PO/File Reference #4"
                />
              </InlineFieldGroup>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={12}>
              <h2 className="modal__header">
                <img src={iconGuest} alt="icon client" /> Guest Information
              </h2>
              <Field
                component={BlockField}
                name="guestName"
                type="text"
                label="Name"
                placeholder="Max Mustermann"
                required
              />
              <Field
                component={BlockField}
                name="guestEmail"
                type="text"
                label="Guest Email"
                placeholder="max.mustermann@email.ch"
              />
              <Field
                component={BlockField}
                name="adults"
                label="Adults"
                type="select"
                placeholder="None"
                items={[
                  { name: '1' },
                  { name: '2' },
                  { name: '3' },
                  { name: '4' },
                  { name: '5' }
                ]}
                required
                normalize={value => parseFloat(value) || 0}
              />
              <Field
                component={BlockField}
                name="children"
                label="Children"
                type="select"
                placeholder="None"
                items={[
                  { name: '1' },
                  { name: '2' },
                  { name: '3' },
                  { name: '4' },
                  { name: '5' }
                ]}
                normalize={value => parseFloat(value) || 0}
              />
              <FieldArray
                name="guests"
                component={renderGuests}
                count={parseFloat(adults || 0) + parseFloat(children || 0)}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <h2 className="modal__header">
                <img src={iconApartment} alt="icon apartment" /> Apartment
              </h2>
              <Field
                component={BlockField}
                name="regions"
                label="City/Region"
                type="checkbox"
                items={regionsRetrieved.map(e => ({
                  name: `regions_${e.id}`,
                  value: `/regions/${e.id}`,
                  label: e.name
                }))}
                required
              />
              <Field
                component={BlockField}
                name="location"
                label="Preferred Location"
                type="placesInput"
                placeholder="point of interest, office address, location etc."
                format={e => (e && e.address ? e.address : '')}
              />
              <Field
                component={BlockField}
                name="apartmentTypes"
                label="Apartment Type"
                type="checkbox"
                placeholder="Please choose..."
                items={apartmentTypesRetrieved.map(e => ({
                  value: `/apartment_types/${e.id}`,
                  label: e.name,
                  name: e.name
                }))}
                required
              />
              <InlineFieldGroup label="Dates" required>
                <Field
                  component={InlineField}
                  name="arrival"
                  label="Arrival"
                  initialVisibleMonth={() =>
                    arrival ? moment(arrival) : moment()
                  }
                  type="dateTime"
                  placeholder="Arrival"
                />
                <Field
                  component={InlineField}
                  name="departure"
                  type="dateTime"
                  startDate={arrival}
                  initialVisibleMonth={() => {
                    if (departure) {
                      return moment(departure);
                    }

                    if (arrival) {
                      return moment(arrival);
                    }

                    return moment();
                  }}
                  isOutsideRange={day => {
                    return arrival
                      ? moment(arrival)
                          .add(1, 'day')
                          .isAfter(day)
                      : false;
                  }}
                  placeholder="Departure/empty for open-end"
                />
              </InlineFieldGroup>
              <Field
                component={BlockField}
                type="select"
                name="requestedRateType"
                label="Request Rate Type"
                placeholder="Please Choose..."
                items={[
                  {
                    value: 'nightly',
                    name: 'Nightly'
                  },
                  {
                    value: 'monthly',
                    name: 'Monthly'
                  }
                ]}
                required
              />
              <Field
                component={BlockField}
                name="budgets"
                label="Budgets"
                type="currencyInput"
                placeholder="CHF 210.-"
              />
              <Field
                component={BlockField}
                name="cleaning"
                label="Cleaning"
                type="radioButton"
                options={[
                  {
                    id: 'weekly',
                    value: 'weekly',
                    label: 'Weekly'
                  },
                  {
                    id: 'twice_a_month',
                    value: 'twice a month',
                    label: 'Twice a month'
                  }
                ]}
              />
              <Field
                component={BlockField}
                name="babyPackage"
                label="Baby Package"
                type="checkbox"
              />
              <Field
                component={BlockField}
                name="parking"
                label="Parking Slot"
                type="checkbox"
              />
              <Field
                component={BlockField}
                name="pets"
                label="Pets"
                type="number"
                placeholder="Number of pets"
                normalize={value => parseFloat(value) || ''}
              />
              {!isNaN(parseFloat(pets)) && isFinite(pets) && pets > 0 && (
                <Field
                  component={BlockField}
                  name="petBreeds"
                  label="Pet Breeds"
                  placeholder="Comma separated (Dog, Cat, ...)"
                  type="text"
                />
              )}
              <Field
                component={BlockField}
                name="comment"
                label="Remarks"
                type="wysiwig"
                placeholder="If it's possible..."
              />
            </Col>
          </Row>
          <div className="form-group row">
            <div className="col-12">
              <div className="float-right">
                <button type="submit" className="btn btn--primary">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Title is required.';
  }

  if (!values.guestName) {
    errors.guestName = 'Name is required.';
  }

  if (!values.adults) {
    errors.adults = 'At least one adult must be selected.';
  } else {
    const guestErrors = {};

    const guest = values.guests && typeof values.guests[0] !== 'undefined';

    if (!guest || !values.guests[0].firstName) {
      guestErrors.firstName = 'First name is required.';
    }

    if (!guest || !values.guests[0].lastName) {
      guestErrors.lastName = 'Last name is required.';
    }

    if (
      Object.keys(guestErrors).length !== 0 &&
      guestErrors.constructor === Object
    ) {
      errors.guests = [guestErrors];
    }
  }

  if (!values.regions || !values.regions.length) {
    errors.regions = 'At least one of the above must be selected.';
  }

  if (!values.apartmentTypes || !values.apartmentTypes.length) {
    errors.apartmentTypes = 'At least one of the above must be selected.';
  }

  if (!values.arrival) {
    errors.arrival = 'Arrival date must be set.';
  }

  if (
    values.arrival &&
    values.departure &&
    new Date(values.arrival) >= new Date(values.departure)
  ) {
    errors.departure = 'Departure date must be greater than arrival date.';
  }

  if (!values.requestedRateType) {
    errors.requestedRateType = 'One of the options above must be selected.';
  }

  if (values.budgets && parseFloat(values.budgets) < 0) {
    errors.budgets = 'Negative values values are not allowed.';
  }

  if (values.pets && values.pets < 0) {
    errors.pets = 'Negative values are not allowed.';
  }

  if (values.differentBillingAddress && !values.billingZip) {
    errors.billingZip = 'Zip is required.';
  }

  if (values.differentBillingAddress && !values.billingCity) {
    errors.billingCity = 'City is required.';
  }

  if (values.differentBillingAddress && !values.billingCountry) {
    errors.billingCountry = 'Country is required.';
  }

  if (values.differentBillingAddress && !values.billingCompany) {
    errors.billingCompany = 'Company is required.';
  }

  if (values.differentContractAddress && !values.contractZip) {
    errors.contractZip = 'Zip is required.';
  }

  if (values.differentContractAddress && !values.contractCity) {
    errors.contractCity = 'City is required.';
  }

  if (values.differentContractAddress && !values.contractCountry) {
    errors.contractCountry = 'Country is required.';
  }

  if (values.differentContractAddress && !values.contractCompany) {
    errors.contractCompany = 'Company is required.';
  }

  if (
    values.location &&
    values.location.address !== '' &&
    !values.location.pos
  ) {
    errors.location = 'Please select an address from the dropdown.';
  }

  if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
    return {
      ...errors,
      _error: 'Submission failed. Please fix the fields marked in red.'
    };
  }

  return errors;
};

Form = reduxForm({
  form: 'request',
  validate,
  onSubmitFail: (errors, dispatch, submitErrors, props) => {
    toast.error(errors._error || props.error);
  },
  enableReinitialize: true,
  keepDirtyOnReinitialize: true
})(Form);

const selector = formValueSelector('request');

export default connect(
  (state, { duplicatedRequest }) => {
    const {
      adults,
      children,
      guests,
      pets,
      arrival,
      departure,
      differentBillingAddress,
      differentContractAddress
    } = selector(
      state,
      'adults',
      'children',
      'guests',
      'pets',
      'arrival',
      'departure',
      'differentBillingAddress',
      'differentContractAddress'
    );

    const {
      apartmentTypesLoading,
      apartmentTypesRetrieved,
      regionsLoading,
      regionsRetrieved,
      userCompanyLoading,
      userCompanyRetrieved
    } = state.request.other;

    const {
      firstName,
      lastName,
      email,
      company
    } = state.authentication.token.retrieved;

    const { id } = state.authentication.token.retrieved;
    const { retrieved: currentUser } = state.user.show;
    const { roles } = state.authentication.token.retrieved;

    let initialValues = {
      anonymous: !!userCompanyRetrieved.defaultAnonymous,
      nestOnly: !!userCompanyRetrieved.defaultNestOnly,
      babyPackage: false,
      parking: false,
      cleaning: 'twice a month',
      adults: 1,
      differentBillingAddress:
        (hasRole(roles, SUB_CLIENT) &&
          currentUser &&
          currentUser.invoiceTo === 'company') ||
        false,
      billingToGuest:
        (hasRole(roles, SUB_CLIENT) &&
          currentUser &&
          currentUser.invoiceTo === 'guest') ||
        undefined
    };

    if (duplicatedRequest) {
      Object.keys(duplicatedRequest.guests).forEach(key => {
        duplicatedRequest.guests[key] === null &&
          delete duplicatedRequest.guests[key];
      });

      const location =
        duplicatedRequest.preferredLocation ||
        duplicatedRequest.preferredLocationPos
          ? {
              address: duplicatedRequest.preferredLocation || undefined,
              pos: duplicatedRequest.preferredLocationPos || undefined
            }
          : undefined;

      initialValues = {
        differentBillingAddress:
          duplicatedRequest.differentBillingAddress || false,
        differentContractAddress:
          duplicatedRequest.differentContractAddress || undefined,
        billingAddress: duplicatedRequest.billingAddress || undefined,
        billingZip: duplicatedRequest.billingZip || undefined,
        billingCity: duplicatedRequest.billingCity || undefined,
        billingCountry: duplicatedRequest.billingCountry || undefined,
        billingCompany: duplicatedRequest.billingCompany || undefined,
        contractAddress: duplicatedRequest.contractAddress || undefined,
        contractZip: duplicatedRequest.contractZip || undefined,
        contractCity: duplicatedRequest.contractCity || undefined,
        contractCountry: duplicatedRequest.contractCountry || undefined,
        contractCompany: duplicatedRequest.contractCompany || undefined,
        billingToGuest: duplicatedRequest.billingToGuest || undefined,
        anonymous: duplicatedRequest.anonymous || undefined,
        nestOnly: duplicatedRequest.nestOnly || undefined,
        title: duplicatedRequest.title || undefined,
        clientReferences: duplicatedRequest.clientReferences
          ? duplicatedRequest.clientReferences.filter(e => e !== null)
          : undefined,
        guests: duplicatedRequest.guests,
        guestName: duplicatedRequest.guestName || undefined,
        guestEmail: duplicatedRequest.guestEmail || undefined,
        adults: duplicatedRequest.adults || undefined,
        children: duplicatedRequest.children || 0 || undefined,
        location,
        arrival: duplicatedRequest.arrival || undefined,
        departure: duplicatedRequest.departure || undefined,
        requestedRateType: duplicatedRequest.requestedRateType || undefined,
        budgets: duplicatedRequest.budgets || undefined,
        cleaning: duplicatedRequest.cleaning || undefined,
        babyPackage: duplicatedRequest.babyPackage || false,
        parking: duplicatedRequest.parking || false,
        pets: duplicatedRequest.pets || undefined,
        petBreeds: duplicatedRequest.petBreeds || undefined,
        comment: duplicatedRequest.comment || undefined,
        regions: duplicatedRequest.regions.map(e => `/regions/${e.id}`),
        apartmentTypes: duplicatedRequest.apartmentTypes.map(
          e => `/apartment_types/${e.id}`
        )
      };
    }

    return {
      adults,
      children,
      guests,
      pets,
      arrival,
      departure,
      errors: state.form.request ? state.form.request.syncErrors : {},
      apartmentTypesLoading,
      apartmentTypesRetrieved,
      regionsLoading,
      regionsRetrieved,
      userCompanyLoading,
      userCompanyRetrieved,
      firstName,
      lastName,
      email,
      company,
      differentBillingAddress,
      differentContractAddress,
      initialValues,
      userId: id,
      currentUser,
      roles
    };
  },
  {
    listApartmentTypes,
    listRegions,
    listDashboard,
    userCompany,
    change,
    retrieveUser
  }
)(Form);
