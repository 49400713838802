/* eslint react/prop-types: 0 */
import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Input } from '../Common/Form';
import logo from '../../assets/nest_logo.svg';
import { Loader } from '../Util';
import { getToken } from '../../actions/Authentication/token';

const renderField = data => (
  <div className="form-group row">
    <Input
      {...data}
      type={data.type}
      step={data.step}
      className="col-12 text-left"
      required={data.required}
      placeholder={data.placeholder}
      id={`request_${data.input.name}`}
      error={data.meta.touched && data.meta.error ? data.meta.error : null}
    />
  </div>
);

const Login = ({
  handleSubmit,
  getToken,
  error,
  submitting,
  submitSucceeded
}) => (
  <div className="login">
    {submitSucceeded && <Redirect to="/" />}
    <div className="login__wrapper">
      <div className="login__box">
        <img src={logo} alt="Nest Logo" className="login__logo" />
        <h4 className="text text--normal" style={{ marginBottom: '40px' }}>
          Log in to our platform.
        </h4>
        <form onSubmit={handleSubmit(getToken)}>
          <Field
            id="email"
            type="email"
            name="email"
            placeholder="Email"
            component={renderField}
          />
          <Field
            id="password"
            name="password"
            type="password"
            placeholder="Password"
            component={renderField}
          />
          {!submitting && error && (
            <h6
              className="login__heading login__heading--small"
              style={{ color: 'red', marginBottom: '0' }}
            >
              {error}
            </h6>
          )}
          <button type="submit" className="btn btn--primary">
            Log In
          </button>
          {submitting && <Loader />}
          <h6 className="text text--small mb-3">
            <Link to="/reset-password" className="text text--link-pink">
              Reset password
            </Link>
          </h6>
          <h6 className="text text--small mb-0">
            No access yet?{' '}
            <Link to="/apply" className="text text--link-pink">
              Apply now
            </Link>
          </h6>
        </form>
      </div>
    </div>
  </div>
);

const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = 'Email is required.';
  }

  if (!values.password) {
    errors.password = 'Password is required.';
  }

  return errors;
};

const reduxFormLogIn = reduxForm({
  form: 'login',
  validate
})(Login);

export default connect(
  null,
  { getToken }
)(reduxFormLogIn);
