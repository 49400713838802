import { combineReducers } from 'redux';

export function consultantError(state = null, action) {
  switch (action.type) {
    case 'USER_CONSULTANT_ERROR':
      return action.consultantError;

    default:
      return state;
  }
}

export function consultantLoading(state = false, action) {
  switch (action.type) {
    case 'USER_CONSULTANT_LOADING':
      return action.consultantLoading;

    default:
      return state;
  }
}

export function consultantRetrieved(state = [], action) {
  switch (action.type) {
    case 'USER_CONSULTANT_SUCCESS':
      return action.consultantRetrieved;

    default:
      return state;
  }
}

export default combineReducers({
  consultantError,
  consultantLoading,
  consultantRetrieved
});
