import React from 'react';
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap';
import { generate as generateKey } from 'shortid';
import PropTypes from 'prop-types';

const Tooltip = ({ children, title, placement }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={<BootstrapTooltip id={generateKey()}>{title}</BootstrapTooltip>}
    >
      {children}
    </OverlayTrigger>
  );
};

Tooltip.propTypes = {
  placement: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

Tooltip.defaultProps = {
  placement: 'top'
};

export default Tooltip;
