/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { create, reset } from '../../actions/Offer/create';
import { listApartmentTypes } from '../../actions/Request/other';
import { retrieve } from '../../actions/Request/show';
import Form from './Form';
import { Detail as RequestDetail } from '../Request';
import { Modal, Loader } from '../Util';

const Create = ({
  create,
  retrieveRequest,
  request,
  requestError,
  requestLoading,
  modalState,
  setModalState,
  requestId,
  setRequestId,
  listApartmentTypes,
  apartmentTypesLoading,
  apartmentTypesRetrieved
}) => {
  useEffect(() => {
    retrieveRequest(requestId);
    listApartmentTypes();

    return () => {
      setRequestId();
    };
  }, {});

  useEffect(() => {
    if (requestError) {
      setRequestId();
      setModalState(false);
    }
  }, requestError);

  return !request || requestLoading || apartmentTypesLoading ? (
    <Loader />
  ) : (
    <Modal isOpen={modalState}>
      <RequestDetail
        request={request}
        modalState={modalState}
        setModalState={setModalState}
      />
      <Form
        apartmentTypesRetrieved={apartmentTypesRetrieved}
        onSubmit={create}
        request={request}
      />
    </Modal>
  );
};

const mapStateToProps = state => {
  const {
    retrieved: request,
    error: requestError,
    loading: requestLoading
  } = state.request.show;

  const {
    created: offerCreated,
    error: offerError,
    loading: offerLoading
  } = state.offer.create;

  const {
    apartmentTypesLoading,
    apartmentTypesRetrieved
  } = state.request.other;

  return {
    request,
    requestError,
    requestLoading,
    offerCreated,
    offerError,
    offerLoading,
    apartmentTypesLoading,
    apartmentTypesRetrieved
  };
};

const mapDispatchToProps = dispatch => ({
  create: values => dispatch(create(values)),
  reset: () => dispatch(reset()),
  retrieveRequest: id => dispatch(retrieve(id)),
  listApartmentTypes: () => dispatch(listApartmentTypes())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Create);
