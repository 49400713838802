/* eslint react/prop-types: "off" */
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '.';
import { hasRole } from '../../utils/helpers';
import {
  USER,
  VENDOR,
  ADMIN,
  HOST,
  HOST_ADMIN,
  CLIENT,
  SUB_CLIENT
} from '../../constants/roles';
import { NotFound } from '../Common/Pages';

const PrivateRoute = ({
  token,
  roles,
  components,
  component: currentComponent,
  title,
  path,
  tosAccepted,
  companySubClientEnabled,
  ...rest
}) => {
  if (!token) {
    return <Redirect to="/login" />;
  }

  if (path === '/users' && hasRole(roles, CLIENT) && !companySubClientEnabled) {
    return <Redirect to="/login" />;
  }

  if (hasRole(roles, SUB_CLIENT) && !tosAccepted && path !== '/tos') {
    return <Redirect to="/tos" />;
  }

  if (!components) {
    return (
      <Route
        render={props => (
          <Page {...props} component={currentComponent} title={title} />
        )}
      />
    );
  }

  if (!roles || !roles[0]) {
    throw new Error('Role is not set.');
  }

  let component = null;

  switch (roles[0]) {
    case USER:
      component = components.user;
      break;
    case VENDOR:
      component = components.vendor;
      break;
    case ADMIN:
      component = components.admin;
      break;
    case HOST:
      component = components.host;
      break;
    case HOST_ADMIN:
      component = components.hostadmin;
      break;
    case CLIENT:
      component = components.client;
      break;
    case SUB_CLIENT:
      component = components.sub_client;
      break;
    default:
      break;
  }

  if (!component) {
    return <NotFound />;
  }

  return (
    <Route
      {...rest}
      render={props => <Page {...props} component={component} title={title} />}
    />
  );
};

export default connect(state => {
  let token = null;
  let roles = null;
  let tosAccepted = null;
  let companySubClientEnabled = null;

  const { retrieved } = state.authentication.token;

  if (retrieved) {
    ({ token, roles, tosAccepted, companySubClientEnabled } = retrieved);
  }

  return { token, roles, tosAccepted, companySubClientEnabled };
})(PrivateRoute);
