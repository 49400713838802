import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { generate as generateKey } from 'shortid';
import { iconSearch } from '../../../Util';
import { useOutsideAlerter } from '../../../../utils/hooks';

const SuggestionInput = ({
  placeholder,
  list,
  listAction,
  resetAction,
  setItem,
  className
}) => {
  const [value, setValue] = useState('');

  const ref = useRef(null);

  useOutsideAlerter(ref, resetAction);

  useEffect(() => {
    resetAction();
  }, []);

  return (
    <div className="position-relative" ref={ref}>
      <div className={`input-group ${className}`}>
        <div className="input-group-prepend">
          <div className="input-group-text" id="btnGroupAddon">
            <img src={iconSearch} alt="search" />
          </div>
        </div>
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          value={value}
          onChange={e => {
            if (!e.target.value) {
              resetAction();
            } else {
              listAction(e);
            }
            setValue(e.target.value);
          }}
        />
      </div>
      {!!(list && list['hydra:member'].length) && (
        <div className="input-autocomplete__container w-auto">
          {list['hydra:member'].map(offer => (
            <div
              key={generateKey()}
              onClick={() => {
                setItem(offer);
                setValue(offer.address);
                resetAction();
              }}
              className="input-autocomplete__item"
            >
              <span>{`${offer.address} - ${offer.apartmentType.name}`}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

SuggestionInput.defaultProps = {
  placeholder: 'Search',
  setItem: undefined,
  list: null,
  className: ''
};

SuggestionInput.propTypes = {
  placeholder: PropTypes.string,
  list: PropTypes.object,
  listAction: PropTypes.func.isRequired,
  resetAction: PropTypes.func.isRequired,
  setItem: PropTypes.func,
  className: PropTypes.string
};

export default SuggestionInput;
