/* eslint react/prop-types: 0 */
import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { list, reset } from '../../actions/Notification/list';
import { markSeen } from '../../actions/Notification/update';
import notifcationIcon from '../../assets/notification_icon.svg';
import NotificationPopup from './NotificationPopup';

const NotificationIcon = ({ retrieved, loading, list, markSeen, updated }) => {
  const [modalState, setModalState] = useState();
  const [notificationCount, setNotificationCount] = useState(0);
  const [markedSeen, setMarkedSeen] = useState(false);
  const [notifications, setNotifications] = useState();

  const onClickEvent = event => {
    if (event.target.closest('#notification-box, .notification-modal')) {
      return;
    }

    if (modalState) {
      setModalState(false);
    }
  };

  const onKeydownEvent = () => {
    if (modalState) {
      setModalState(false);
    }
  };

  useEffect(() => {
    list();
  }, []);

  useEffect(() => {
    setNotifications(retrieved);
  }, [retrieved]);

  useEffect(() => {
    if (
      modalState === true &&
      notifications &&
      notifications['hydra:member'].length &&
      !markedSeen
    ) {
      markSeen(notifications['hydra:member'][0].user);
      setMarkedSeen(true);
    }

    document.addEventListener('click', onClickEvent, false);
    document.addEventListener('keydown', onKeydownEvent, false);

    return () => {
      document.removeEventListener('click', onClickEvent, false);
      document.removeEventListener('keydown', onKeydownEvent, false);
    };
  }, [modalState]);

  useEffect(() => {
    if (retrieved) {
      const count = retrieved['hydra:member'].reduce((acc, curr) => {
        acc += curr.seenTime == null ? 1 : 0;
        return acc;
      }, 0);
      setNotificationCount(count);
      setMarkedSeen(false);
    }
  }, [retrieved]);

  useEffect(() => {
    if (updated && notifications && notifications['hydra:member']) {
      const updatedNotifications = { ...notifications };
      const index = updatedNotifications['hydra:member'].findIndex(
        el => el.id === updated.id
      );
      updatedNotifications['hydra:member'][index] = updated;
      setNotifications(updatedNotifications);
    }
  }, [updated]);

  return (
    <div id="notification-box">
      <Nav.Link
        className="nav-link--no-hover"
        onClick={() => setModalState(!modalState)}
      >
        <img
          src={notifcationIcon}
          alt="Notification Icon"
          className="navbar__notification-icon"
        />
        {!loading && notifications && notifications.unSeen > 0 && !markedSeen && (
          <div className="notification-icon-wrapper">
            <div className="notification-icon-box notification-icon-box--pink">
              <span className="notification-icon-box__counter">
                {notifications.unSeen}
              </span>
            </div>
          </div>
        )}
      </Nav.Link>
      {modalState && (
        <NotificationPopup
          notificationCount={notificationCount}
          setModalStatus={setModalState}
          loading={loading}
          notifications={notifications}
        />
      )}
    </div>
  );
};

export default connect(
  state => {
    const { retrieved, loading, error, eventSource } = state.notification.list;
    const { updated } = state.notification.update;

    return {
      retrieved,
      loading,
      error,
      eventSource,
      updated
    };
  },
  {
    list,
    markSeen,
    reset
  }
)(NotificationIcon);
