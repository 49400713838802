import { combineReducers } from 'redux';

export function apartmentTypesError(state = null, action) {
  switch (action.type) {
    case 'REQUEST_APARTMENT_TYPES_ERROR':
      return action.apartmentTypesError;

    default:
      return state;
  }
}

export function apartmentTypesLoading(state = false, action) {
  switch (action.type) {
    case 'REQUEST_APARTMENT_TYPES_LOADING':
      return action.apartmentTypesLoading;

    default:
      return state;
  }
}

export function apartmentTypesRetrieved(state = [], action) {
  switch (action.type) {
    case 'REQUEST_APARTMENT_TYPES_SUCCESS':
      return action.apartmentTypesRetrieved;

    default:
      return state;
  }
}

export function regionsError(state = null, action) {
  switch (action.type) {
    case 'REQUEST_REGIONS_ERROR':
      return action.regionsError;

    default:
      return state;
  }
}

export function regionsLoading(state = false, action) {
  switch (action.type) {
    case 'REQUEST_REGIONS_LOADING':
      return action.regionsLoading;

    default:
      return state;
  }
}

export function regionsRetrieved(state = [], action) {
  switch (action.type) {
    case 'REQUEST_REGIONS_SUCCESS':
      return action.regionsRetrieved;

    default:
      return state;
  }
}

export function userCompanyError(state = null, action) {
  switch (action.type) {
    case 'REQUEST_USER_COMPANY_ERROR':
      return action.userCompanyError;

    default:
      return state;
  }
}

export function userCompanyLoading(state = false, action) {
  switch (action.type) {
    case 'REQUEST_USER_COMPANY_LOADING':
      return action.userCompanyLoading;

    default:
      return state;
  }
}

export function userCompanyRetrieved(state = [], action) {
  switch (action.type) {
    case 'REQUEST_USER_COMPANY_SUCCESS':
      return action.userCompanyRetrieved;

    default:
      return state;
  }
}

export default combineReducers({
  apartmentTypesError,
  apartmentTypesLoading,
  apartmentTypesRetrieved,
  regionsError,
  regionsLoading,
  regionsRetrieved,
  userCompanyError,
  userCompanyLoading,
  userCompanyRetrieved
});
