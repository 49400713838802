import React from 'react';
import PropTypes from 'prop-types';

const CardStatus = ({ request }) => (
  <div className="card d-flex justify-content-between">
    {request.hasOwnProperty('count') && (
      <div className="card__request card__request--pink d-flex align-items-center">
        <div className="align-self-center text-center w-100">
          <span>{request.count}</span>
        </div>
      </div>
    )}
    <div className="card__content card__content--status d-flex align-items-center">
      <h2 className="card__main-title">{request.title}</h2>
    </div>
    <div className="card__info">
      <img src={request.icon} alt="icon delivery" />
    </div>
  </div>
);

CardStatus.propTypes = {
  request: PropTypes.shape({
    count: PropTypes.number,
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
  })
};

CardStatus.defaultProps = {
  request: PropTypes.shape({
    count: 0
  })
};

export default CardStatus;
