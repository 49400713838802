/* eslint react/prop-types: 0 */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Pagination from 'react-js-pagination';
import { list } from '../../actions/NoAvailableOffer/list';
import { Loader } from '../Util';

const NoAvailabilityTable = ({ list, retrieved, loading, request }) => {
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    list(`apartment_requests/${request.id}/no_available_offers`);
  }, []);

  return (
    <div className="table-responsive position-relative">
      {loading && <Loader type="wrap" />}
      <table className="table table-shadow table-no-availability table-hover mt-3">
        <thead>
          <tr>
            <th scope="col">Id #</th>
            <th scope="col">Company</th>
          </tr>
        </thead>
        <tbody>
          {retrieved &&
            (retrieved['hydra:member'].length ? (
              retrieved['hydra:member'].map((e, i) => {
                // Do not show entry if it's not within pagination range
                if (
                  i < itemsPerPage * page - itemsPerPage ||
                  i >= itemsPerPage * page
                ) {
                  return null;
                }

                return (
                  <tr>
                    <td>{e.id}</td>
                    <td>{e.company.name}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  <i>No items available</i>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        hideDisabled
        activePage={page}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={retrieved ? retrieved['hydra:totalItems'] : 0}
        onChange={page => setPage(page)}
        itemClass="page-item"
        linkClass="page-link"
      />
    </div>
  );
};

export default connect(
  state => {
    const { retrieved, loading } = state.noAvailableOffer.list;

    return {
      retrieved,
      loading
    };
  },
  { list }
)(NoAvailabilityTable);
