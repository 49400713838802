import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ type }) => {
  let loaderType = null;

  if (type === 'full') {
    loaderType = 'loader--full';
  }

  if (type === 'wrap') {
    loaderType = 'loader--wrap';
  }

  return (
    <div className={`loader ${loaderType}`}>
      <div className="loader--center">
        <div className="ball-beat">
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

Loader.defaultProps = {
  type: 'full'
};

Loader.propTypes = {
  type: PropTypes.string
};

export default Loader;
