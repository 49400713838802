/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { iconClose } from '../../Util';
import { getConsultant } from '../../../actions/User/consultant';

const SupportHelp = ({ logo, consultantRetrieved, getConsultant }) => {
  const [infoStatus, setInfoStatus] = useState(false);

  const handleCloseOpen = () => setInfoStatus(!infoStatus);

  useEffect(() => {
    getConsultant();
  }, []);

  return (
    <div className={`support support--${infoStatus ? 'open' : 'close'} `}>
      {infoStatus && (
        <div className="support--data text-center">
          <button
            className="btn btn--close-modal support__closeicon"
            onClick={handleCloseOpen}
            type="button"
          >
            <img src={iconClose} alt="Close Help" />
          </button>
          {consultantRetrieved && consultantRetrieved.profilePicture && (
            <div className="support__image">
              <img
                src={`${process.env.REACT_APP_API_ENTRYPOINT}/uploads/${
                  consultantRetrieved.profilePicture
                }`}
                alt={`${consultantRetrieved.firstName} ${
                  consultantRetrieved.lastName
                }`}
              />
            </div>
          )}
          <img
            className={`support__brand${
              consultantRetrieved ? '--small' : '--large'
            }`}
            src={logo}
            alt="Nest"
            style={{ height: '50px' }}
          />
          <p className="text-center">
            {!consultantRetrieved && (
              <h5>
                <b>Contact</b>
              </h5>
            )}
            {consultantRetrieved && (
              <>
                {process.env.REACT_APP_SUPPORT_ROLE}
                <br />
              </>
            )}
            {consultantRetrieved && (
              <>
                {consultantRetrieved.firstName} {consultantRetrieved.lastName}
                <br />
              </>
            )}
            <a
              href={`mailto:${
                consultantRetrieved
                  ? consultantRetrieved.email
                  : process.env.REACT_APP_SUPPORT_EMAIL
              }`}
            >
              {consultantRetrieved
                ? consultantRetrieved.email
                : process.env.REACT_APP_SUPPORT_EMAIL}
            </a>{' '}
            <br />
            <a
              href={`tel:${
                consultantRetrieved
                  ? consultantRetrieved.mobileNumber
                  : process.env.REACT_APP_SUPPORT_PHONE
              }`}
            >
              {consultantRetrieved
                ? consultantRetrieved.mobileNumber
                : process.env.REACT_APP_SUPPORT_PHONE}
            </a>
          </p>
        </div>
      )}
      {!infoStatus && (
        <div className="support--help" onClick={handleCloseOpen}>
          <div className="d-flex align-items-center justify-content-around">
            <div className="support__info">
              <p>Need Help?</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default connect(
  state => {
    const {
      consultantLoading,
      consultantError,
      consultantRetrieved
    } = state.user.consultant;
    return {
      consultantLoading,
      consultantError,
      consultantRetrieved
    };
  },
  { getConsultant }
)(SupportHelp);
