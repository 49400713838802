/* eslint react/prop-types: "off" */
import React, { useState, useEffect } from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { generate as generateKey } from 'shortid';
import { numberToCHF, withProps } from '../../utils/helpers';

const parseLatLng = position => {
  const [lat, lng] = position.split(',');

  return [parseFloat(lat), parseFloat(lng)];
};

const computeDistanceBetween = (latLngFrom, latLngTo) => {
  const rad = x => {
    return (x * Math.PI) / 180;
  };

  const getDistance = (p1, p2) => {
    const R = 6378137; // Earth’s mean radius in meter
    const dLat = rad(p2.lat() - p1.lat());
    const dLong = rad(p2.lng() - p1.lng());
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(rad(p1.lat())) *
        Math.cos(rad(p2.lat())) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return (d / 1000).toFixed(1); // returns the distance in kilometers
  };

  return getDistance(latLngFrom, latLngTo);
};

const Map = ({ request, offers, setSelectedOffer }) => {
  const [markers, setMarkers] = useState([]);
  const [mapRef, setMapRef] = useState(null);
  const requestLatLng = request.preferredLocationPos
    ? new window.google.maps.LatLng(
        ...parseLatLng(request.preferredLocationPos)
      )
    : null;

  useEffect(() => {
    if (!offers) {
      return;
    }

    let markers = [];

    offers['hydra:member'].forEach(offer => {
      if (!offer.addressPos) {
        return null;
      }

      const offerLatLng = new window.google.maps.LatLng(
        ...parseLatLng(offer.addressPos)
      );

      const lines = [
        `Offer #${offer.id}`,
        `Company: ${offer.company.name}`,
        `Address: ${offer.address}`,
        `Price: ${numberToCHF(offer.price)}`
      ];

      const distance = requestLatLng
        ? computeDistanceBetween(requestLatLng, offerLatLng)
        : null;

      if (requestLatLng) {
        lines.splice(3, 0, `Distance: ${distance} km`);
      }

      markers = [
        ...markers,
        {
          title: lines.join('\n'),
          latLng: offerLatLng,
          onClick: () => setSelectedOffer(offer)
        }
      ];
    });

    setMarkers(markers);
  }, [offers]);

  useEffect(() => {
    if (!mapRef) {
      return;
    }

    const bounds = new window.google.maps.LatLngBounds();

    markers.forEach(marker => {
      bounds.extend(marker.latLng);
    });

    if (requestLatLng) {
      bounds.extend(requestLatLng);
    }

    if (markers.length) {
      mapRef.fitBounds(bounds);
    }
  }, [mapRef, markers]);

  return (
    request && (
      <GoogleMap
        defaultCenter={requestLatLng}
        defaultZoom={9}
        ref={ref => setMapRef(ref)}
      >
        {markers.map(marker => (
          <Marker
            title={marker.title}
            key={generateKey()}
            position={marker.latLng}
            onClick={marker.onClick}
          />
        ))}
        {requestLatLng && (
          <Marker
            title={request.preferredLocation}
            icon={{
              url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
              scaledSize: new window.google.maps.Size(45, 45)
            }}
            position={requestLatLng}
          />
        )}
      </GoogleMap>
    )
  );
};

export default withProps({
  loadingElement: <div style={{ height: `100%` }} />,
  containerElement: <div style={{ height: `400px` }} />,
  mapElement: <div style={{ height: `100%` }} />
})(withGoogleMap(Map));
