import React from 'react';
import PropTypes from 'prop-types';

const InlineFieldGroup = ({
  labelClass,
  childrenClass,
  label,
  children,
  required
}) => (
  <div className="form-group row">
    <div className={`${labelClass} d-flex`}>
      <span
        className={`form-label ${
          required ? 'form-label--required' : ''
        } align-self-center`}
      >
        {label}
      </span>
    </div>
    <div className={childrenClass}>
      <div className="row">{children}</div>
    </div>
  </div>
);

InlineFieldGroup.propTypes = {
  labelClass: PropTypes.string,
  childrenClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  required: PropTypes.bool
};

InlineFieldGroup.defaultProps = {
  labelClass: 'col-sm-3',
  childrenClass: 'col-sm-9',
  required: false
};

export default InlineFieldGroup;
