import axios from 'axios';

export function error(error) {
  return { type: 'NO_AVAILABLE_OFFER_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'NO_AVAILABLE_OFFER_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'NO_AVAILABLE_OFFER_CREATE_SUCCESS', created };
}

export function create(values) {
  return async dispatch => {
    dispatch(loading(true));

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_ENTRYPOINT}/no_available_offers`,
        {
          apartmentRequest: values.apartmentRequest
        },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      dispatch(success(data));
    } catch (e) {
      if (e.hasOwnProperty('response')) {
        dispatch(error(e.response.data.detail));
      } else {
        dispatch(error('An error occurred'));
      }
    }

    dispatch(loading(false));
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
    dispatch(success(null));
  };
}
