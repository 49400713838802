import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { withAuth } from '.';
import { NotFound } from '../Common/Pages';

const NotFoundRoute = ({ token }) => {
  if (!token) {
    return <Redirect to="/login" />;
  }

  return <Route component={NotFound} />;
};

export default withAuth(NotFoundRoute);
