import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { stateToHTML } from 'draft-js-export-html';

const getEditorStateFromHTML = html => {
  if (!html || !html.trim()) {
    return EditorState.createEmpty();
  }

  const blocksFromHTML = convertFromHTML(html);
  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  return EditorState.createWithContent(state);
};

const WYSIWIGInput = ({ id, placeholder, input, meta }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    if (meta.value && meta.value.trim()) {
      setEditorState(getEditorStateFromHTML(meta.value));
    }
  }, []);

  useEffect(() => {
    setEditorState(getEditorStateFromHTML(input.value));
  }, [input.value]);

  return (
    <>
      <Editor
        placeholder={placeholder}
        editorState={editorState}
        editorClassName={`textarea textarea--wysiwig${
          focus ? ' textarea--focus' : ''
        }`}
        onEditorStateChange={editorState => setEditorState(editorState)}
        onFocus={() => setFocus(true)}
        onBlur={() => {
          setFocus(false);
          let convertedData = editorState.getCurrentContent();
          convertedData = convertedData.hasText()
            ? stateToHTML(convertedData)
            : '';

          input.onChange(convertedData);
        }}
        toolbar={{
          options: ['inline', 'blockType', 'list'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
          },
          list: {
            options: ['unordered', 'ordered']
          }
        }}
      />
      <textarea hidden id={id} {...input} placeholder={placeholder} />
    </>
  );
};

WYSIWIGInput.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string
  }).isRequired,
  meta: PropTypes.shape({
    initial: PropTypes.string
  }).isRequired
};

WYSIWIGInput.defaultProps = {
  placeholder: 'Message...'
};

export default WYSIWIGInput;
