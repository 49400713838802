/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { toast } from 'react-toastify';
import { Collapse } from 'react-bootstrap';
import { BlockField } from '../Common/Form';
import { sendEmail } from '../../actions/Offer/email';
import { Loader } from '../Util';

let Form = ({ sendEmail, id, handleSubmit, submitSucceeded, submitting }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (submitSucceeded) {
      toast.success('Email has been sent.');
    }
  }, [submitSucceeded]);

  return (
    <div>
      <span
        className="text-pink"
        onClick={() => setOpen(!open)}
        style={{ cursor: 'pointer' }}
      >
        <i className="fa fa-envelope" /> Send via email{' '}
        <i className={`fa fa-caret-${open ? 'up' : 'down'}`} />
      </span>
      <Collapse in={open}>
        <form onSubmit={handleSubmit(values => sendEmail({ id, ...values }))}>
          {submitting && <Loader />}
          <Field
            component={BlockField}
            name="recipient"
            label="To*"
            type="text"
            labelClass="col-sm-1"
            inputClass="col-sm-3"
            placeholder="max.mustermann@email.ch"
          />
          <Field
            component={BlockField}
            name="cc"
            label="Cc*"
            type="text"
            labelClass="col-sm-1"
            inputClass="col-sm-3"
            placeholder="max.mustermann@email.ch"
          />
          <Field
            component={BlockField}
            name="price"
            type="checkbox"
            placeholder=""
            label="Include Price"
            labelClass="col-sm-1"
            inputClass="col-sm-3"
          />
          <div className="form-group row">
            <div className="offset-md-1 col-sm-3">
              <button type="submit" className="btn btn--primary w-100">
                Send
              </button>
            </div>
          </div>
        </form>
      </Collapse>
    </div>
  );
};

const validate = values => {
  const errors = {};

  if (!values.recipient) {
    errors.recipient = 'This field is required.';
  }

  if (!values.cc) {
    errors.cc = 'This field is required.';
  }

  return errors;
};

Form = reduxForm({
  form: 'offerEmail',
  validate,
  onSubmitFail: (errors, dispatch, submitErrors, props) => {
    toast.error((errors ? errors._error : '') || props.error);
  },
  enableReinitialize: false
})(Form);

export default connect(
  () => ({
    initialValues: {
      price: false
    }
  }),
  {
    sendEmail
  }
)(Form);
