import axios from 'axios';
import { SubmissionError } from 'redux-form';

export const error = error => {
  return { type: 'RESET_PASSWORD_CONFIRM_ERROR', error };
};

export const loading = loading => {
  return { type: 'RESET_PASSWORD_CONFIRM_LOADING', loading };
};

export const success = retrieved => {
  return { type: 'RESET_PASSWORD_CONFIRM_SUCCESS', retrieved };
};

export const reset = () => dispatch => {
  dispatch(success(''));
};

export const resetPasswordConfirm = values => async dispatch => {
  dispatch(loading(true));
  dispatch(error(''));

  const { id, nonce, password, passwordConfirm } = values;

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_ENTRYPOINT}/reset_passwords/${id}/confirm`,
      { nonce, password, passwordConfirm }
    );

    dispatch(success(data));
  } catch (e) {
    const errors = {
      _error: !e.response.data.violations
        ? e.response.data['hydra:description']
        : ''
    };
    if (e.response.data.violations) {
      e.response.data.violations.map(
        violation => (errors[violation.propertyPath] = violation.message)
      );
    }

    throw new SubmissionError(errors);
  }

  dispatch(loading(false));
};
