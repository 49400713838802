/* eslint react/prop-types: "off" */
import React, { useEffect, useState } from 'react';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  list as listCompany,
  reset as resetCompany
} from '../../actions/Company/list';
import { Loader } from '../Util';
import { BlockField } from '../Common/Form';
import { withAuth } from '../Authentication';
import { hasRole } from '../../utils/helpers';
import { HOST_ADMIN, CLIENT, SUB_CLIENT } from '../../constants/roles';
import { retrieve as retrieveUser } from '../../actions/User/show';

const subClientEnabled = ({
  companiesRetrieved,
  selectedCompany,
  currentUser,
  userRoles
}) => {
  // If company list and current user haven't been retrieved, return true to allow for the roles field to be registered
  if (!companiesRetrieved && !currentUser) {
    return true;
  }

  const currentCompany =
    companiesRetrieved && selectedCompany
      ? companiesRetrieved['hydra:member'].find(
          e => e['@id'] === selectedCompany
        ) // eslint-disable-line
      : null;

  const companyIsClient =
    hasRole(userRoles, CLIENT) || (currentCompany && currentCompany.typeName);

  const subClientEnabled = hasRole(userRoles, CLIENT)
    ? currentUser && currentUser.company.subClientEnabled
    : currentCompany && currentCompany.subClientEnabled;

  return companyIsClient && subClientEnabled;
};

let Form = ({
  handleSubmit,
  listCompany,
  resetCompany,
  loading,
  companiesRetrieved,
  roles: userRoles,
  companiesError,
  selectedCompany,
  selectedRole,
  retrieveUser,
  userId,
  currentUser,
  currentUserLoading
}) => {
  const [roles, setRoles] = useState([
    {
      value: SUB_CLIENT,
      label: 'Sub Client',
      name: 'Client'
    }
  ]);

  useEffect(() => {
    if (hasRole(userRoles, HOST_ADMIN)) {
      listCompany();
      setRoles(roles => [
        {
          value: CLIENT,
          label: 'Client',
          name: 'Client'
        },
        ...roles
      ]);
    } else if (hasRole(userRoles, CLIENT)) {
      retrieveUser(userId);
    }

    return () => {
      resetCompany();
    };
  }, []);

  useEffect(() => {
    if (companiesError) {
      toast.error('Could not fetch companies.');
      resetCompany();
    }
  }, [companiesError]);

  return (
    <form onSubmit={handleSubmit}>
      {(loading || currentUserLoading) && <Loader />}
      <Row>
        <Col md={6}>
          {hasRole(userRoles, HOST_ADMIN) && (
            <Field
              component={BlockField}
              id="company"
              name="company"
              label="Company Name"
              type="select"
              placeholder="Please choose..."
              labelClass="col-sm-4"
              inputClass="col-sm-8"
              required
              items={
                companiesRetrieved
                  ? companiesRetrieved['hydra:member'].map(e => ({
                      value: e['@id'],
                      label: e.name,
                      name: e.name
                    }))
                  : []
              }
            />
          )}
          {subClientEnabled({
            companiesRetrieved,
            selectedCompany,
            currentUser,
            userRoles
          }) && (
            <Field
              component={BlockField}
              name="roles"
              label="Role"
              type="select"
              placeholder="Please choose..."
              labelClass="col-sm-4"
              inputClass="col-sm-8"
              required
              items={roles}
            />
          )}
          <Field
            component={BlockField}
            name="gender"
            type="radioButton"
            label="Salutation"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            options={[
              { id: 'm', value: 'm', label: 'Mr.' },
              { id: 'f', value: 'f', label: 'Mrs.' }
            ]}
          />
          <Field
            component={BlockField}
            name="firstName"
            type="text"
            label="First Name"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            placeholder="Max"
            required
          />
          <Field
            component={BlockField}
            name="lastName"
            type="text"
            label="Last Name"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            placeholder="Mustermann"
            required
          />
          <Field
            component={BlockField}
            name="email"
            type="text"
            label="Email"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            placeholder="max.mustermann@email.ch"
            required
          />
          <Field
            component={BlockField}
            name="mobileNumber"
            type="text"
            label="Mobile number"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            placeholder="+41 75 123 45 67"
          />
          <Field
            component={BlockField}
            name="phoneNumber"
            type="text"
            label="Phone number"
            labelClass="col-sm-4"
            inputClass="col-sm-8"
            placeholder="+41 75 123 45 67"
          />
          {(Array.isArray(selectedRole)
            ? selectedRole.includes(SUB_CLIENT)
            : selectedRole === SUB_CLIENT) && (
            <Field
              component={BlockField}
              name="invoiceTo"
              label="Contract and Invoice To"
              type="select"
              placeholder="Please choose..."
              labelClass="col-sm-4"
              inputClass="col-sm-8"
              items={[
                {
                  value: 'company',
                  label: 'Company',
                  name: 'company'
                },
                {
                  value: 'guest',
                  label: 'Guest/Alternative Address',
                  name: 'guest'
                }
              ]}
              required
            />
          )}
          <Field
            component={BlockField}
            name="active"
            type="checkbox"
            label="Is Active?"
            labelClass="col-8 col-sm-4"
            inputClass="col-4 col-sm-8"
          />
        </Col>
        <Col md={6}>
          <Field
            component={BlockField}
            name="profilePicture"
            type="fileInput"
            label="Image"
            accept="image/*"
            uploadDir={process.env.REACT_APP_IMAGES_UPLOAD_DIR_USER}
            hasPreview
            labelClass="col-sm-4"
            inputClass="col-sm-8"
          >
            Choose File
          </Field>
        </Col>
      </Row>
      <Row className="form-group">
        <Col md={12}>
          <div className="float-right">
            <button type="submit" className="btn btn--primary">
              SAVE
            </button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

const validate = (
  values,
  { roles: userRoles, companiesRetrieved, currentUser, selectedCompany }
) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'First Name is required.';
  }

  if (!values.lastName) {
    errors.lastName = 'Last Name is required.';
  }

  if (hasRole(userRoles, HOST_ADMIN) && !values.company) {
    errors.company = 'Company is required.';
  }

  if (!values.email) {
    errors.email = 'Email is required.';
  }

  if (
    subClientEnabled({
      companiesRetrieved,
      selectedCompany,
      currentUser,
      userRoles
    }) &&
    !values.roles
  ) {
    errors.roles = 'Role is required.';
  }

  if (
    (Array.isArray(values.roles)
      ? values.roles.includes(SUB_CLIENT)
      : values.roles === SUB_CLIENT) &&
    !values.invoiceTo
  ) {
    errors.invoiceTo = 'Contract and Invoce To is required.';
  }

  if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
    return {
      ...errors,
      _error: 'Submission failed. Please fix the fields marked in red.'
    };
  }

  return errors;
};

Form = reduxForm({
  form: 'user',
  validate,
  onSubmitFail: (errors, dispatch, submitErrors, props) => {
    toast.error(errors._error || props.error);
  }
})(Form);

const selector = formValueSelector('user');

export default connect(
  state => {
    const { company, roles } = selector(state, 'company', 'roles');
    const {
      loading: companiesLoading,
      retrieved: companiesRetrieved,
      error: companiesError
    } = state.company.list;
    const { id: userId } = state.authentication.token.retrieved;
    const {
      retrieved: currentUser,
      loading: currentUserLoading
    } = state.user.show;

    return {
      companiesError,
      companiesLoading,
      companiesRetrieved,
      selectedCompany: company,
      selectedRole: roles,
      userId,
      currentUser,
      currentUserLoading
    };
  },
  { listCompany, resetCompany, retrieveUser }
)(withAuth(Form));
