import firebase from 'firebase/app';
import 'firebase/messaging';

let messaging;

/**
 * Initialize the Firebase app in the service worker by passing in the messagingSenderId.
 */
export const initializeFirebase = () => {
  // initialize the firebase app
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FCM_DATABASE_URL,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID
  });

  messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
};

/**
 * Show the confirm dialog of the browser to accept the web push notifications
 * @returns {Promise<{allowNotifications: boolean}>}
 */
export const askForPermissionToReceiveNotifications = async () => {
  try {
    messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    return {
      token,
      allowNotifications: true
    };
  } catch (error) {
    return {
      allowNotifications: false
    };
  }
};

/**
 * Register the service worker in the browser so it will always listen for messages
 */
(function() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js');
  }
})();

export { messaging };
