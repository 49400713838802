import { SubmissionError } from 'redux-form';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'REQUEST_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'REQUEST_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'REQUEST_CREATE_SUCCESS', created };
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));

    const newValues = values;

    if (newValues.location) {
      newValues.preferredLocation = newValues.location.address || '';
      newValues.preferredLocationPos = newValues.location.pos
        ? `${newValues.location.pos.lat},${newValues.location.pos.lng}`
        : '';
    }

    if (!newValues.differentBillingAddress) {
      delete newValues.billingAddress;
      delete newValues.billingZip;
      delete newValues.billingCity;
      delete newValues.billingCountry;
      delete newValues.billingCompany;
    }

    if (!newValues.differentContractAddress) {
      delete newValues.contractAddress;
      delete newValues.contractZip;
      delete newValues.contractCity;
      delete newValues.contractCountry;
      delete newValues.contractCompany;
    }

    if (newValues.clientReferences) {
      newValues.clientReferences = newValues.clientReferences.filter(
        e => e !== null
      );
    }

    return fetch('apartment_requests', {
      method: 'POST',
      body: JSON.stringify(values)
    })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => dispatch(success(retrieved)))
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw new SubmissionError({
            ...e.errors,
            _error: 'Submission failed. Please fix the fields marked in red.'
          });
        } else {
          dispatch(error(e.message));
          throw new SubmissionError({
            _error: e.message
          });
        }
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}
