/* eslint react/prop-types: "off" */
import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import Form from './Form';
import { create, reset } from '../../actions/User/create';
import { iconClose, Loader, Modal } from '../Util';

const Create = ({ loading, create, modalState, setModalState }) => {
  return (
    <Modal isOpen={modalState} onClose={() => setModalState(false)}>
      <div className="modal__content pb-0">
        <Row className="mb-5">
          <Col md={12}>
            <h1 className="heading heading--medium">Add a new User</h1>
            <button
              type="button"
              className="btn btn--close-modal"
              onClick={() => setModalState(!modalState)}
            >
              <img src={iconClose} alt="close modal" />
            </button>
            {loading && <Loader />}
            <Form onSubmit={create} />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default connect(
  state => {
    const { created, error, loading } = state.company.create;
    return { created, error, loading };
  },
  {
    create,
    reset
  }
)(Create);
