import React from 'react';
import PropTypes from 'prop-types';

const TextInput = ({ type, id, input, placeholder, disabled }) => {
  return (
    <input
      className="input"
      type={type}
      id={id}
      placeholder={placeholder}
      disabled={disabled}
      {...input}
      value={input.value}
    />
  );
};

TextInput.defaultProps = {
  className: null,
  placeholder: null,
  disabled: false,
  type: 'text'
};

TextInput.propTypes = {
  type: PropTypes.oneOf(['number', 'text', 'password', 'email']),
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default TextInput;
