import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';
import { ENTRYPOINT } from '../config/entrypoint';

const MIME_TYPE = 'application/ld+json';

export function fetch(id, options = {}) {
  if (typeof options.headers === 'undefined') options.headers = new Headers();
  if (options.headers.get('Accept') === null)
    options.headers.set('Accept', MIME_TYPE);

  if (
    options.headers.get('Authorization') == null &&
    localStorage.getItem('token') !== null
  )
    options.headers.set(
      'Authorization',
      `Bearer ${localStorage.getItem('token')}`
    );

  if (
    options.body !== 'undefined' &&
    !(options.body instanceof FormData) &&
    options.headers.get('Content-Type') === null
  )
    options.headers.set('Content-Type', MIME_TYPE);

  return global.fetch(new URL(id, ENTRYPOINT), options).then(response => {
    if (response.ok) return response;

    return response.json().then(json => {
      const error = json['hydra:description'] || response.statusText;
      if (!json.violations) throw Error(error);

      const errors = { _error: null };
      json.violations.map(
        violation => (errors[violation.propertyPath] = violation.message)
      );

      throw new SubmissionError(errors);
    });
  });
}

export function mercureSubscribe(url, topics) {
  topics.forEach(topic =>
    url.searchParams.append('topic', new URL(topic, ENTRYPOINT))
  );

  return new EventSource(url.toString());
}

export function normalize(data) {
  if (has(data, 'hydra:member')) {
    // Normalize items in collections
    data['hydra:member'] = data['hydra:member'].map(item => normalize(item));

    return data;
  }

  // Flatten nested documents
  return mapValues(data, value =>
    Array.isArray(value)
      ? value.map(v => get(v, '@id', v))
      : get(value, '@id', value)
  );
}

export function extractHubURL(response) {
  const linkHeader = response.headers.get('Link');
  if (!linkHeader) return null;

  const matches = linkHeader.match(
    /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
  );

  return matches && matches[1] ? new URL(matches[1], ENTRYPOINT) : null;
}
