import axios from 'axios';
import { SubmissionError } from 'redux-form';

export const error = error => {
  return { type: 'RESET_PASSWORD_ERROR', error };
};

export const loading = loading => {
  return { type: 'RESET_PASSWORD_LOADING', loading };
};

export const retrieved = retrieved => {
  return { type: 'RESET_PASSWORD_SUCCESS', retrieved };
};

export const reset = () => dispatch => {
  dispatch(retrieved(''));
};

export const resetPasswordCreate = ({ email }) => async dispatch => {
  dispatch(loading(true));
  dispatch(error(''));

  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_ENTRYPOINT}/reset_passwords/create`,
      { email }
    );

    dispatch(retrieved(data));
  } catch (e) {
    const errors = {
      _error: !e.response.data.violations
        ? e.response.data['hydra:description']
        : ''
    };
    if (e.response.data.violations) {
      e.response.data.violations.map(
        violation => (errors[violation.propertyPath] = violation.message)
      );
    }

    throw new SubmissionError(errors);
  }

  dispatch(loading(false));
};
