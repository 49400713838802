/* eslint react/prop-types: 0 */
import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Input } from '../Common/Form';
import logo from '../../assets/nest_logo.svg';
import { Loader } from '../Util';
import { create } from '../../actions/Application/create';

const renderField = data => (
  <div className="form-group row">
    <Input
      {...data}
      type={data.type}
      step={data.step}
      className="col-12 text-left"
      required={data.required}
      placeholder={data.placeholder}
      id={`request_${data.input.name}`}
      error={data.meta.touched && data.meta.error ? data.meta.error : null}
    />
  </div>
);

const Application = ({ create, handleSubmit, submitting, submitSucceeded }) => {
  useEffect(() => {
    if (submitSucceeded) {
      toast.success('You have applied successfully.');
      setTimeout(() => {
        window.location.href = 'https://mynest.ch/';
      }, 2000);
    }
  }, [submitSucceeded]);

  return (
    <div className="login">
      <title>NEST - Apply for an account</title>
      <div className="login__wrapper">
        <div className="login__box">
          <img src={logo} alt="Nest Logo" className="login__logo" />
          <h4 className="text text--normal" style={{ marginBottom: '40px' }}>
            Apply for an account.
          </h4>
          <form onSubmit={handleSubmit(create)}>
            <Field
              id="firstName"
              type="text"
              name="firstName"
              placeholder="First Name*"
              component={renderField}
            />
            <Field
              id="lastName"
              type="text"
              name="lastName"
              placeholder="Last Name*"
              component={renderField}
            />
            <Field
              id="email"
              type="email"
              name="email"
              placeholder="Email*"
              component={renderField}
            />
            <Field
              id="phone"
              type="text"
              name="phone"
              placeholder="Phone*"
              component={renderField}
            />
            <Field
              id="company"
              type="text"
              name="company"
              placeholder="Company*"
              component={renderField}
            />
            <button type="submit" className="btn btn--primary">
              Apply
            </button>
            {submitting && <Loader />}
          </form>
        </div>
      </div>
    </div>
  );
};

const validate = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'First Name is required.';
  }

  if (!values.lastName) {
    errors.lastName = 'Last Name is required.';
  }

  if (!values.email) {
    errors.email = 'Email is required.';
  }

  if (!values.phone) {
    errors.phone = 'Phone is required.';
  }

  if (!values.company) {
    errors.company = 'Company is required.';
  }

  if (Object.keys(errors).length !== 0 && errors.constructor === Object) {
    return {
      ...errors,
      _error: 'Submission failed. Please fix the fields marked in red.'
    };
  }

  return errors;
};

const reduxFormLogIn = reduxForm({
  form: 'appliaction',
  validate,
  onSubmitFail: (errors, dispatch, submitErrors, props) => {
    toast.error(errors._error || props.error);
  }
})(Application);

export default connect(
  null,
  { create }
)(reduxFormLogIn);
