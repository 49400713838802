import React from 'react';
import PropTypes from 'prop-types';
import { iconFilter } from '../../Util';

const FilterButtons = ({ onClick, activeButton }) => (
  <div className="filter d-md-flex">
    <div className="filter__icon d-flex align-items-center">
      <img src={iconFilter} alt="icon filter" />
      <span>Filter</span>
    </div>
    <div className="filter__buttons d-flex justify-content-between">
      <button
        type="button"
        value="host"
        className={`btn btn--filter${activeButton === 'host' ? ' active' : ''}`}
        onClick={onClick}
      >
        Nest
      </button>
      <button
        type="button"
        value="vendor"
        className={`btn btn--filter${
          activeButton === 'vendor' ? ' active' : ''
        }`}
        onClick={onClick}
      >
        Vendor
      </button>
      <button
        type="button"
        value="client"
        className={`btn btn--filter${
          activeButton === 'client' ? ' active' : ''
        }`}
        onClick={onClick}
      >
        Client
      </button>
    </div>
  </div>
);

FilterButtons.defaultProps = {
  activeButton: '',
  onClick: null
};

FilterButtons.propTypes = {
  activeButton: PropTypes.string,
  onClick: PropTypes.func
};

export default FilterButtons;
