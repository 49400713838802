import { SubmissionError } from 'redux-form';
import { toast } from 'react-toastify';
import moment from 'moment';
import { fetch } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'USER_CREATE_ERROR', error };
}

export function loading(loading) {
  return { type: 'USER_CREATE_LOADING', loading };
}

export function success(created) {
  return { type: 'USER_CREATE_SUCCESS', created };
}

export function create(values) {
  return dispatch => {
    dispatch(loading(true));

    const body = new FormData();

    Object.keys(values).map(function(key) {
      if (key === 'profilePicture' && values[key] instanceof FileList) {
        body.append(key, values[key][0]);
      } else if (key === 'company') {
        body.append(key, values[key].split('/')[2]);
      } else {
        body.append(key, values[key]);
      }
    });

    return fetch('users', { method: 'POST', body })
      .then(response => {
        dispatch(loading(false));

        return response.json();
      })
      .then(retrieved => {
        toast.success('User successfully created');
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));

        if (e instanceof SubmissionError) {
          dispatch(error(e.errors._error));
          throw new SubmissionError({
            ...e.errors,
            _error: 'Submission failed. Please fix the fields marked in red.'
          });
        } else {
          dispatch(error(e.message));
          throw new SubmissionError({
            _error: e.message
          });
        }
      });
  };
}

export function reset() {
  return dispatch => {
    dispatch(loading(false));
    dispatch(error(null));
  };
}
