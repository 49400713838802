import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';

const PlacesInput = ({
  id,
  input,
  placeholder,
  disabled,
  meta: { initial }
}) => {
  const [address, setAddress] = useState(initial ? initial.address : '');

  useEffect(() => {
    setAddress(input.value);
  }, [input.value]);

  useEffect(() => {
    document.getElementById(id).setAttribute('autoComplete', 'new-password');
    if (initial) {
      let [lat, lng] = [null, null];

      if (initial.pos) {
        [lat, lng] = initial.pos ? initial.pos.split(',') : [null, null];
      }

      const location = {
        address: initial.address,
        pos: lat == null ? undefined : { lat, lng }
      };

      input.onChange(location);
      input.onBlur(location);
    }
  }, []);

  return (
    <PlacesAutocomplete
      value={address}
      onChange={address => {
        setAddress(address);
        input.onChange({ address });
        input.onBlur({ address });
      }}
      onSelect={async address => {
        setAddress(address);
        try {
          const result = await geocodeByAddress(address);
          const pos = await getLatLng(result[0]);

          const location = { address, pos: pos || null };

          input.onChange(location);
          input.onBlur(location);
        } catch (e) {
          // handle error
        }
      }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div className="position-relative">
          <input
            {...getInputProps({
              id,
              disabled,
              placeholder,
              className: 'input'
            })}
          />
          {!!suggestions.length && (
            <div className="input-autocomplete__container">
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'input-autocomplete__item input-autocomplete__item--active'
                  : 'input-autocomplete__item';

                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </PlacesAutocomplete>
  );
};

PlacesInput.defaultProps = {
  className: null,
  placeholder: null,
  disabled: false
};

PlacesInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    checked: PropTypes.bool,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDragStart: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  meta: PropTypes.shape({
    initial: PropTypes.object
  }).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool
};

export default PlacesInput;
