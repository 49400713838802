/* eslint react/prop-types: "off" */
import React, { useEffect } from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { toast, ToastContainer } from 'react-toastify';
import { NotFoundRoute, withAuth } from './components/Authentication';
import {
  refreshTokenTimeout,
  checkToken
} from './actions/Authentication/token';
import {
  loginRoutes,
  requestRoutes,
  notificationRoutes,
  companyRoutes,
  offerRoutes,
  userRoutes,
  dashboardRoutes,
  applicationRoutes,
  demoRoutes
} from './routes';
import { history } from './store';

const App = ({ checkToken, refreshTokenTimeout, token }) => {
  useEffect(() => {
    if (token) {
      checkToken();
      refreshTokenTimeout();
    }
  }, [token]);

  return (
    <>
      <ConnectedRouter history={history}>
        <Switch>
          {loginRoutes}
          {requestRoutes}
          {dashboardRoutes}
          {notificationRoutes}
          {companyRoutes}
          {offerRoutes}
          {userRoutes}
          {applicationRoutes}
          {demoRoutes}
          <NotFoundRoute />
        </Switch>
      </ConnectedRouter>
      <ToastContainer
        draggable={false}
        autoClose={15000}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default connect(
  null,
  { checkToken, refreshTokenTimeout }
)(withAuth(App));
