/* eslint react/prop-types: "off" */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import logo from '../../../assets/nest_logo.svg';

const NotFound = ({ history }) => {
  return (
    <div className="container-fluid">
      <div className="not-found">
        <img src={logo} alt="Nest Logo" className="not-found__logo" />
        <h4 className="heading heading--small" style={{ marginBottom: '40px' }}>
          The page you were looking for does not exist!
        </h4>
        <a href="#" onClick={() => history.goBack()}>
          Go back
        </a>{' '}
        | <Link to="/">Home</Link>
      </div>
    </div>
  );
};

export default withRouter(NotFound);
