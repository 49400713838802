import { fetch, extractHubURL } from '../../utils/dataAccess';

export function error(error) {
  return { type: 'NO_AVAILABLE_OFFER_LIST_ERROR', error };
}

export function loading(loading) {
  return { type: 'NO_AVAILABLE_OFFER_LIST_LOADING', loading };
}

export function success(retrieved) {
  return { type: 'NO_AVAILABLE_OFFER_LIST_SUCCESS', retrieved };
}

export function list(page = 'no_available_offers') {
  return dispatch => {
    dispatch(loading(true));
    dispatch(error(''));

    fetch(page)
      .then(response =>
        response
          .json()
          .then(retrieved => ({ retrieved, hubURL: extractHubURL(response) }))
      )
      .then(({ retrieved }) => {
        dispatch(loading(false));
        dispatch(success(retrieved));
      })
      .catch(e => {
        dispatch(loading(false));
        dispatch(error(e.message));
      });
  };
}

export function reset(eventSource) {
  return dispatch => {
    if (eventSource) eventSource.close();

    dispatch({ type: 'NO_AVAILABLE_OFFER_LIST_RESET' });
  };
}
