import React from 'react';
import { iconSearch } from '../../Util';

const Search = ({ value, onChange, onKeyPress }) => {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <div className="input-group-text" id="btnGroupAddon">
          <img src={iconSearch} alt="search" />
        </div>
      </div>
      <input
        type="text"
        className="form-control"
        placeholder="Search"
        name="search"
        onChange={onChange}
        onKeyPress={onKeyPress}
        defaultValue={value || ''}
      />
    </div>
  );
};
export default Search;
