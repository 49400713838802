/* eslint react/prop-types: "off" */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import {
  formatDateSimple,
  zeroPad,
  arrayToString
} from '../../../utils/helpers';

const countDownTimer = (date, setTimer) => {
  if (!date || !date.isValid()) {
    return;
  }

  const countDownDate = date.toDate().getTime();

  const x = setInterval(() => {
    const now = moment
      .tz('Europe/Zurich')
      .toDate()
      .getTime();

    const distance = countDownDate - now;

    let hours = Math.floor(distance / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);

    hours = zeroPad(hours, 2);
    minutes = zeroPad(minutes, 2);
    seconds = zeroPad(seconds, 2);

    const string = `${hours}:${minutes}:${seconds}`;

    setTimer(string);

    if (distance < 0) {
      clearInterval(x);
      setTimer();
    }
  }, 1000);

  return x;
};

const Timer = ({ expireDate, className }) => {
  const expiresAt = moment.tz(expireDate, 'Europe/Zurich');
  const [timer, setTimer] = useState();

  useEffect(() => {
    const timer = countDownTimer(expiresAt, setTimer);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return <strong className={className}>{timer}</strong>;
};

const CardApartment = ({ onClick, apartment }) => {
  return (
    <div role="button" className="card card--data d-flex" onClick={onClick}>
      {apartment.statusName.indexOf('closed') === -1 &&
        apartment.statusName !== 'booked' && (
          <div className="notification-icon-box notification-icon-box--abs notification-icon-box--pink">
            <span className="notification-icon-box__counter">
              {apartment.offersCount}
            </span>
          </div>
        )}
      <div className="card__content d-flex align-items-center">
        <div className="w-100">
          <h2 className="card__main-title text-break">
            {apartment.title} #{apartment.id}
          </h2>
          <p>
            {arrayToString(apartment.regions)} <br />
            {arrayToString(apartment.apartmentTypes)} <br />
            {formatDateSimple(apartment.arrival)}
            {apartment.departure
              ? ` - ${formatDateSimple(apartment.departure)}`
              : ''}{' '}
            <br />
          </p>
        </div>
      </div>
      {apartment.statusName.startsWith('pending') && (
        <div className="card__content d-flex align-items-end ml-auto">
          <p>
            <Timer
              expireDate={apartment.statusName === 'pending_confirmation' ? apartment.expiredDateAcceptedOffer : apartment.expireDate}
              className={apartment.statusName !== 'pending' ? 'text-pink' : ''}
            />
          </p>
        </div>
      )}
    </div>
  );
};

CardApartment.propTypes = {
  apartment: PropTypes.shape({
    offersCount: PropTypes.number.isRequired,
    arrival: PropTypes.string.isRequired,
    departure: PropTypes.string,
    preferredLocation: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func
};

CardApartment.defaultProps = {
  onClick: null
};

export default CardApartment;
